import { Box, Button, FormControl, Input, InputGroup, InputLeftAddon, InputLeftElement, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiLogIn, BiRename } from 'react-icons/bi';
import { BsPerson, BsTelephone } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { MdAlternateEmail, MdOutlineLocationOn, MdPassword } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';
import { registrarUsuario } from '../../database';

const FormRegistro = ({ onClose = '' }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [cargando, setCargando] = useState(false);

    const [formData, setFormData] = useState({
        apynom: '',
        correo: '',
        contra: '',
        localidad: '',
        caract_cel: '',
        nro_cel: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleClick = async () => {
        //setCargando(true);

        if (formData.apynom.length < 8) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "El apellido y nombre debe tener al menos 5 caracteres.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (!formData.correo || !formData.correo.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: 'Por favor, ingrese un correo electrónico válido.',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
            return;
        }

        if (!(formData.contra.length >= 8 && formData.contra.length <= 20)) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: 'La contraseña debe tener entre 8 y 20 caracteres.',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
            return;
        }

        if (formData.localidad == "") {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "La localidad es requerida.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (!(formData.caract_cel.length <= 4)) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: 'La característica debe tener como máximo 4 dígitos.',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
            return;
        }

        // if (!(formData.nro_cel.length <= 6)) {
        //     setCargando(false);
        // toast({
        //     title: 'Datos erróneos',
        //     description: 'El número de celular debe tener como máximo 6 dígitos.',
        //     status: 'error',
        //     duration: 4000,
        //     isClosable: true,
        // });
        // return;
        // }

        const resultadoRegistro = await registrarUsuario(formData);

        console.log(resultadoRegistro)

        if (resultadoRegistro.success) {
            setCargando(false);
            toast({
                title: 'Cuenta creada correctamente',
                status: 'success',
                duration: 2000,
                isClosable: true,
            })

            if (onClose != '') {
                setTimeout(() => {
                    onClose();
                }, 1000);
            } else {
                setTimeout(() => {
                    navigate(`/home`)
                }, 2000);
            }


        } else {
            toast({
                title: 'Correo ya registrado',
                description: 'El correo ingresado ya se encuentra registrado.',
                status: 'error',
                isClosable: true,
            });
        }

        // const res = await fetch("http://localhost:4000/crear-usuario",{
        //     method: 'POST',
        //     body: JSON.stringify(formData),
        //     headers: {"Content-Type": "application/json",}
        // });

        // setCargando(false);
        // if (res.ok){
        //     toast({
        //         title: 'Cuenta creada correctamente',
        //         status: 'success',
        //         duration: 2000,
        //         isClosable: true,
        //     })

        //     setTimeout(() => {
        //         navigate(`/home`)
        //     }, 2000);
        // } 
        // else if (res.status == '409'){
        //     toast({
        //         title: 'Correo ya registrado',
        //         description: 'El correo ingresado ya se encuentra registrado.',
        //         status: 'error',
        //         isClosable: true,
        //     });
        // }


    };

    return (
        <FormControl w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
            <Box w="100%">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <BsPerson color='gray.300' />
                    </InputLeftElement>
                    <Input w="100%" h="40px" variant='outline' placeholder="Apellido y nombre" name="apynom" onChange={handleChange} />
                </InputGroup>
            </Box>
            <Box w="100%">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <MdAlternateEmail color='gray.300' />
                    </InputLeftElement>
                    <Input w="100%" h="40px" variant='outline' placeholder="Correo electrónico" name="correo" onChange={handleChange} />
                </InputGroup>
            </Box>
            <Box w="100%">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <MdPassword color='gray.300' />
                    </InputLeftElement>
                    <Input type='password' w="100%" h="40px" variant='outline' placeholder="Contraseña" name="contra" onChange={handleChange} />
                </InputGroup>
            </Box>
            <Box w="100%">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <MdOutlineLocationOn color='gray.300' />
                    </InputLeftElement>
                    <Input type='text' w="100%" h="40px" variant='outline' placeholder="Localidad" name="localidad" onChange={handleChange} />
                </InputGroup>
            </Box>
            <Box w="100%" display="flex" flexDirection="row" gap="10px">
                <Box w="48.5%">
                    <InputGroup>
                        <InputLeftAddon children='0' />
                        <Input type='number' w="100%" h="40px" variant='outline' placeholder="3446" name="caract_cel" onChange={handleChange} />
                    </InputGroup>
                </Box>
                <Box w="48.5%">
                    <InputGroup>
                        <InputLeftAddon children='15' />
                        <Input type='number' w="100%" h="40px" variant='outline' placeholder="302010" name="nro_cel" onChange={handleChange} />
                    </InputGroup>
                </Box>
            </Box>
            <Box w="100%" mt="0px" mb="20px">
                <Button w="100%" h="40px" isLoading={cargando} onClick={handleClick} rightIcon={<BiLogIn />} bg='primary.100' color="#fff" variant='solid'>Registrarme</Button>
            </Box>
            {/* <Box w="100%" mb="20px">
            <Button w="100%" h="40px" borderColor="#1e1e1e" leftIcon={<FcGoogle />} color="#1e1e1e" variant='outline'>Registrarme con Google</Button>
        </Box> */}
        </FormControl>
    )
}

export default FormRegistro