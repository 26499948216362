import { Box, Button, Divider, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { eliminarViajeParticular, getChoferPorId, marcarViajeParticularTerminado, obtenerPasajeroPorId, obtenerViajeParticularPorId } from '../../database';
import VolverTitulo from '../../components/usuario/VolverTitulo';
import { BsWhatsapp } from 'react-icons/bs';
import { MdOutlineCancel } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const ViajeParticular = ({ user, setUser }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen: isCancelarOpen, onOpen: onCancelarOpen, onClose: onCancelarClose } = useDisclosure();
    const [viajeParticular, setViajeParticular] = useState();
    const [chofer, setChofer] = useState();
    const [pasajero, setPasajero] = useState();
    const [idCancelar, setIdCancelar] = useState('');
    const [idViaje, setIdViaje] = useState('');
    const [viajeTerminado, setViajeTerminado] = useState(false);

    const obtenerViajeParticular = async () => {
        const resultadoViaje = await obtenerViajeParticularPorId(id);

        console.log(resultadoViaje.viajeParticular);

        if (resultadoViaje.success) {
            setViajeParticular(resultadoViaje.viajeParticular);
            obtenerChofer(resultadoViaje.viajeParticular.id_chofer);
            obtenerPasajero(resultadoViaje.viajeParticular.id_solicitador);
        }
    }

    const obtenerChofer = async (id_chofer) => {
        const resultadoChofer = await getChoferPorId(id_chofer);

        console.log(resultadoChofer);

        if (resultadoChofer.success) {
            setChofer(resultadoChofer.data);
        }
    }

    const obtenerPasajero = async (id_pasajero) => {
        const resultadoPasajero = await obtenerPasajeroPorId(id_pasajero);

        console.log(resultadoPasajero);

        if (resultadoPasajero.success) {
            setPasajero(resultadoPasajero.pasajero);
        }
    }

    function getCookie(name) {
        const cookieName = `${name}=`;
        const cookiesArray = document.cookie.split(';');
        for (let i = 0; i < cookiesArray.length; i++) {
            let cookie = cookiesArray[i].trim();
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    }

    const adminId = getCookie('adminId');
    const userId = getCookie('userId');

    useEffect(() => {
        if (userId) {
            console.log("existe el usuario")
        } else if (adminId == '') {
            navigate('/login');
        }
    }, [adminId]);

    function convertirFormato(numero) {
        const numeroLimpio = numero.replace(/[-\s]/g, '');

        const numeroConPrefijo = "+54" + numeroLimpio;

        return numeroConPrefijo;
    }

    function generateWhatsAppLink(fixedNumber, recipientNumber, text) {
        const urlEncodedText = encodeURIComponent(text);
        return `https://api.whatsapp.com/send?phone=${fixedNumber}&text=${urlEncodedText}`;
    }

    function redirectToWhatsApp(fixedNumber, recipientNumber, text) {
        const whatsappLink = generateWhatsAppLink(fixedNumber, recipientNumber, text);
        window.location.href = whatsappLink;
    }

    const enviarDatosChofer = () => {
        let nroChofer = convertirFormato('3446596426');
        let msj = `Datos sobre el viaje: \n`;
        msj += `Desde: ${viajeParticular.origen}. \n`;
        msj += `Hacia: ${viajeParticular.destino}. \n`;
        msj += `El día ${viajeParticular.fecha} a las ${viajeParticular.hora}. \n`;
        msj += `Pasajero/s: \n`;
        msj += ` - ${pasajero.nombre}. Dirección de búsqueda: ${pasajero.origen_busqueda}. Direccion de destino: ${pasajero.destino_busqueda} \n`;
        msj += `Precio por pasajero: $${viajeParticular.precio}\n`;

        redirectToWhatsApp('3446583020', nroChofer, msj);
    }

    useEffect(() => {
        obtenerViajeParticular();
    }, []);

    useEffect(() => {
        obtenerViajeParticular();
    }, [viajeTerminado]);

    const popupCancelarViaje = () => {
        onCancelarOpen();
        setIdCancelar(id);
    }

    const cancelarViaje = async () => {
        if (idCancelar != '') {
            try {
                const resultadoeliminar = await eliminarViajeParticular(idCancelar);

                if (resultadoeliminar.success) {
                    toast({
                        title: 'El viaje se ha eliminado correctamente.',
                        status: 'success',
                        duration: 4000,
                        isClosable: true,
                    })
                    if (userId != '') {
                        navigate('/home');
                    } else if (adminId != '') {
                        navigate('/admin');
                    }
                } else {
                    toast({
                        title: 'Ocurrió un error al intentar eliminar el viaje.',
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                    })
                }
                onCancelarClose();
            } catch (error) {
                console.error('Error en la solicitud de eliminación:', error);
            }
        } else {

        }
    }

    const terminarViaje = async () => {
        try {
            const resultadoTerminar = await marcarViajeParticularTerminado(id);

            if (resultadoTerminar.success) {
                setViajeTerminado(true);
                toast({
                    title: 'Viaje terminado.',
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Error al terminar el viaje.',
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
            }
        } catch (e) {
            toast({
                title: 'Error al terminar el viaje.',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    }

    return (
        <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
            <VolverTitulo texto="Viaje particular" volver="" />
            {viajeParticular &&
                <Box w="85%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" h="100vh" mt="60px">
                    <Box fontWeight="600" w="100%" gap="20px" display="flex" flexDirection={["column", "column", "row"]}>
                        <Box w="100%">
                            <Flex w="100%" flexDirection="column">
                                <Box w="100%">
                                    <Text color="#adacac" fontSize="13px">ORIGEN</Text>
                                    <Text maxW="100%" isTruncated mt="-5px">{viajeParticular.origen}</Text>
                                </Box>
                                <Spacer />
                                <Box w="100%">
                                    <Text color="#adacac" fontSize="13px">DESTINO</Text>
                                    <Text maxW="100%" isTruncated mt="-5px">{viajeParticular.destino}</Text>
                                </Box>
                            </Flex>
                            <Divider mb="10px" mt="-3px" />
                            <Flex w="100%">
                                <Box>
                                    <Text color="#adacac" fontSize="13px">FECHA</Text>
                                    <Text textAlign="left" mt="-5px">{viajeParticular.fecha}</Text>
                                </Box>
                                <Spacer />
                                <Box>
                                    <Text color="#adacac" fontSize="13px">PASAJEROS</Text>
                                    <Text textAlign="right" mt="-5px">{viajeParticular.pasajeros}</Text>
                                </Box>
                            </Flex>
                            <Divider mb="10px" mt="-3px" />
                            <Flex w="100%" justifyContent="flex-end">
                                <Box>
                                    <Text textAlign="right" color="#adacac" fontSize="13px">PRECIO</Text>
                                    <Text textAlign="right" mt="-5px">${viajeParticular.precio}</Text>
                                </Box>
                            </Flex>
                            <Divider mb="10px" mt="-3px" />
                        </Box>
                        <Box w="100%" >
                            {viajeParticular.realizado == "false" &&
                                <Flex w="100%" direction="column">
                                    {adminId &&
                                        <Box w="100%" display="flex" flexDirection="column" gap="10px">
                                            <Button onClick={() => { enviarDatosChofer(); }} w="100%" h="40px" leftIcon={<BsWhatsapp />} colorScheme='teal' variant='outline'>Enviar datos al chofer</Button>
                                            <Button w="100%" h="40px" onClick={() => { popupCancelarViaje(viajeParticular.id) }} color="#fff" variant='solid' leftIcon={<MdOutlineCancel />} bg='red.500' >Cancelar viaje</Button>
                                            <Button onClick={() => { terminarViaje() }} w="100%" h="40px" color="#fff" variant='solid' leftIcon={<AiOutlineCheckCircle />} bg='primary.100' > Finalizar viaje </Button>
                                        </Box>}
                                </Flex>}

                            {viajeParticular.realizado == "true" &&
                                <Flex w="100%" >
                                    <Box w="100%" bg="primary.100" h="40px" display="flex" justifyContent="center" alignItems="center" borderRadius="5px">
                                        <Text color="#fff">Viaje terminado.</Text>
                                    </Box>
                                </Flex>}

                            {viajeParticular.id_solicitador == userId &&
                                <Flex w="100%" >
                                    <Button w="100%" h="40px" onClick={() => { popupCancelarViaje(viajeParticular.id) }} color="#fff" variant='solid' leftIcon={<MdOutlineCancel />} bg='red.500' >Cancelar viaje</Button>
                                </Flex>}

                            <Flex w="100%" mt="15px" direction="column">
                                <Box w="100%">
                                    <Text color="#adacac" fontSize="13px">CHOFER</Text>
                                </Box>
                                {chofer &&
                                    <Flex mt="5px" index={chofer.id}>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            <Image w="50px" h="50px" objectFit='cover' borderRadius="25px"
                                                src="https://cdn-icons-png.flaticon.com/128/5651/5651503.png" />
                                        </Box>
                                        <Flex direction="column">
                                            <Text ml="10px" mb="-5px" fontWeight="bold">{chofer.marca_vehiculo} - {chofer.modelo_vehiculo}</Text>
                                            <Text ml="10px" mb="-5px" fontWeight="bold">{chofer.patente_vehiculo} - {chofer.color_vehiculo}</Text>
                                            <Text ml="10px" color="#adacac" fontSize="16px">{chofer.nombre}</Text>
                                        </Flex>
                                    </Flex>

                                }
                            </Flex>
                            <Flex w="100%" mt="15px" direction="column">
                                <Box w="100%">
                                    <Text color="#adacac" fontSize="13px">PASAJEROS</Text>
                                </Box>
                                {pasajero &&
                                    <Flex mt="5px" key={pasajero.id}>
                                        <Box>
                                            <Image w="40px" h="40px" objectFit='cover' borderRadius="20px" src="https://cdn-icons-png.flaticon.com/128/149/149071.png" />
                                        </Box>
                                        <Flex direction="column" h="100%" justifyContent="center">
                                            {userId && <Text ml="10px">{pasajero.nombre} (Tú)</Text>}
                                            {adminId && <Text ml="10px">{pasajero.nombre}</Text>}
                                        </Flex>
                                    </Flex>
                                }
                            </Flex>
                        </Box>
                    </Box>
                </Box>
            }
            {isCancelarOpen &&
                <Modal isOpen={isCancelarOpen} onClose={onCancelarClose}>
                    <ModalOverlay />
                    <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
                        <ModalHeader>Cancelar viaje</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            < Text>¿Desea cancelar el viaje?</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button bg='primary.100' color="#fff" mr={3} onClick={() => { cancelarViaje() }}>
                                Si, cancelar
                            </Button>
                            <Button onClick={onCancelarClose} bg="#">No</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>}
        </Box>
    )
}

export default ViajeParticular;