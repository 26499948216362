import { Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useMediaQuery, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiEdit } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import { getChoferes } from '../../database';
import EditarChofer from './EditarChofer';

const TablaChoferes = () => {
    const [choferes, setChoferes] = useState();
    const [idChofer, setIdChofer] = useState();
    const navigate = useNavigate();

    const obtenerChoferes = async () => {
        const resultadoAgregarChofer = await getChoferes('0', '0');

        if (resultadoAgregarChofer.length > 0) {
            setChoferes(resultadoAgregarChofer);
        }
    }

    useEffect(() => {
        obtenerChoferes();
    }, [])

    const toast = useToast();
    const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
    const { isOpen, onOpen, onClose } = useDisclosure();

    const obtenerChoferEditar = (id) => {
        setIdChofer(id);
        onOpen();
    }

    useEffect(() => {
        obtenerChoferes();
    }, [isOpen]);

    return (
        <>
            <TableContainer w="100%">
                <Table size='sm' colorScheme='primary'>
                    <Thead>
                        <Tr h="40px">
                            <Th w="35%" p="0">Nombre</Th>
                            <Th w="35%" p="0">Ciudad</Th>
                            <Th w="10%" textAlign="right" p="0"></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {choferes && choferes.map((data, index) => (
                            <Tr key={index} h="40px">
                                <Td p="0" maxW="40px" h="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                    {data.nombre}
                                </Td>
                                <Td p="0" maxW="40px" h="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                    {data.localidad}
                                </Td>
                                <Td p="0" display="flex" flexDirection="row" h="40px" justifyContent="flex-end" alignItems="center" gap="5px">
                                    <Box display="flex" justifyContent="center" alignItems="center" borderRadius="10px" w="25px" h="25px">
                                        <BiEdit cursor="pointer" fontSize="23px" color='gray.300' onClick={() => { obtenerChoferEditar(data.id) }} />
                                    </Box>
                                </Td>
                            </Tr>
                        ))}

                    </Tbody>
                </Table>
            </TableContainer>
            {!isLargerThan800 &&
                <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} autoFocus={false}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerHeader mt="20px" mb="10px" p="0" w="100%" h="150px" display="flex" justifyContent="center">
                            <Text>Datos del chofer</Text>
                        </DrawerHeader>
                        <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
                            <EditarChofer id={idChofer} cerrarPopup={onClose} />
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>}
            {isLargerThan800 &&
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent w={["380px", "100%", "100%"]}>
                        <ModalHeader>Datos del chofer</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <EditarChofer id={idChofer} cerrarPopup={onClose} />
                        </ModalBody>
                    </ModalContent>
                </Modal>}
        </>
    )
}

export default TablaChoferes