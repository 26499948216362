import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Divider, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import FormLogin from '../../components/usuario/FormLogin';
import logo from '../../img/logo.png';
import logoBlanco from '../../img/logo-blanco.png';
import FormRegistro from '../../components/usuario/FormRegistro';
import fondo from '../../img/viaje.jpg';
import { useNavigate } from 'react-router-dom';
import MenuPresentacion from '../../components/usuario/MenuPresentacion';
import Carrousel from '../../components/Carrousel';
import banner1 from '../../img/Banner-04.jpg';
import banner2 from '../../img/Banner-05.jpg';
import banner3 from '../../img/Banner-06.jpg';
import { obtenerPublicidades } from '../../database';

const Presentación = ({ setUser }) => {
    const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
    const { isOpen: isOpenLogin, onOpen: onOpenLogin, onClose: onCloseLogin } = useDisclosure();
    const { isOpen: isOpenRegistro, onOpen: onOpenRegistro, onClose: onCloseRegistro } = useDisclosure();
    const navigate = useNavigate();
    const [publicidades, setPublicidades] = useState([]);

    const images = [
        {
            url: banner1,
        },
        {
            url: banner2,
        },
        {
            url: banner3,
        },
    ];

    const servicios = [
        {
            "title": "Viajes compartidos",
            "description": "Una alternativa económica y cómoda para tus viajes de larga distancia. Consultá nuestros recorridos, reservá tu lugar, y nosotros nos encargamos del resto."
        },
        {
            "title": "Viajes exclusivos",
            "description": "Realizamos viajes exclusivos desde Buenos Aires y Concepción del Uruguay hacia cualquier punto del país. Podés solicitarlo por nuestra web con un mínimo de 3 días de anticipación."
        },
        {
            "title": "Encomiendas",
            "description": "Si necesitás enviar documentos o paquetes de Buenos Aires a Entre Ríos y viceversa, podés solicitarlo por nuestra web, brindándonos una descripción del paquete a enviar y la dirección de salida y recepción del mismo."
        },
        {
            "title": "Transporte de mascotas",
            "description": "Somos una empresa pet friendly, y podemos brindarte todas las condiciones para que tus mascotas viajen seguras y tranquilas."
        }
    ]

    const obtenerPublicidad = async () => {
        const publicidad = await obtenerPublicidades();

        if (publicidad.success) {
            setPublicidades(publicidad.publicidades);
        }
    }

    useEffect(() => {
        obtenerPublicidad();
    }, []);


    return (
        <>
            <MenuPresentacion />
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" gap="10px" backgroundImage={fondo} backgroundSize="cover" backgroundPosition="center" w="100%" h="100vh" position="relative" top="0">
                <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    background="rgba(0, 0, 0, 0.7)" // Fondo oscuro semitransparente
                    zIndex="" // Colocar detrás del contenido
                ></Box>
                <Box mt="-100px" w="90%" gap="30px" zIndex="10" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                    <Box zIndex="100" w="100%" display="flex" justifyContent="center">
                        <Image w="300px" src={logoBlanco} />
                    </Box>
                    <Box zIndex="10" w="100%" display="flex" justifyContent="center" flexDirection="column" alignItems="center" mt="-80px">
                        <Text color="#fff" fontSize="22px" fontWeight="600">Viajes compartidos</Text>
                        <Text color="#fff" fontSize="22px" fontWeight="600">Viajes exclusivos</Text>
                        <Text color="#fff" fontSize="22px" fontWeight="600">Servicio puerta a puerta</Text>
                    </Box>
                    <Box gap="15px" zIndex="10" w="100%" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                        <Text textAlign="center" color="#fff" fontSize="18px">Salidas todos los días conectando CABA y Gran Buenos Aires con el sur de Entre Ríos</Text>
                        <Text textAlign="center" color="#fff" fontSize="18px">Viajes de larga distancia exclusivos desde Buenos Aires y Entre Ríos a cualquier punto del país</Text>
                    </Box>
                    <Box zIndex="10" w="350px" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                        <Button w="100%" h="40px" onClick={() => { navigate('/vermasviajes') }} borderColor="#8C3333" color="#fff" variant='outline'>Ver viajes disponibles</Button>
                    </Box>
                </Box>
            </Box>
            <Box w="100%">
                <Carrousel images={images} />
            </Box>
            <Box w="100%" display="flex" flexDirection="column" alignItems="center" pb="80px">
                <Box w="100%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" borderRadius="20px 20px 0 0" bg="#fff" zIndex="100" >
                    <Box w="100%" zIndex="10" maxW={["400px", "600px", "800px"]} boxShadow="2px 2px 5px 2px rgba(30,30,30,0.5)" borderRadius="20px 20px 0 0" bg="rgba(255,255,255,1)" display="flex" flexDirection="row" alignItems="center" justifyContent="space-evenly" h="65px" position="fixed" bottom="0px">
                        <Button h="35px" w="150px" bg="primary.100" color="#fff" onClick={onOpenLogin}>Iniciá sesión </Button>
                        <Button h="35px" borderColor="primary.100" w="150px" color="primary.100" variant='outline' onClick={onOpenRegistro}>Registrate </Button>
                    </Box>
                    <Box gap="15px" mt="20px" w="90%" display="flex" flexDirection="column" alignItems="flex-start">
                        <Box w="100%" display="flex" flexDirection="column" alignItems="flex-start" gap="10px">
                            <Text w="100%" textDecoration="underline" textDecorationColor="primary.100" fontSize="22px" color="#1e1e1e" textAlign="center" fontWeight="bold">Sobre nosotros</Text>
                            <Text w="100%" textAlign="start" fontSize="17px">Somos una empresa dedicada al servicio puerta a puerta de larga distancia. Contamos con choferes profesionales habilitados y todas las garantías de seguridad.</Text>
                        </Box>

                        <Button onClick={() => { navigate('/vermasviajes') }} w="100%" color="#fff" bgGradient="linear(to bottom, #A40000, #EA0000)" _hover={{ bgGradient: "linear(to bottom, #EA0000, #A40000)" }} _focus={{ boxShadow: "outline" }} mt="20px" mb="20px">¡Quiero viajar!</Button>

                        <Text w="100%" textDecoration="underline" textDecorationColor="primary.100" fontSize="22px" color="#1e1e1e" textAlign="center" fontWeight="bold">Nuestros servicios</Text>

                        <Accordion allowToggle w="100%">
                            {servicios.map((service, index) => (
                                <AccordionItem key={index}>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="start">
                                                <Text fontSize="18px" color="#1e1e1e" fontWeight="500">
                                                    {service.title}
                                                </Text>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Text textAlign="start" fontSize="17px">
                                            {service.description}
                                        </Text>
                                    </AccordionPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>

                    </Box>
                    {!isLargerThan800 &&
                        <Drawer placement="bottom" onClose={onCloseLogin} isOpen={isOpenLogin} autoFocus={false}>
                            <DrawerOverlay />
                            <DrawerContent>
                                <DrawerHeader p="0" w="100%" h="150px" display="flex" justifyContent="center">
                                    <Image src={logo} w="150px" />
                                </DrawerHeader>
                                <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" gap="20px">
                                    <Box w="100%" textAlign="center">
                                        <Text fontSize="20px" fontWeight="bold">¡Bienvenido!</Text>
                                    </Box>
                                    <Box w="100%">
                                        <FormLogin setUser={setUser} />
                                    </Box>
                                </DrawerBody>
                            </DrawerContent>
                        </Drawer>}
                    {isLargerThan800 &&
                        <Modal isOpen={isOpenLogin} onClose={onCloseLogin} autoFocus={false}>
                            <ModalOverlay />
                            <ModalContent mt="10%">
                                <DrawerHeader p="0" w="100%" h="150px" display="flex" justifyContent="center">
                                    <Image src={logo} w="150px" />
                                </DrawerHeader>
                                <ModalCloseButton />
                                <ModalBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
                                    <Box w="100%" textAlign="center">
                                        <Text fontSize="20px" fontWeight="bold">¡Bienvenido!</Text>
                                    </Box>
                                    <Box w="100%">
                                        <FormLogin setUser={setUser} />
                                    </Box>
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                    }
                    {!isLargerThan800 && <Drawer placement="bottom" onClose={onCloseRegistro} isOpen={isOpenRegistro} autoFocus={false}>
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerHeader p="0" w="100%" h="150px" display="flex" justifyContent="center">
                                <Image src={logo} w="150px" />
                            </DrawerHeader>
                            <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" gap="20px">
                                <Box w="100%" textAlign="center">
                                    <Text fontSize="20px" fontWeight="bold">Registrate gratis</Text>
                                </Box>
                                <Box w="100%">
                                    <FormRegistro />
                                </Box>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>}
                    {isLargerThan800 &&
                        <Modal isOpen={isOpenRegistro} onClose={onCloseRegistro} autoFocus={false}>
                            <ModalOverlay />
                            <ModalContent mt="5%">
                                <DrawerHeader p="0" w="100%" h="150px" display="flex" justifyContent="center">
                                    <Image src={logo} w="150px" />
                                </DrawerHeader>
                                <ModalCloseButton />
                                <ModalBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
                                    <Box w="100%" textAlign="center">
                                        <Text fontSize="20px" fontWeight="bold">Registrate gratis</Text>
                                    </Box>
                                    <Box w="100%">
                                        <FormRegistro />
                                    </Box>
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                    }
                </Box>

            </Box>
            {publicidades && <Box w="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb="80px">
                <Text mb="10px" w="100%" textDecoration="underline" textDecorationColor="primary.100" fontSize="22px" color="#1e1e1e" textAlign="center" fontWeight="bold">Quienes nos acompañan</Text>
                <Carrousel images={publicidades} />
            </Box>}
        </>

    )
}

export default Presentación