import { Box, Button, FormControl, Input, InputGroup, InputLeftAddon, InputLeftElement, Text, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { AiFillCar } from 'react-icons/ai'
import { BiSolidColorFill } from 'react-icons/bi'
import { BsPerson } from 'react-icons/bs'
import { MdAlternateEmail, MdOutlineLocationOn } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { actualizarChofer, getChoferPorId } from '../../database'

const EditarChofer = ({ id, cerrarPopup }) => {
    const navigate = useNavigate();
    const toast = useToast();

    const [cargando, setCargando] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '',
        correo: '',
        localidad: '',
        caract_cel: '',
        nro_cel: '',
        marca_vehiculo: '',
        modelo_vehiculo: '',
        patente_vehiculo: '',
        color_vehiculo: ''
      });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

      function validarPatente(patente) {
        const expresionPatenteVieja = /^[a-zA-Z]{3}\d{3}$/;
        const expresionPatenteNueva = /^[A-Z]{2}\d{3}[A-Z]{2}$/;
        if (patente.length == 6){
            return expresionPatenteVieja.test(patente);
        } else if (patente.length == 7){
            return expresionPatenteNueva.test(patente);
        }
      }

      const handleClick = async() =>{
        try{
          setCargando(false);
    
          const resultadoActualizar = await actualizarChofer(id, formData);

          console.log(resultadoActualizar);
          
          if (resultadoActualizar.success) {
            cerrarPopup();
            toast({
              title: 'Chofer actualizado.',
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
          } else {
            cerrarPopup();
            toast({
              title: 'Error al actualizar el chofer.',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
          }
        } catch(e){
    
        }
      }

      const obtenerChofer = async (id) =>{
        const resultadoChofer = await getChoferPorId(id);
    
        if (resultadoChofer.success){
            const data = resultadoChofer.data;
    
            setFormData({
                nombre: data.nombre,
                correo: data.correoelectronico,
                localidad: data.localidad,
                caract_cel: data.nro_celular.split('-')[0],
                nro_cel: data.nro_celular.split('-')[1],
                marca_vehiculo: data.marca_vehiculo,
                modelo_vehiculo: data.modelo_vehiculo,
                patente_vehiculo: data.patente_vehiculo,
                color_vehiculo: data.color_vehiculo
              });
        }
      }
    
      useEffect(() => {
        obtenerChofer(id);
      }, []);

  return (
    <>
    {formData && <FormControl w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
        <Box  w="100%">
            <Text textAlign="left">CHOFER</Text>
        </Box>
        <Box w="100%">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <BsPerson color='gray.300' />
                </InputLeftElement>
                <Input w="100%" h="40px" variant='outline'  placeholder={formData.nombre ? formData.nombre : "Apellido y nombre"}  name="nombre" onChange={handleChange} />
            </InputGroup>
        </Box>
        <Box w="100%">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <MdAlternateEmail color='gray.300' />
                </InputLeftElement>
                <Input w="100%" h="40px" variant='outline'  placeholder={formData.correo ? formData.correo : "Correo electrónico"}  name="correo" onChange={handleChange} />
            </InputGroup>
        </Box>
        <Box w="100%">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <MdOutlineLocationOn color='gray.300' />
                </InputLeftElement>
                <Input type='text' w="100%" h="40px" variant='outline' placeholder={formData.localidad ? formData.localidad : "Localidad"} name="localidad" onChange={handleChange} />
            </InputGroup>
        </Box>
        <Box w="100%" display="flex" flexDirection="row" gap="10px">
            <Box w="48.5%">
                <InputGroup>
                    <InputLeftAddon children='0'/>
                    <Input type='number' w="100%" h="40px" variant='outline' placeholder={formData.caract_cel ? formData.caract_cel : "Característica celular"} name="caract_cel" onChange={handleChange} />
                </InputGroup>
            </Box>
            <Box w="48.5%">
                <InputGroup>
                    <InputLeftAddon children='15'/>
                    <Input type='number' w="100%" h="40px" variant='outline' placeholder={formData.nro_cel ? formData.nro_cel : "Número celular"} name="nro_cel" onChange={handleChange} />
                </InputGroup>
            </Box>
        </Box>
        <Box  w="100%">
            <Text textAlign="left">VEHÍCULO</Text>
        </Box>
        <Box w="100%">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <AiFillCar color='gray.300' />
                </InputLeftElement>
                <Input type='text' w="100%" h="40px" variant='outline' placeholder={formData.marca_vehiculo ? formData.marca_vehiculo : "Marca"} name="marca_vehiculo" onChange={handleChange} />
            </InputGroup>
        </Box>
        <Box w="100%">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <AiFillCar color='gray.300' />
                </InputLeftElement>
                <Input type='text' w="100%" h="40px" variant='outline' placeholder={formData.modelo_vehiculo ? formData.modelo_vehiculo : "Modelo"} name="modelo_vehiculo" onChange={handleChange} />
            </InputGroup>
        </Box>
        <Box w="100%" display="flex" flexDirection="row" gap="10px">
            <Box w="48.5%">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <BiSolidColorFill color='gray.300' />
                    </InputLeftElement>
                    <Input type='text' w="100%" h="40px" variant='outline' placeholder={formData.color_vehiculo ? formData.color_vehiculo : "Color"} name="color_vehiculo" onChange={handleChange} />
                </InputGroup>
            </Box>
            <Box w="48.5%">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <AiFillCar color='gray.300' />
                    </InputLeftElement>
                    <Input type='text' w="100%" h="40px" variant='outline' placeholder={formData.patente_vehiculo ? formData.patente_vehiculo : "Patente"} name="patente_vehiculo" onChange={handleChange} />
                </InputGroup>
            </Box>
        </Box>
        <Box w="100%" mt="20px" mb={["0", "15px"]}>
            <Button w="100%" h="40px" leftIcon={<AiFillCar />} isLoading={cargando} onClick={handleClick} bg='primary.100' color="#fff" variant='solid'>Editar chofer</Button>
        </Box>
    </FormControl>}
    </>
    
  )
}

export default EditarChofer