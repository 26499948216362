import React, { useState } from 'react';
import { Box, Flex, IconButton, Text, useMediaQuery } from '@chakra-ui/react';
import { AiFillHome, AiOutlineSearch, AiFillHeart, AiFillCar } from 'react-icons/ai';
import { GiSteeringWheel } from 'react-icons/gi';
import { BsFillBagCheckFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { FaSuitcaseRolling } from 'react-icons/fa';

const TabMenu = () => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [activeTab, setActiveTab] = useState('home');
  const navigate = useNavigate();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <Flex
      position={isMobile ? 'fixed' : 'static'}
      bottom={isMobile ? 0 : 'unset'}
      width="100%"
      h="50px"
      maxW={["100%", "600px", "800px"]}
      background="white"
      borderTopWidth={isMobile ? '1px' : 0}
      boxShadow={isMobile ? '0px -2px 4px rgba(0, 0, 0, 0.1)' : 'none'}
      justifyContent="space-around"
      alignItems="center"
      padding={2}
      bg="primary.100"
      display={isMobile ? 'flex' : 'none'}
    >
      <Box>
        <GiSteeringWheel onClick={()=>{navigate('/crearviaje')}} fontSize="25px" color="#f7f7f7" />
      </Box>
      <Box>
        <AiFillCar onClick={()=>{navigate('/agregarchofer')}} fontSize="25px" color="#f7f7f7"/>
      </Box>
      <Box>
        <FaSuitcaseRolling fontSize="25px" color="#f7f7f7"/>
      </Box>
    </Flex>
  );
};

export default TabMenu;
