import { Box, Button, FormControl, Input, InputGroup, InputLeftAddon, InputLeftElement, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { AiFillCar } from 'react-icons/ai'
import { BsPerson } from 'react-icons/bs'
import { MdAlternateEmail, MdOutlineLocationOn } from 'react-icons/md'
import { BiSolidColorFill } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'
import {registrarChofer} from '../../database';

const FormAgregarChofer = () => {
    const navigate = useNavigate();
    const toast = useToast();

    const [cargando, setCargando] = useState(false);
    const [formData, setFormData] = useState({
        apynom: '',
        correo: '',
        localidad: '',
        caract_cel: '',
        nro_cel: '',
        marca_vehiculo: '',
        modelo_vehiculo: '',
        patente_vehiculo: '',
        color_vehiculo: ''
        // url_imagen_vehiculo: ''
      });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };

    function validarPatente(patente) {
        const expresionPatenteVieja = /^[a-zA-Z]{3}\d{3}$/;
        const expresionPatenteNueva = /^[A-Z]{2}\d{3}[A-Z]{2}$/;
        if (patente.length == 6){
            return expresionPatenteVieja.test(patente);
        } else if (patente.length == 7){
            return expresionPatenteNueva.test(patente);
        }
      }

      const handleClick = async() =>{
        setCargando(true);

        if (formData.apynom.length < 5) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "El apellido y nombre debe tener al menos 5 caracteres.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (!formData.correo || !formData.correo.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setCargando(false);
            toast({
              title: 'Datos erróneos',
              description: 'Por favor, ingrese un correo electrónico válido.',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
            return;
        }

        if (formData.localidad == "") {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "La localidad es requerida.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (!(formData.caract_cel.length <= 4) || !(formData.nro_cel.length >= 2)) {
            setCargando(false);
            toast({
              title: 'Datos erróneos',
              description: 'La característica debe tener como máximo 4 dígitos.',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
            return;
          }
      
        if (!(formData.nro_cel.length <= 8) || !(formData.nro_cel.length >= 4)) {
            setCargando(false);
        toast({
            title: 'Datos erróneos',
            description: 'El número de celular debe tener como máximo 8 dígitos.',
            status: 'error',
            duration: 4000,
            isClosable: true,
        });
            return;
        }

        if (formData.marca_vehiculo.length < 3) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "La marca debe tener al menos 3 caracteres.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (formData.modelo_vehiculo.length < 2) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "El modelo debe tener al menos 2 caracteres.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (formData.color_vehiculo.length < 4) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "El color debe tener al menos 4 caracteres.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (!validarPatente(formData.patente_vehiculo)) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "La patente ingresada no es correcta.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        const resultadoAgregarChofer = await registrarChofer(formData);

        if (resultadoAgregarChofer.success) {
            setCargando(false);
            toast({
                title: 'Chofer agregado correctamente',
                status: 'success',
                duration: 2000,
                isClosable: true,
            })

            setTimeout(() => {
                navigate(`/admin`)
            }, 2000);
        } else {
            setCargando(false);
            if (resultadoAgregarChofer.error){
                toast({
                    title: 'Chofer ya registrado',
                    description: 'El correo ingresado ya se encuentra registrado.',
                    status: 'error',
                    isClosable: true,
                });
            } else{
                toast({
                    title: 'Error',
                    description: 'Se produjo un error al intentar registrar un chofer.',
                    status: 'error',
                    isClosable: true,
                });
            }
        }
      
        // const res = await fetch("http://localhost:4000/registrar-chofer",{
        //     method: 'POST',
        //     body: JSON.stringify(formData),
        //     headers: {"Content-Type": "application/json",}
        // });

        // setCargando(false);
        // if (res.ok){
        //     toast({
        //         title: 'Chofer agregado correctamente',
        //         status: 'success',
        //         duration: 2000,
        //         isClosable: true,
        //     })

        //     setTimeout(() => {
        //         navigate(`/admin`)
        //     }, 2000);
        // } 
        // else if (res.status == '409'){
        //     toast({
        //         title: 'Chofer ya registrado',
        //         description: 'El correo ingresado ya se encuentra registrado.',
        //         status: 'error',
        //         isClosable: true,
        //     });
        // } else {
        //     toast({
        //         title: 'Error',
        //         description: 'Se produjo un error al intentar registrar un chofer.',
        //         status: 'error',
        //         isClosable: true,
        //     });
        // }
    };

    // const handleImageChange = (event) => {
    //     const file = event.target.files[0];
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         url_imagen_vehiculo: file,
    //     }));
    //   };



  return (
    <FormControl w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
        <Box  w="100%">
            <Text textAlign="left">CHOFER</Text>
        </Box>
        <Box w="100%">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <BsPerson color='gray.300' />
                </InputLeftElement>
                <Input w="100%" h="40px" variant='outline'  placeholder="Apellido y nombre"  name="apynom" onChange={handleChange} />
            </InputGroup>
        </Box>
        <Box w="100%">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <MdAlternateEmail color='gray.300' />
                </InputLeftElement>
                <Input w="100%" h="40px" variant='outline'  placeholder="Correo electrónico"  name="correo" onChange={handleChange} />
            </InputGroup>
        </Box>
        <Box w="100%">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <MdOutlineLocationOn color='gray.300' />
                </InputLeftElement>
                <Input type='text' w="100%" h="40px" variant='outline' placeholder="Localidad" name="localidad" onChange={handleChange} />
            </InputGroup>
        </Box>
        <Box w="100%" display="flex" flexDirection="row" gap="10px">
            <Box w="48.5%">
                <InputGroup>
                    <InputLeftAddon children='0'/>
                    <Input type='number' w="100%" h="40px" variant='outline' placeholder="3446" name="caract_cel" onChange={handleChange} />
                </InputGroup>
            </Box>
            <Box w="48.5%">
                <InputGroup>
                    <InputLeftAddon children='15'/>
                    <Input type='number' w="100%" h="40px" variant='outline' placeholder="302010" name="nro_cel" onChange={handleChange} />
                </InputGroup>
            </Box>
        </Box>
        <Box  w="100%">
            <Text textAlign="left">VEHÍCULO</Text>
        </Box>
        <Box w="100%">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <AiFillCar color='gray.300' />
                </InputLeftElement>
                <Input type='text' w="100%" h="40px" variant='outline' placeholder="Marca" name="marca_vehiculo" onChange={handleChange} />
            </InputGroup>
        </Box>
        <Box w="100%">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <AiFillCar color='gray.300' />
                </InputLeftElement>
                <Input type='text' w="100%" h="40px" variant='outline' placeholder="Modelo" name="modelo_vehiculo" onChange={handleChange} />
            </InputGroup>
        </Box>
        <Box w="100%" display="flex" flexDirection="row" gap="10px">
            <Box w="48.5%">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <BiSolidColorFill color='gray.300' />
                    </InputLeftElement>
                    <Input type='text' w="100%" h="40px" variant='outline' placeholder="Color" name="color_vehiculo" onChange={handleChange} />
                </InputGroup>
            </Box>
            <Box w="48.5%">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <AiFillCar color='gray.300' />
                    </InputLeftElement>
                    <Input type='text' w="100%" h="40px" variant='outline' placeholder="Patente" name="patente_vehiculo" onChange={handleChange} />
                </InputGroup>
            </Box>
        </Box>
        {/* <Box  w="100%">
            <Text textAlign="left">Foto del vehículo</Text>
        </Box>
        <Box w="100%">
            <input type="file" accept="image/*" onChange={handleImageChange}/>
        </Box> */}
        <Box w="100%" mt="20px">
            <Button w="100%" h="40px" leftIcon={<AiFillCar />} isLoading={cargando} onClick={handleClick} bg='primary.100' color="#fff" variant='solid'>Agregar chofer</Button>
        </Box>
    </FormControl>
  )
}

export default FormAgregarChofer