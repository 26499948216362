import { Box, Button, Collapse, FormControl, FormLabel, Input, InputGroup, InputLeftElement, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BiCurrentLocation } from 'react-icons/bi';
import { FaSearchLocation } from 'react-icons/fa';
import { MdLocationOn, MdLocationPin, MdOutlineLocationOn } from 'react-icons/md';
import { TbLocationFilled } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const FormBuscarViaje = ({ hayViajes, origen, destino, setHayViajes = '' }) => {
  const { isOpen: isOriginModalOpen, onOpen: onOriginModalOpen, onClose: onOriginModalClose } = useDisclosure();
  const { isOpen: isDestinationModalOpen, onOpen: onDestinationModalOpen, onClose: onDestinationModalClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  const [cargando, setCargando] = useState(false);
  const [listOrigen, showListOrigen] = useState(false);
  const [listDestino, showListDestino] = useState(false);
  const [provinciaOrigen, setProvinciaOrigen] = useState('');
  const [provinciaDestino, setProvinciaDestino] = useState('');

  const handleProvinciaDestino = (event) => {
    setProvinciaDestino(event.target.value);
  };

  const handleProvinciaOrigen = (event) => {
    setProvinciaOrigen(event.target.value);
  };

  const handleClick = () => {
    setCargando(true);

    if (!seleccionadoCompleto.origen && !seleccionadoCompleto.destino) {
      setCargando(false);
      toast({
        title: 'Datos incompletos',
        description: "Debes completar origen o destino",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return;
    }
    console.log(seleccionadoCompleto.origen)
    console.log(seleccionadoCompleto.destino)
    if (setHayViajes != '') {
      setHayViajes(true);
    }
    setCargando(false);
    window.history.replaceState({}, document.title, window.location.pathname);
    navigate(`/resultados/${seleccionadoCompleto.origen}/${seleccionadoCompleto.destino}`)
  };

  const [inputValueOrigen, setInputValueOrigen] = useState('');
  const [inputValueDestino, setInputValueDestino] = useState('');
  const [ciudadesOrigen, setCiudadesOrigen] = useState([]);
  const [ciudadesDestino, setCiudadesDestino] = useState([]);
  const containerRef = useRef(null);
  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([]);
  const [ciudadesFiltradasDestino, setCiudadesFiltradasDestino] = useState([]);

  const provinciasArgentina = [
    'Buenos Aires',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Córdoba',
    'Corrientes',
    'Entre Ríos',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquén',
    'Río Negro',
    'Salta',
    'San Juan',
    'San Luis',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
    'Tucumán',
  ];

  useEffect(() => {
    const fetchDataOrigen = async () => {
      try {
        const response = await fetch(`https://apis.datos.gob.ar/georef/api/localidades?provincia=${provinciaOrigen}&campos=nombre&max=999`);

        if (response.ok) {
          const data = await response.json();
          const localidadesModificadas = data.localidades.map(localidad => {
            if (localidad.nombre === "Ciudad de Buenos Aires") {
              return { ...localidad, nombre: "Ciudad Autónoma de Buenos Aires" };
            }
            return localidad;
          });
          setCiudadesOrigen(localidadesModificadas);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (provinciaOrigen !== '') {
      fetchDataOrigen();
      showListOrigen(false);
      setInputValueOrigen('');
    }
  }, [provinciaOrigen]);

  useEffect(() => {
    const fetchDataOrigen = async () => {
      try {
        const response = await fetch(`https://apis.datos.gob.ar/georef/api/localidades?provincia=${provinciaDestino}&campos=nombre&max=999`);

        if (response.ok) {
          const data = await response.json();
          const localidadesModificadas = data.localidades.map(localidad => {
            if (localidad.nombre === "Ciudad de Buenos Aires") {
              return { ...localidad, nombre: "Ciudad Autónoma de Buenos Aires" };
            }
            return localidad;
          });
          setCiudadesDestino(localidadesModificadas);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (provinciaDestino !== '') {
      fetchDataOrigen();
      showListDestino(false);
      setInputValueDestino('');
    }
  }, [provinciaDestino]);

  /* origen */
  useEffect(() => {
    const ciudadesFiltradas = ciudadesOrigen.filter((ciudad) =>
      typeof ciudad === 'object' && ciudad.nombre.toLowerCase().includes(inputValueOrigen.toLowerCase())
    );
    setCiudadesFiltradas(ciudadesFiltradas);
  }, [inputValueOrigen]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValueOrigen(value);
  };

  /* destino */
  useEffect(() => {
    const ciudadesFiltradas = ciudadesDestino.filter((ciudad) =>
      typeof ciudad === 'object' && ciudad.nombre.toLowerCase().includes(inputValueDestino.toLowerCase())
    );
    setCiudadesFiltradasDestino(ciudadesFiltradas);
  }, [inputValueDestino]);

  const handleInputChangeDestino = (event) => {
    const value = event.target.value;
    setInputValueDestino(value);
  };

  function capitalizeWords(str) {
    const accentMap = {
      'á': 'a',
      'é': 'e',
      'í': 'i',
      'ó': 'o',
      'ú': 'u',
      // Agrega más caracteres acentuados y sus equivalentes sin acentos según sea necesario
    };

    // Reemplaza los caracteres acentuados por sus equivalentes sin acentos
    const stringWithoutAccents = str.replace(/[áéíóú]/g, match => accentMap[match] || match);

    // Capitaliza las palabras
    return stringWithoutAccents
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const [seleccionadoCompleto, setSeleccionCompleto] = useState({
    origen: origen != '' ? origen : '',
    destino: destino != '' ? destino : ''
  }
  );

  const origenSeleccionado = (cuidad) => {
    showListOrigen(false);
    setInputValueOrigen(capitalizeWords(cuidad.nombre));

    setSeleccionCompleto({
      origen: provinciaOrigen + ', ' + capitalizeWords(cuidad.nombre)
    });
    setSeleccionCompleto((prevSeleccion) => ({
      ...prevSeleccion,
      origen: capitalizeWords(provinciaOrigen) + ', ' + capitalizeWords(cuidad.nombre)
    }));
  }

  const destinoSeleccionado = (cuidad) => {
    showListDestino(false);
    setInputValueDestino(capitalizeWords(cuidad.nombre));

    setSeleccionCompleto((prevSeleccion) => ({
      ...prevSeleccion,
      destino: capitalizeWords(provinciaDestino) + ', ' + capitalizeWords(cuidad.nombre),
    }));
  }

  return (
    <>
      <FormControl w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
        <Box position="relative" zIndex="1" ref={containerRef} w="100%">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<TbLocationFilled color='gray.300' />}
            />
            <Input onClick={onOriginModalOpen} readOnly value={seleccionadoCompleto.origen != '' ? seleccionadoCompleto.origen : inputValueOrigen} placeholder="¿Desde donde viajas?" onChange={handleInputChange} />
          </InputGroup>
        </Box>
        <Box w="100%">
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <MdOutlineLocationOn color='gray.300' />
            </InputLeftElement>
            <Input onClick={onDestinationModalOpen} value={seleccionadoCompleto.destino != '' ? seleccionadoCompleto.destino : inputValueDestino} readOnly w="100%" h="40px" variant='outline' placeholder='¿Hacia donde viajas?' />
          </InputGroup>
        </Box>
        <Box w="100%">
          <Button w="100%" h="40px" onClick={handleClick} isLoading={cargando} leftIcon={<FaSearchLocation />} bg='#8C3333' color={cargando == true ? "#8C3333" : "#fff"} variant='solid'> Buscar </Button>
        </Box>
        {!hayViajes &&
          <Box w="100%">
            <Button w="100%" h="40px" onClick={() => { navigate('/viajeparticular') }} colorScheme='blackAlpha' color="#8C3333" variant='outline'> Solicitar viaje personalizado </Button>
          </Box>}
      </FormControl>
      <Box>
        <Modal isOpen={isOriginModalOpen} onClose={onOriginModalClose}>
          <ModalOverlay />
          <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
            <ModalHeader>Complete el origen</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection="column" gap="10px">
              <Select placeholder="Provincia" value={provinciaOrigen} onChange={handleProvinciaOrigen}>
                {provinciasArgentina.map((provincia, index) => (
                  <option key={index} value={provincia}>
                    {provincia}
                  </option>
                ))}
              </Select>
              <Input onClick={() => showListOrigen(true)} value={inputValueOrigen} w="100%" h="40px" variant='outline' placeholder='Ciudad' name="origen" onChange={handleInputChange} />
              {listOrigen &&
                <Box >
                  <List mt="-10px" maxH="200px" overflowY="auto" border="1px solid #e2e8f0" borderRadius="5px">
                    {ciudadesFiltradas.map((ciudad, index) => (
                      <Box onClick={() => { origenSeleccionado(ciudad) }} key={index} display="flex" gap="10px" fontSize="18px" flexDirection="row" justifyContent="flex-start" alignItems="center" h="35px">
                        <MdLocationPin />
                        <Text>{capitalizeWords(ciudad.nombre)}</Text>
                      </Box>
                    ))}
                  </List>
                </Box>}
            </ModalBody>
            <ModalFooter>
              <Button color="#fff" bg="primary.100" w="150px" onClick={onOriginModalClose}> Seleccionar </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isDestinationModalOpen} onClose={onDestinationModalClose}>
          <ModalOverlay />
          <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
            <ModalHeader>Modal de Destino</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection="column" gap="10px">
              <Select placeholder="Provincia" value={provinciaDestino} onChange={handleProvinciaDestino}>
                {provinciasArgentina.map((provincia, index) => (
                  <option key={index} value={provincia}>
                    {provincia}
                  </option>
                ))}
              </Select>
              <Input onClick={() => showListDestino(true)} value={inputValueDestino} w="100%" h="40px" variant='outline' placeholder='Ciudad' name="destino" onChange={handleInputChangeDestino} />
              {listDestino &&
                <Box >
                  <List mt="-10px" maxH="200px" overflowY="auto" border="1px solid #e2e8f0" borderRadius="5px">
                    {ciudadesFiltradasDestino.map((ciudad, index) => (
                      <Box onClick={() => { destinoSeleccionado(ciudad) }} key={index} display="flex" gap="10px" fontSize="18px" flexDirection="row" justifyContent="flex-start" alignItems="center" h="35px">
                        <MdLocationPin />
                        <Text>{capitalizeWords(ciudad.nombre)}</Text>
                      </Box>
                    ))}
                  </List>
                </Box>}
            </ModalBody>
            <ModalFooter>
              <Button color="#fff" bg="primary.100" w="150px" colorScheme="blue" onClick={onDestinationModalClose}> Seleccionar </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  )
}

export default FormBuscarViaje