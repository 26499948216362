import { Box, Button, Input, InputGroup, InputLeftElement, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiRename } from 'react-icons/bi'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { MdOutlineCancel } from 'react-icons/md'
import { actualizarPublicidad, obtenerImagenDesdeStorage, obtenerPublicidadPorId } from '../../database'

const EditarPublicidad = ({ idPublicidad, onClose, setActualizado }) => {
    const toast = useToast();
    const [cargando, setCargando] = useState(false);
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState({
        empresa: '',
        url: ''
    });

    const obtenerPublicidad = async () => {
        const publicidad = await obtenerPublicidadPorId(idPublicidad);

        if (publicidad.success) {
            setFormData(publicidad.publicidad);
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const url = URL.createObjectURL(file);
            setFormData((prevData) => ({
                ...prevData,
                url: url,
            }));
            setFile(file);
        }
    };

    const handleClick = async () => {
        setCargando(true);
        if (file && formData) {
            const result = await actualizarPublicidad(idPublicidad, formData, file);
            if (result.success) {
                setCargando(false);
                setActualizado(true);
                onClose();
                toast({
                    title: 'Publicidad editada correctamente.',
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                });
            } else {
                setCargando(false);
                toast({
                    title: 'Error al editar la publicidad.',
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
            }
        }
    }

    useEffect(() => {
        obtenerPublicidad();
    }, []);

    return (
        <Box w="100%" display="flex" justifyContent="center" flexDirection="column" gap="15px">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <BiRename color='gray.300' />
                </InputLeftElement>
                <Input w="100%" h="40px" variant='outline' placeholder={formData.empresa ? formData.empresa : "Nombre empresa"} name="empresa" onChange={handleChange} />
            </InputGroup>
            <Box>
                <Text>Seleccione una imagen</Text>
                <Input type="file" w="100%" border="none" ml="-15px" name="url" onChange={handleImageChange} />
            </Box>

            <Box w="100%" display="flex" flexDirection="row" justifyContent="space-between">
                <Button w="40%" h="40px" leftIcon={<MdOutlineCancel />} onClick={onClose} bg='transparent' border="1px solid #8C3333" color="primary.100">Cancelar</Button>
                <Button w="55%" h="40px" leftIcon={<IoMdAddCircleOutline />} isLoading={cargando} onClick={handleClick} bg='primary.100' color="#fff" variant='solid'>Editar</Button>
            </Box>
        </Box>
    )
}

export default EditarPublicidad