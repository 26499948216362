import React, { useEffect, useState } from 'react';
import { Box, Button, InputGroup, InputLeftElement, Input, useToast } from '@chakra-ui/react'
import { FaLocationDot } from 'react-icons/fa6';
import { MdAttachMoney, MdDelete } from 'react-icons/md';
import { editarTarifa, eliminarTarifa } from '../../database';
import { getTarifaPorId } from '../../database';
import { FaRegEdit } from 'react-icons/fa';

const EditarTarifa = ({ idTarifa, onClose, setActualizado }) => {
  const toast = useToast();
  const [cargando, setCargando] = useState(false);
  const [tarifa, setTarifa] = useState({
    localidad: '',
    precio: 0
  });

  const obtenerTarifas = async () => {
    const resultadoTarifa = await getTarifaPorId(idTarifa);

    if (resultadoTarifa.success > 0) {
      setTarifa(resultadoTarifa.tarifa);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTarifa((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    obtenerTarifas();
  }, []);

  const handleClick = async () => {
    try {
      setCargando(true);

      const resultadoEditar = await editarTarifa(idTarifa, tarifa);

      console.log(resultadoEditar)

      if (resultadoEditar.success) {
        setCargando(false);
        setActualizado(true);
        onClose();
        toast({
          title: 'Tarifa editada.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      } else {
        setCargando(false);
        toast({
          title: 'Error al editar la tarifa.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (e) {

    }
  }

  const eliminar = async (id) => {
    const resultado = await eliminarTarifa(id);
    onClose();

    if (resultado.success) {
      setActualizado(true);
      toast({
        title: 'Tarifa eliminada correctamente.',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Error al eliminar la tarifa.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  }

  return (
    <Box w="100%" display="flex" justifyContent="center" flexDirection="column" gap="15px">
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <FaLocationDot color='gray.300' />
        </InputLeftElement>
        <Input w="100%" h="40px" variant='outline' placeholder={tarifa.localidad ? tarifa.localidad : "Localidad"} name="localidad" onChange={handleChange} />
      </InputGroup>
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <MdAttachMoney color='gray.300' />
        </InputLeftElement>
        <Input type="number" w="100%" h="40px" variant='outline' placeholder={tarifa.precio ? tarifa.precio : "Precio"} name="precio" onChange={handleChange} />
      </InputGroup>
      <Box w="100%" display="flex" flexDirection="row" justifyContent="space-between">
        <Button onClick={() => { eliminar(tarifa.id) }} leftIcon={<MdDelete />}>Eliminar</Button>
        <Button w="60%" h="40px" leftIcon={<FaRegEdit />} isLoading={cargando} onClick={handleClick} bg='primary.100' color="#fff" variant='solid'>Editar tarifa</Button>
      </Box>
    </Box>
  )
}

export default EditarTarifa