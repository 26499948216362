import React, { useState } from 'react';
import { Box, Button, InputGroup, InputLeftElement, Input, useToast } from '@chakra-ui/react'
import { FaLocationDot } from 'react-icons/fa6';
import { MdAttachMoney, MdOutlineCancel } from 'react-icons/md';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { agregarTarifa } from '../../database';

const FormAregarTarifa = ({ onClose, setActualizado }) => {

  const toast = useToast();
  const [cargando, setCargando] = useState(false);
  const [formData, setFormData] = useState({
    localidad: '',
    precio: 0
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClick = async () => {
    try {
      setCargando(true);

      const resultadoAgregar = await agregarTarifa(formData);

      if (resultadoAgregar.success) {
        setCargando(false);
        setActualizado(true);
        onClose();
        toast({
          title: 'Tarifa agregada.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      } else {
        setCargando(false);
        toast({
          title: 'Error al agregar la tarifa.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (e) {

    }
  }

  return (
    <Box w="100%" display="flex" justifyContent="center" flexDirection="column" gap="15px">
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <FaLocationDot color='gray.300' />
        </InputLeftElement>
        <Input w="100%" h="40px" variant='outline' placeholder={formData.localidad ? formData.localidad : "Localidad"} name="localidad" onChange={handleChange} />
      </InputGroup>
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <MdAttachMoney color='gray.300' />
        </InputLeftElement>
        <Input type="number" w="100%" h="40px" variant='outline' placeholder={formData.precio ? formData.precio : "Precio"} name="precio" onChange={handleChange} />
      </InputGroup>
      <Box w="100%" display="flex" flexDirection="row" justifyContent="space-between">
        <Button w="40%" h="40px" leftIcon={<MdOutlineCancel />} onClick={onClose} bg='transparent' border="1px solid #8C3333" color="primary.100">Cancelar</Button>
        <Button w="55%" h="40px" leftIcon={<IoMdAddCircleOutline />} isLoading={cargando} onClick={handleClick} bg='primary.100' color="#fff" variant='solid'>Agregar</Button>
      </Box>
    </Box>
  )
}

export default FormAregarTarifa