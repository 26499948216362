import { Box, Button } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import VolverTitulo from '../../components/usuario/VolverTitulo'
import TabMenu from '../../components/admin/TabMenu'
import TablaChoferes from '../../components/admin/TablaChoferes';
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const Choferes = () => {
  const navigate = useNavigate();

  function getCookie(name) {
    const cookieName = `${name}=`;
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  }

  const adminId = getCookie('adminId');

  useEffect(()=>{
    if (adminId == ''){
      navigate('/login');
    } else {
      
    }
  }, [adminId]);

  return (
    <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
        <VolverTitulo texto="Choferes" />
        <Box mt="60px" w="85%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" h="100vh" gap="10px">
            <Box w="100%" display="flex" justifyContent="flex-start">
                <Button onClick={()=>{navigate(`/agregarchofer`)}} leftIcon={<IoIosAddCircleOutline />} w="150px" h="35px" colorScheme='blackAlpha' color="#8C3333" variant='outline'>Agregar chofer</Button>
            </Box>
            <TablaChoferes />
        </Box>
        <TabMenu/>
    </Box>
  )
}

export default Choferes