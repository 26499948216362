import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/usuario/Home';
import Resultado from './pages/usuario/Resultados';
import Viaje from './pages/usuario/Viaje';
import ReportarProblema from './pages/usuario/ReportarProblema';
import MisViajes from './pages/usuario/MisViajes';
import Presentación from './pages/usuario/Presentación';
import ViajePartiuclar from './pages/usuario/ViajePartiuclar';
import HomeAdmin from './pages/admin/HomeAdmin';
import CrearViaje from './pages/admin/CrearViaje';
import AgregarChofer from './pages/admin/AgregarChofer';
import { useEffect, useState } from 'react';
import Login from './pages/admin/Login';
import VerMasViajes from './pages/usuario/VerMasViajes';
import VerViajes from './pages/admin/VerViajes';
import { getAuth } from "firebase/auth";
import { app } from "./firebase";
import Choferes from './pages/admin/Choferes';
import Tarifas from './pages/admin/Tarifas';
import ViajesParticulares from './pages/admin/ViajesParticulares';
import ViajeParticular from './pages/admin/ViajeParticular';
import Publicidades from './pages/admin/Publicidades';
import Cupones from './pages/admin/Cupones';
import { Box, Image, Text } from '@chakra-ui/react';
import logo from './img/logo.png';
import { actualizarFechasAViajes } from './database';

function App() {

  
  const [admin, setAdmin] = useState('');
  const auth = getAuth(app);
  const [user, setUser] = useState(null);
  const [mantenimiento, setMantenimiento] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log(user);
        // El usuario está autenticado, user contiene información sobre el usuario.
        setUser(user);
      } else {
        // El usuario no está autenticado, user es null.
        setUser(null);
      }
    });

    return () => {
      unsubscribe(); // Limpia el listener cuando el componente se desmonta.
    };
  }, []);

  // const actualizar = async() => {
  //   await actualizarFechasAViajes();
  // }

  // useEffect(() => {
  //   actualizar();
  // }, []);

  return (
    <>
    
      {!mantenimiento && <BrowserRouter>
        <Routes>
          {/* Rutas del usuario */}
            <Route path='/home' element={<Home user={user} />} />
            <Route path='/resultados/:origen/:destino' element={<Resultado user={user} />} />
            <Route path='/viaje/:id' element={<Viaje user={user} setUser={setUser} admin={admin}/>} />
            <Route path='/reportarproblema' element={<ReportarProblema user={user} />} />
            <Route path='/misviajes' element={<MisViajes user={user} setUser={setUser}/>} />
            <Route index element={<Presentación setUser={setUser} />} />
            <Route path='/viajeparticular' element={<ViajePartiuclar user={user} setUser={setUser} />} />
            <Route path='/vermasviajes' element={<VerMasViajes  />} />

          {/* Rutas del admin */}
            <Route path='/admin' element={<HomeAdmin admin={admin}/>} />
            <Route path='/crearviaje' element={<CrearViaje admin={admin}/>} />
            <Route path='/choferes' element={<Choferes admin={admin}/>} />
            <Route path='/tarifas' element={<Tarifas admin={admin}/>} />
            <Route path='/agregarchofer' element={<AgregarChofer admin={admin}/>} />
            <Route path='/login' element={<Login setAdmin={setAdmin}/>} />
            <Route path='/viajes-creados' element={<VerViajes setAdmin={setAdmin}/>} />
            <Route path='/publicidades' element={<Publicidades setAdmin={setAdmin}/>} />
            <Route path='/cupones' element={<Cupones setAdmin={setAdmin}/>} />
            <Route path='/viajes-particulares' element={<ViajesParticulares setAdmin={setAdmin}/>} />
            <Route path='/viaje-particular/:id' element={<ViajeParticular setAdmin={setAdmin} user={user} setUser={setUser}/>} />
        </Routes>
      </BrowserRouter>}
      {mantenimiento &&
        <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh" mt="50px">
          <Box>
            <Image w="120px" src={logo} />
          </Box>
          <Text fontWeight="bold">Nos encontramos en mantenimiento.</Text>
          <Text mt="20px" textAlign="center">Si tienes alguna consulta, por favor, ponte en contacto al 1136575905 / 3442644575.</Text>
        </Box>}
    </>
  );
}

export default App;
