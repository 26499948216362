import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import VolverTitulo from '../../components/usuario/VolverTitulo'
import TabMenu from '../../components/admin/TabMenu'
import TablaChoferes from '../../components/admin/TablaChoferes';
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import TablaTarifas from '../../components/admin/TablaTarifas';
import FormAregarTarifa from '../../components/admin/FormAregarTarifa';


const Tarifas = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [actualizado, setActualizado] = useState(false);

  function getCookie(name) {
    const cookieName = `${name}=`;
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  }

  const adminId = getCookie('adminId');

  useEffect(() => {
    if (adminId == '') {
      navigate('/login');
    } else {

    }
  }, [adminId]);



  return (
    <Box w="100%" display="flex" flexDirection="column" alignItems="center">
      <VolverTitulo texto="Tarifas" />
      <Box mt="60px" w="85%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" gap="10px">
        <Box w="100%" display="flex" justifyContent="flex-start">
          <Button onClick={onOpen} leftIcon={<IoIosAddCircleOutline />} w="150px" h="35px" colorScheme='blackAlpha' color="#8C3333" variant='outline'>Agregar tarifa</Button>
        </Box>
        <TablaTarifas setActualizado={setActualizado} actualizado={actualizado} sinLimites={true} />
      </Box>
      <TabMenu />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="85%">
          <ModalHeader>Nueva tarifa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormAregarTarifa setActualizado={setActualizado} onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default Tarifas