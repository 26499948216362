import React, { useEffect, useState } from 'react'
import VolverTitulo from '../../components/usuario/VolverTitulo';
import CardViajes from '../../components/usuario/CardViajes';
import { obtenerViajesParticulares } from '../../database';
import { useNavigate } from 'react-router-dom';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useMediaQuery } from '@chakra-ui/react';
import CardViajesParticulares from '../../components/admin/CardViajesParticulares';

const ViajesParticulares = () => {
    const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
    const navigate = useNavigate();
    const [viajesRealizados, setViajesRealizados] = useState([]);
    const [viajesNoRealizados, setViajesNoRealizados] = useState([]);

    const obtenerViajes = async () => {
        const resultadoViajes = await obtenerViajesParticulares('0', '0', '100', '0');

        console.log(resultadoViajes)

        if (resultadoViajes.success) {
            const realizados = resultadoViajes.viajesParticulares.filter((viaje) => viaje.realizado == 'true');
            const noRealizados = resultadoViajes.viajesParticulares.filter((viaje) => viaje.realizado == 'false');

            setViajesRealizados([]);
            setViajesNoRealizados([]);
            setViajesRealizados(realizados);
            setViajesNoRealizados(noRealizados);
        }
    }

    useEffect(() => {
        obtenerViajes();
    }, []);

    { viajesRealizados && console.log(viajesRealizados) }
    { viajesNoRealizados && console.log(viajesNoRealizados) }


    function getCookie(name) {
        const cookieName = `${name}=`;
        const cookiesArray = document.cookie.split(';');
        for (let i = 0; i < cookiesArray.length; i++) {
            let cookie = cookiesArray[i].trim();
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    }

    const adminId = getCookie('adminId');

    useEffect(() => {
        if (adminId == '') {
            navigate('/login');
        }
    }, [adminId]);

    return (
        <>
            <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
                <VolverTitulo texto="Viajes Particulares" />
                <Box mt="60px" w="85%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" h="100vh">
                    <Box w="100%" gap="20px" display="flex" flexDirection={["column", "column", "row"]}>
                        <Tabs variant='soft-rounded' w="100%" colorScheme='primary'>
                            <TabList w="100%" h="40px" display="flex" flexDirection="row" color="#fff" justifyContent="space-between">
                                <Tab _selected={{ color: "#fff", backgroundColor: "primary.100" }}>Pendientes</Tab>
                                <Tab _selected={{ color: "#fff", backgroundColor: "primary.100" }}>Realizados</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel p="0">
                                    <Box w="100%" mt="10px" display="flex" flexDirection="column" gap="20px">
                                        {viajesNoRealizados.length > 0 ? (
                                            viajesNoRealizados.map((noRealizado) => (
                                                <CardViajesParticulares key={noRealizado.id} viaje={noRealizado} />
                                            ))
                                        ) : (
                                            <Text>No hay viajes pendientes...</Text>
                                        )}
                                    </Box>
                                </TabPanel>
                                <TabPanel p="0">
                                    <Box w="100%" mt="10px" display="flex" flexDirection="column" gap="20px">
                                        {viajesRealizados.length > 0 ? (
                                            viajesRealizados.map((realizado) => (
                                                <CardViajesParticulares key={realizado.id} viaje={realizado} />
                                            ))
                                        ) : (
                                            <Text>No hay viajes realizados...</Text>
                                        )}
                                    </Box>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ViajesParticulares