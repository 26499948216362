import { Box, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import FormBuscarViaje from '../../components/usuario/FormBuscarViaje'
import Menu from '../../components/usuario/Menu'
import ViajesDisponibles from '../../components/usuario/ViajesDisponibles'
import Cookies from 'js-cookie';
import { obtenerUltimosViajes } from '../../database';
import logo from '../../img/logo.png';

const Home = ({ user }) => {

  const [viajes, setViajes] = useState();

  const getUserIdCookie = () => {
    return Cookies.get('user_id');
  }

  const obtenerViajesFun = async () => {
    const resultadoViajes = await obtenerUltimosViajes(3);

    if (resultadoViajes.success) {
      setViajes(resultadoViajes.viajes);
    }
  }

  useEffect(() => {
    getUserIdCookie();
    obtenerViajesFun();
  }, []);

  return (
    <>
      <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
        <Box w="85%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" h="100vh">
          <Menu />
          <Box w="100%" gap="20px" display="flex" flexDirection={["column", "column", "row"]}>
            <FormBuscarViaje />
            <ViajesDisponibles viajes={viajes} />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Home