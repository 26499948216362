import { Box, Button, FormControl, Image, Input, InputGroup, InputLeftElement, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiLogIn } from 'react-icons/bi'
import { MdAlternateEmail, MdPassword } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import logo from '../../img/logo.png';
import {loginAdmin} from '../../database';

const Login = ({setAdmin}) => {

    const navigate = useNavigate();
    const toast = useToast();
    const [cargando, setCargando] = useState(false);

    const [formData, setFormData] = useState({
        correo: '',
        contra: ''
      });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    function setCookie(name, value, daysToExpire) {
        const date = new Date();
        date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value}; ${expires}; path=/`;
      }

    const handleClick = async() =>{
        setCargando(true);

        if (!formData.correo || !formData.correo.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            toast({
              title: 'Datos erróneos',
              description: 'Por favor, ingrese un correo electrónico válido.',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
            setCargando(false);
            return;
        }

        if (!(formData.contra.length >= 8 && formData.contra.length <= 20)) {
            toast({
              title: 'Datos erróneos',
              description: 'La contraseña debe tener entre 8 y 20 caracteres.',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
            setCargando(false);
            return;
        }

        const resultadoInicioSesion = await loginAdmin(formData)
        
        if (resultadoInicioSesion.success) {
            setCookie('adminId', resultadoInicioSesion.admin.id, 365);
        
            setCargando(false);
            navigate(`/admin`);
            setAdmin(resultadoInicioSesion.admin);
        } else {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: 'El correo o la contraseña son incorrectos.',
                status: 'error',
                isClosable: true,
            });
        }
    };

  return (
    <Box w="100%" display="flex" justifyContent="center" alignItems="center">
        <Box w="85%" maxW="400px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box>
                <Image src={logo} w="150px"/>
            </Box>
            <Box mb="30px">
                <Text fontSize="20px" fontWeight="bold">Inicio Administrador</Text>
            </Box>
            <FormControl w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
                    <Box w="100%">
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <MdAlternateEmail color='gray.300' />
                            </InputLeftElement>
                            <Input w="100%" h="40px" variant='outline'  placeholder="correo electrónico"  name="correo" onChange={handleChange} />
                        </InputGroup>
                    </Box>
                    <Box w="100%">
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <MdPassword color='gray.300' />
                            </InputLeftElement>
                            <Input type='password' w="100%" h="40px" variant='outline' placeholder="Contraseña" name="contra" onChange={handleChange} />
                        </InputGroup>
                    </Box>
                    <Box w="100%" mb="20px">
                        <Button isLoading={cargando} onClick={handleClick} w="100%" h="40px" rightIcon={<BiLogIn />} bg='primary.100' color="#fff" variant='solid'>Iniciar sesión</Button>
                    </Box>
                </FormControl>
        </Box>
    </Box>
    
  )
}

export default Login