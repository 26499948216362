import { Box, Text, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ViajesDisponibles from '../../components/usuario/ViajesDisponibles';
import Mensaje from '../../components/usuario/Mensaje';
import VolverTitulo from '../../components/usuario/VolverTitulo';
import { obtenerUltimosViajes } from '../../database'; // Ajusta la ruta según tu configuración

const VerMasViajes = () => {
  const [viajes, setViajes] = useState([]);
  const [hayViajes, setHayViajes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numViajes, setNumViajes] = useState(10);

  const obtenerViajesFun = async () => {
    setLoading(true);
    const resultadoViajes = await obtenerUltimosViajes(numViajes);

    if (resultadoViajes.success) {
      setViajes(prevViajes => [...prevViajes, ...resultadoViajes.viajes]); // Agregar nuevos viajes a la lista existente
      setHayViajes(true);
    } else {
      setHayViajes(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    obtenerViajesFun();
  }, [numViajes]);

  const handleObtenerMas = () => {
    setNumViajes(prevNum => prevNum + 10); // Incrementar el número de viajes a obtener en 10
  };

  return (
    <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
      <VolverTitulo texto="Viajes disponibles" />
      <Box mt="60px" w="85%" maxW="400px" display="flex" flexDirection="column" alignItems="center" h="100vh">
        {hayViajes && (
          <>
            <ViajesDisponibles resultado={true} viajes={viajes} />

            {loading ? (
              <Text mt="20px">Cargando...</Text>
            ) : (
              <Button mt="20px" onClick={handleObtenerMas}>
                Cargar más
              </Button>
            )}
          </>
        )}
        {!hayViajes && <Mensaje mt="60px" tipo="sin_viaje" />}
      </Box>
    </Box>
  );
};

export default VerMasViajes;
