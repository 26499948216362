import { Box, Button, Input, InputGroup, InputLeftElement, Select, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiDollar, BiRename } from 'react-icons/bi'
import { BsFillCalendarEventFill } from 'react-icons/bs'
import { IoMdAddCircleOutline, IoMdCalendar } from 'react-icons/io'
import { MdDiscount, MdOutlineCancel, MdOutlinePercent } from 'react-icons/md'
import Calendario from '../Calendario'
import { crearCuponDescuento } from '../../database'

const FormAgregarCupon = ({ setActualizado, onClose }) => {

    const toast = useToast();
    const [isOpen, setIsOpen] = useState(false);
    const [fecha, setFecha] = useState();
    const [cargando, setCargando] = useState(false);
    const [formData, setFormData] = useState({
        codigo: '',
        tipo: '',
        duracion: '',
        fechas: '',
        dias: '',
        monto: '',
        porcentaje: '',
    });

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTipoChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleClick = async () => {
        setCargando(true);

        if (formData.tipo == '') {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "Seleccione el tipo de descuento.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (formData.monto == '' && formData.porcentaje == '') {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "Ingrese el monto o porcentaje de descuento.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (formData.duracion == '') {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "Seleccione el tipo de duaración.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (fecha == '' && formData.dias == '') {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "Ingrese la cantidad de dias o fechas.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        const result = await crearCuponDescuento(formData, fecha);

        if (result.success) {
            setCargando(false);
            setActualizado(true);
            onClose();
            toast({
                title: 'Cupón creado.',
                status: 'success',
                duration: 4000,
                isClosable: true,
            });
        } else {
            setCargando(false);
            toast({
                title: 'Error al crear el cupón.',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    }

    return (
        <Box w="100%" display="flex" justifyContent="center" flexDirection="column" gap="15px">
            <Box w="100%">
                <Select name="tipo" value={formData.tipo} onChange={handleTipoChange} placeholder={formData.tipo ? formData.tipo : 'Tipo de descuento'} w="100%" h="40px" variant='outline'>
                    <option value="monto">Monto fijo</option>
                    <option value="porcentaje">Porcentaje</option>
                </Select>
            </Box>

            {formData.tipo == 'monto' ? (
                <Box w="100%">
                    <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                            <BiDollar color='gray.300' />
                        </InputLeftElement>
                        <Input w="100%" h="40px" variant='outline' placeholder={formData.monto ? formData.monto : "Ingrese el monto fijo"} name="monto" onChange={handleChange} />
                    </InputGroup>
                </Box>
            ) : (<></>)}

            {formData.tipo == 'porcentaje' ? (
                <Box w="100%">
                    <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                            <MdOutlinePercent color='gray.300' />
                        </InputLeftElement>
                        <Input w="100%" h="40px" variant='outline' placeholder={formData.porcentaje ? formData.porcentaje : "Ingrese el porcentaje"} name="porcentaje" onChange={handleChange} />
                    </InputGroup>
                </Box>
            ) : (<></>)}

            <Box w="100%">
                <Select name="duracion" value={formData.duracion} onChange={handleTipoChange} placeholder={formData.duracion ? formData.duracion : 'Tipo de duracion'} w="100%" h="40px" variant='outline'>
                    <option value="cantidad">Cantidad de días</option>
                    <option value="fechas">Entre dos fechas</option>
                </Select>
            </Box>

            {formData.duracion == 'cantidad' ? (
                <Box w="100%">
                    <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                            <IoMdCalendar color='gray.300' />
                        </InputLeftElement>
                        <Input w="100%" h="40px" variant='outline' placeholder={formData.dias ? formData.dias : "Ingrese la cantidad de días"} name="dias" onChange={handleChange} />
                    </InputGroup>
                </Box>
            ) : (<></>)}

            {formData.duracion == 'fechas' ? (
                <Box w="100%">
                    <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                            <BsFillCalendarEventFill color='gray.300' />
                        </InputLeftElement>
                        <Input w="100%" h="40px" variant='outline' name="fechas" value={fecha ? fecha : ''} placeholder={formData.fechas ? formData.fechas : "Seleccione dos fechas"} onChange={handleChange} onClick={openModal} readOnly />
                    </InputGroup>
                    <Calendario isOpen={isOpen} onClose={closeModal} setFecha={setFecha} fecha={fecha} />
                </Box>
            ) : (<></>)}


            <Box w="100%">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <MdDiscount color='gray.300' />
                    </InputLeftElement>
                    <Input w="100%" h="40px" variant='outline' placeholder={formData.empresa ? formData.empresa : "Código"} name="codigo" onChange={handleChange} />
                </InputGroup>
            </Box>

            <Box w="100%" display="flex" flexDirection="row" justifyContent="space-between">
                <Button w="40%" h="40px" leftIcon={<MdOutlineCancel />} onClick={onClose} bg='transparent' border="1px solid #8C3333" color="primary.100">Cancelar</Button>
                <Button w="55%" h="40px" leftIcon={<IoMdAddCircleOutline />} isLoading={cargando} onClick={handleClick} bg='primary.100' color="#fff" variant='solid'>Agregar</Button>
            </Box>
        </Box>
    )
}

export default FormAgregarCupon