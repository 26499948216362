import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBRZmB93o21nQr3Hpy_cAvv2wNKO0iJSAk",
  authDomain: "annytraslados.firebaseapp.com",
  projectId: "annytraslados",
  storageBucket: "annytraslados.appspot.com",
  messagingSenderId: "1058723472279",
  appId: "1:1058723472279:web:be429d6d74a78d3b349ad2"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export {app, storage};
