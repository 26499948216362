import { Box } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import TabMenu from '../../components/admin/TabMenu'
import VolverTitulo from '../../components/usuario/VolverTitulo'
import FormCrearViaje from '../../components/admin/FormCrearViaje'
import { useNavigate } from 'react-router-dom'

const CrearViaje = ({admin}) => {
  const navigate = useNavigate();

  function getCookie(name) {
    const cookieName = `${name}=`;
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  }

  const adminId = getCookie('adminId');

  useEffect(()=>{
    if (adminId == ''){
      navigate('/login');
    }
  }, [adminId])

  return (
    <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
        <VolverTitulo texto="Crear viaje" />
        <Box mt="60px" w="85%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" h="100vh" gap="10px">
            <FormCrearViaje admin={adminId} />
        </Box>
        <TabMenu />
    </Box>
  )
}

export default CrearViaje