import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Checkbox, Divider, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Highlight, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spacer, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure, useMediaQuery, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import VolverTitulo from '../../components/usuario/VolverTitulo';
import CardViajes from '../../components/usuario/CardViajes';
import CardViajesParticulares from '../../components/admin/CardViajesParticulares';
import FormFiltrosMisViajes from '../../components/usuario/FormFiltrosMisViajes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FormLogin from '../../components/usuario/FormLogin';
import { AiFillFilter } from 'react-icons/ai';
import Cookies from 'js-cookie';
import { getViajesParticularesPorUsuario, getViajesPorUsuario } from '../../database';

const MisViajes = ({ user, setUser }) => {
    const [viajesRealizados, setViajesRealizados] = useState([]);
    const [viajesParticularesRealizados, setViajesParticularesRealizados] = useState([]);
    const [viajesNoRealizados, setViajesNoRealizados] = useState([]);
    const [viajesParticularesNoRealizados, setViajesParticularesNoRealizados] = useState([]);
    const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isFiltroOpen, onOpen: onFiltroOpen, onClose: onFiltroClose } = useDisclosure();
    const [dataUser, setDataUser] = useState();


    const obtenerViajes = async (id) => {
        const resultadosViaje = await getViajesPorUsuario(id);

        console.log(resultadosViaje);
        if (resultadosViaje.success) {
            const realizados = resultadosViaje.viajes.filter((viaje) => viaje.realizado === 'true');
            const noRealizados = resultadosViaje.viajes.filter((viaje) => viaje.realizado === 'false');

            setViajesRealizados(realizados);
            setViajesNoRealizados(noRealizados);
        }

        const resultadosViajesParticulares = await getViajesParticularesPorUsuario(id);
        if (resultadosViajesParticulares.success) {
            const realizados = resultadosViajesParticulares.viajesParticulares.filter((viaje) => viaje.realizado === 'true');
            const noRealizados = resultadosViajesParticulares.viajesParticulares.filter((viaje) => viaje.realizado === 'false');

            setViajesParticularesRealizados(realizados);
            setViajesParticularesNoRealizados(noRealizados);
        }
    }

    const getUserIdCookie = () => {
        setDataUser(Cookies.get('user_id'));
    }




    useEffect(() => {
        if (user == '' || user == undefined) {
            onOpen();
        } else {
            onClose();
            obtenerViajes(user.idInicio == null ? user.uid : user.idInicio);
        }
    }, [isOpen, user]);

    return (
        <>
            <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
                <VolverTitulo texto="Mis viajes" />
                <Box mt="60px" w="85%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" h="100vh">
                    <Box w="100%" gap="20px" display="flex" flexDirection={["column", "column", "row"]}>
                        <Button onClick={onFiltroOpen} borderColor="primary.100" variant='outline' color="primary.100" leftIcon={<AiFillFilter />}>Filtrar por</Button>
                        <Tabs variant='soft-rounded' w="100%" colorScheme='primary' mt="20px">
                            <TabList w="100%" h="40px" display="flex" flexDirection="row" color="#fff" justifyContent="space-between">
                                <Tab _selected={{ color: "#fff", backgroundColor: "primary.100" }}>Pendientes</Tab>
                                <Tab _selected={{ color: "#fff", backgroundColor: "primary.100" }}>Realizados</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel p="0">
                                    <Box w="100%" mt="20px" display="flex" flexDirection="column" gap="10px">
                                        <Text fontFamily="18px" fontWeight="bold" color="#fff">
                                            <Highlight query='VIAJES' styles={{ px: '1', py: '1', bg: '#ccc', color: 'primary.100' }}>
                                                VIAJES
                                            </Highlight>
                                        </Text>
                                        <Box display="flex" flexDirection="column" gap="20px">
                                            {viajesNoRealizados.length > 0 ? (
                                                viajesNoRealizados.map((noRealizado) => (
                                                    <CardViajes key={noRealizado.id} viaje={noRealizado} />
                                                ))
                                            ) : (
                                                <Text>No hay viajes pendientes...</Text>
                                            )}
                                        </Box>
                                    </Box>
                                    <Spacer />
                                    <Box w="100%" mt="10px" display="flex" flexDirection="column" gap="10px">
                                        <Text fontFamily="18px" fontWeight="bold" color="#fff">
                                            <Highlight query='VIAJES PARTICULARES' styles={{ px: '1', py: '1', bg: '#ccc', color: 'primary.100' }}>
                                                VIAJES PARTICULARES
                                            </Highlight>
                                        </Text>
                                        <Box display="flex" flexDirection="column" gap="20px">

                                            {viajesParticularesNoRealizados.length > 0 ? (
                                                viajesParticularesNoRealizados.map((noRealizado) => (
                                                    <CardViajesParticulares key={noRealizado.id} viaje={noRealizado} />
                                                ))
                                            ) : (
                                                <Text>No hay viajes particulares pendientes...</Text>
                                            )}
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel p="0">
                                    <Box w="100%" mt="20px" display="flex" flexDirection="column" gap="10px">
                                        <Text fontFamily="18px" fontWeight="bold" color="#fff">
                                            <Highlight query='VIAJES' styles={{ px: '1', py: '1', bg: '#ccc', color: 'primary.100' }}>
                                                VIAJES
                                            </Highlight>
                                        </Text>
                                        <Box display="flex" flexDirection="column" gap="20px">
                                            {viajesRealizados.length > 0 ? (
                                                viajesRealizados.map((realizado) => (
                                                    <CardViajes key={realizado.id} viaje={realizado} />
                                                ))
                                            ) : (
                                                <Text>No hay viajes realizados...</Text>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box w="100%" mt="10px" display="flex" flexDirection="column" gap="10px">
                                        <Text fontFamily="18px" fontWeight="bold" color="#fff">
                                            <Highlight query='VIAJES PARTICULARES' styles={{ px: '1', py: '1', bg: '#ccc', color: 'primary.100' }}>
                                                VIAJES PARTICULARES
                                            </Highlight>
                                        </Text>
                                        <Box display="flex" flexDirection="column" gap="20px">

                                            {viajesParticularesRealizados.length > 0 ? (
                                                viajesParticularesRealizados.map((realizado) => (
                                                    <CardViajesParticulares key={realizado.id} viaje={realizado} />
                                                ))
                                            ) : (
                                                <Text>No hay viajes particulares realizados...</Text>
                                            )}
                                        </Box>
                                    </Box>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Box>
            </Box>
            {!isLargerThan800 &&
                <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} autoFocus={false}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerHeader mt="20px" mb="10px" p="0" w="100%" h="150px" display="flex" justifyContent="center">
                            <Text>Iniciar Sesión</Text>
                        </DrawerHeader>
                        <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
                            <Box w="100%">
                                <FormLogin id="misViajes" setUser={setUser} onClose={onClose} />
                            </Box>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>}
            {isLargerThan800 &&
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Iniciar Sesión</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
                            <Box w="100%">
                                <FormLogin id="misViajes" setUser={setUser} onClose={onClose} />
                            </Box>
                        </ModalBody>
                    </ModalContent>
                </Modal>}
            <Modal isOpen={isFiltroOpen} onClose={onFiltroClose}>
                <ModalOverlay />
                <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
                    <ModalHeader>Filtrar por...</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormFiltrosMisViajes pasajero={true} onClose={onClose} setViajesNoRealizados={setViajesNoRealizados} setViajesRealizados={setViajesRealizados} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default MisViajes