import { Box, Button, Input, InputGroup, InputLeftElement, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiRename } from 'react-icons/bi'
import { FaLocationDot } from 'react-icons/fa6'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { MdAttachMoney, MdOutlineCancel } from 'react-icons/md'
import { agregarPublicidad } from '../../database'

const FormAgregarPublicidad = ({ onClose, setActualizado }) => {

    const toast = useToast();
    const [cargando, setCargando] = useState(false);
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState({
        empresa: '',
        url: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const url = URL.createObjectURL(file);
            setFormData((prevData) => ({
                ...prevData,
                url: url,
            }));
            setFile(file);
        }
    };

    const handleClick = async () => {
        setCargando(true);
        if (file && formData.empresa) {
            const result = await agregarPublicidad(file, formData.empresa);
            if (result.success) {
                setCargando(false);
                setActualizado(true);
                onClose();
                toast({
                    title: 'Publicidad agregada.',
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                });
            } else {
                setCargando(false);
                toast({
                    title: 'Error al agregar la publicidad.',
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
            }
        }
    }
    return (
        <Box w="100%" display="flex" justifyContent="center" flexDirection="column" gap="15px">
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <BiRename color='gray.300' />
                </InputLeftElement>
                <Input w="100%" h="40px" variant='outline' placeholder={formData.empresa ? formData.empresa : "Nombre empresa"} name="empresa" onChange={handleChange} />
            </InputGroup>
            <Box>
                <Text>Seleccione una imagen</Text>
                <Input type="file" w="100%" border="none" ml="-15px" name="url" onChange={handleImageChange} />
            </Box>

            <Box w="100%" display="flex" flexDirection="row" justifyContent="space-between">
                <Button w="40%" h="40px" leftIcon={<MdOutlineCancel />} onClick={onClose} bg='transparent' border="1px solid #8C3333" color="primary.100">Cancelar</Button>
                <Button w="55%" h="40px" leftIcon={<IoMdAddCircleOutline />} isLoading={cargando} onClick={handleClick} bg='primary.100' color="#fff" variant='solid'>Agregar</Button>
            </Box>
        </Box>
    )
}

export default FormAgregarPublicidad