import { Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiEdit } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import { obtenerCuponesDescuento } from '../../database';
import EditarCupon from './EditarCupon';

const TablaCupones = ({ setActualizado, actualizado }) => {

    const [cupones, setCupones] = useState();
    const [idCupon, setIdCupon] = useState();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

    const obtenerCupones = async () => {
        const cupon = await obtenerCuponesDescuento();

        if (cupon.success) {
            setCupones(cupon.cupones);
        }
    }

    const obtenerCuponEditar = (id) => {
        setIdCupon(id);
        onOpen();
    }

    useEffect(() => {
        obtenerCupones();
    }, [actualizado]);

    return (
        <>
            <TableContainer w="100%">
                <Table size='sm' colorScheme='primary'>
                    <Thead>
                        <Tr h="40px">
                            <Th w="20%" p="0">Cupón</Th>
                            <Th w="30%" textAlign="left" p="0">Duración</Th>
                            <Th w="10%" textAlign="left" p="0">Monto</Th>
                            <Th w="10%" textAlign="right" p="0"></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {cupones && cupones.map((data, index) => (
                            <Tr key={index} h="40px">
                                <Td p="0" maxW="40px" h="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                    {data.codigo}
                                </Td>
                                <Td p="0" maxW="40px" h="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                    {data.dias != '' ? `${data.dias} dias` : `${data.fecha_inicio} - ${data.fecha_fin}`}
                                </Td>
                                <Td p="0" maxW="40px" h="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                                    {data.monto != '' ? `$${data.monto}` : `%${data.porcentaje}`}
                                </Td>
                                <Td p="0" display="flex" flexDirection="row" h="40px" justifyContent="flex-end" alignItems="center" gap="5px">
                                    <Box display="flex" justifyContent="center" alignItems="center" borderRadius="10px" w="25px" h="25px">
                                        <BiEdit cursor="pointer" fontSize="23px" color='gray.300' onClick={() => { obtenerCuponEditar(data.id) }} />
                                    </Box>
                                </Td>
                            </Tr>
                        ))}

                    </Tbody>
                </Table>
            </TableContainer>
            {!isLargerThan800 &&
                <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} autoFocus={false}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerHeader mt="20px" mb="10px" p="0" w="100%" h="150px" display="flex" justifyContent="center">
                            <Text>Datos del cupón</Text>
                        </DrawerHeader>
                        <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
                            <EditarCupon idCupon={idCupon} onClose={onClose} setActualizado={setActualizado} />
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>}
            {isLargerThan800 &&
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent w={["380px", "100%", "100%"]}>
                        <ModalHeader>Datos del cupón</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <EditarCupon idCupon={idCupon} onClose={onClose} setActualizado={setActualizado} />
                        </ModalBody>
                    </ModalContent>
                </Modal>}
        </>
    )
}

export default TablaCupones