import { Box, Flex, Spacer, Text, Link } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import CardViajes from './CardViajes'
import { useNavigate } from 'react-router-dom';

const ViajesDisponibles = ({ resultado, viajes }) => {

  const navigate = useNavigate();

  return (
    <Box w="100%" mt={[resultado ? '0px' : '0px', resultado ? '10px' : '25px', '0px']}>
      {!resultado &&
        <Flex>
          <Spacer />
          <Text onClick={() => { navigate('/vermasviajes') }} textDecorationLine="underline">Ver más</Text>
        </Flex>}
      {viajes &&
        <Box mt="10px" mb="20px" display="flex" flexDirection="column" gap="20px">
          {viajes && viajes.map((viaje) => (
            <CardViajes key={viaje.id} viaje={viaje} />
          ))}
        </Box>
      }
    </Box>
  );
}

export default ViajesDisponibles;