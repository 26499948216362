import { Box, Button, Input, InputGroup, InputLeftElement, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { FaLocationDot } from 'react-icons/fa6'
import { getParadaPorId, getParadaPorViajeYCiudad, updatePrecioParada } from '../../database'
import { MdAttachMoney } from 'react-icons/md'




const EditarParada = ({ parada, onClose, setActualizado }) => {
    const toast = useToast();
    const [precio, setPrecio] = useState(0);

    const handleClick = async () => {
        try {
            if (precio != 0) {
                const resultadoEditar = await updatePrecioParada(parada.viaje_id, parada.ciudad, precio);

                if (resultadoEditar.success) {
                    setActualizado(true);
                    onClose();
                    toast({
                        title: 'Parada editada.',
                        status: 'success',
                        duration: 4000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: 'Error al editar la parada.',
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                    });
                }
            } else {
                toast({
                    title: 'El precio debe ser mayor a 0.',
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
            }
        } catch (e) {

        }
    }

    return (
        <Box w="100%" display="flex" justifyContent="center" flexDirection="column" gap="15px">
            <Box>
                <Text>{parada.ciudad}</Text>
            </Box>
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    <MdAttachMoney />
                </InputLeftElement>
                <Input borderColor="1px solid #1e1e1e" w="100%" h="40px" variant='outline' placeholder={parada.precio ? parada.precio : "Precio"} name="precio" onChange={(e) => { setPrecio(e.target.value); }} />
            </InputGroup>

            <Box w="100%" display="flex" flexDirection="row" justifyContent="space-between">
                <Button w="100%" h="40px" leftIcon={<FaRegEdit />} onClick={handleClick} bg='primary.100' color="#fff" variant='solid'>Editar parada</Button>
            </Box>
        </Box>
    )
}

export default EditarParada