import { Box, Button, Divider, Flex, FormControl, Input, InputGroup, InputLeftElement, List, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { BiTimeFive } from 'react-icons/bi'
import { BsFillCalendarEventFill, BsPeopleFill, BsPersonFill } from 'react-icons/bs'
import { GiSteeringWheel } from 'react-icons/gi'
import { IoLogoUsd } from 'react-icons/io'
import { MdLocationPin, MdOutlineLocationOn } from 'react-icons/md'
import { TbLocationFilled } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import Calendario from '../Calendario';
import { crearViaje, getChoferes } from '../../database';
import { CiCirclePlus } from "react-icons/ci";
import { FaTrash } from 'react-icons/fa'

const FormCrearViaje = ({ admin }) => {
  const [fecha, setFecha] = useState();
  const handleProvinciaDestino = (event) => {
    setProvinciaDestino(event.target.value);
  };

  const handleProvinciaParada = (event) => {
    setProvinciaParada(event.target.value);
  };

  const handleProvinciaOrigen = (event) => {
    setProvinciaOrigen(event.target.value);
  };
  const { isOpen: isOriginModalOpen, onOpen: onOriginModalOpen, onClose: onOriginModalClose } = useDisclosure();
  const { isOpen: isParadaModalOpen, onOpen: onParadaModalOpen, onClose: onParadaModalClose } = useDisclosure();
  const { isOpen: isDestinationModalOpen, onOpen: onDestinationModalOpen, onClose: onDestinationModalClose } = useDisclosure();

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();
  const [cargando, setCargando] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [seleccionadoCompleto, setSeleccionCompleto] = useState('');
  const [seleccionadoCompletoParada, setSeleccionCompletoParada] = useState('');
  const [seleccionadoCompletoParadaPrecio, setSeleccionCompletoParadaPrecio] = useState('');

  const paradaSeleccionado = (cuidad) => {
    showListParada(false);
    setInputValueParada(capitalizeWords(cuidad.nombre));

    setSeleccionCompletoParada((prevSeleccion) => ({
      ...prevSeleccion,
      parada: capitalizeWords(provinciaParada) + ', ' + capitalizeWords(cuidad.nombre)
    }));
  }

  const origenSeleccionado = (cuidad) => {
    showListOrigen(false);
    setInputValueOrigen(capitalizeWords(cuidad.nombre));

    setSeleccionCompleto((prevSeleccion) => ({
      ...prevSeleccion,
      origen: capitalizeWords(provinciaOrigen) + ', ' + capitalizeWords(cuidad.nombre)
    }));
  }

  const destinoSeleccionado = (cuidad) => {
    showListDestino(false);
    setInputValueDestino(capitalizeWords(cuidad.nombre));

    setSeleccionCompleto((prevSeleccion) => ({
      ...prevSeleccion,
      destino: capitalizeWords(provinciaDestino) + ', ' + capitalizeWords(cuidad.nombre),
    }));
  }

  const [choferes, setChoferes] = useState();

  const [formData, setFormData] = useState({
    origen: '',
    destino: '',
    horaIda: '',
    horaVuelta: '',
    pasajeros_ida: 0,
    pasajeros_vuelta: 0,
    fecSalida: '',
    precio: '',
    chofer: '',
    realizado: 'false'
  });

  const [formDataParada, setFormDataParada] = useState({
    paradas: []
  });

  const handleChoferChange = (event) => {
    const choferSeleccionado = event.target.value;
    setFormData({
      ...formData,
      chofer: choferSeleccionado
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };



  const agregarParada = () => {
    const parada = seleccionadoCompletoParada.parada;

    if (parada == 'Nueva parada') {

    } else {
      setFormDataParada((prevData) => ({
        ...prevData,
        paradas: [...prevData.paradas, {
          ciudad: parada,
          precio: String(seleccionadoCompletoParadaPrecio)
        }],
      }));
      seleccionadoCompletoParada.parada = 'Nueva parada';
      setSeleccionCompletoParadaPrecio('');
    }
    onCloseParada();
  }

  const handleChangeParada = (e) => {
    const { name, value } = e.target;
    setFormDataParada((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [countIda, setCountIda] = useState(0);

  const handleIncrementIda = () => {
    setCountIda((prevCount) => prevCount + 1);
  };

  const handleDecrementIda = () => {
    if (countIda > 0) {
      setCountIda((prevCount) => prevCount - 1);
    }
  };

  const [countVuelta, setCountVuelta] = useState(0);

  const handleIncrementVuelta = () => {
    setCountVuelta((prevCount) => prevCount + 1);
  };

  const handleDecrementVuelta = () => {
    if (countVuelta > 0) {
      setCountVuelta((prevCount) => prevCount - 1);
    }
  };

  const obtenerChoferes = async () => {
    const resultadoAgregarChofer = await getChoferes('0', '0');

    if (resultadoAgregarChofer.length > 0) {
      setChoferes(resultadoAgregarChofer);
    }
  }

  useEffect(() => {
    if (admin == '') {
      navigate('/login');
    }
    const nuevaFecha = new Date();
    setFecha(nuevaFecha);
    obtenerChoferes();
  }, []);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      origen: seleccionadoCompleto.origen
    }));
  }, [seleccionadoCompleto.origen]);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      destino: seleccionadoCompleto.destino
    }));
  }, [seleccionadoCompleto.destino]);

  useEffect(() => {
    setFormData((prevSeleccion) => ({
      ...prevSeleccion,
      fecSalida: fecha
    }));
  }, [fecha]);

  useEffect(() => {
    setFormData((prevSeleccion) => ({
      ...prevSeleccion,
      pasajeros_ida: countIda,
      pasajeros_vuelta: countVuelta,
    }));
  }, [countIda, countVuelta]);


  const handleClick = async () => {
    setCargando(true);

    setFormData(prevFormData => ({
      ...prevFormData,
      fecSalida: fecha,
      pasajeros_ida: countIda,
      pasajeros_vuelta: countVuelta,
      origen: seleccionadoCompleto.origen,
      destino: seleccionadoCompleto.destino
    }));


    if (seleccionadoCompleto.origen == '') {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: "El origen es requerido.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return;
    }

    if (seleccionadoCompleto.destino == '') {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: 'El destino es requerido.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if ((fecha == "")) {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: 'La fecha es requerida.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (!formData.precio > 0) {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: "El precio es requerido.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return;
    }

    setFormData(prevFormData => ({
      ...prevFormData,
      pasajeros_ida: countIda,
      pasajeros_vuelta: countVuelta,
      origen: seleccionadoCompleto.origen,
      destino: seleccionadoCompleto.destino
    }));

    if (!(formData.pasajeros_ida > 0) && !(countIda > 0)) {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: 'La cantidad de pasajeros de ida debe ser mayor a 0.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (!(formData.pasajeros_vuelta > 0) && !(countVuelta > 0)) {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: 'La cantidad de pasajeros de vuelta debe ser mayor a 0.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (formData.horaIda == "") {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: "La hora es requerida.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return;
    }

    if (formData.horaVuelta == "") {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: "La hora es requerida.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return;
    }

    if (formData.chofer == '') {
      setCargando(false);
      toast({
        title: 'Datos erróneos',
        description: "El chofer es requerido.",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return;
    }

    const resultadoCrearViaje = await crearViaje(formData, formDataParada.paradas);
    setCargando(false);


    if (resultadoCrearViaje.success) {
      setCargando(false);
      toast({
        title: 'Viaje creado correctamente.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })

      setTimeout(() => {
        navigate(`/admin`)
      }, 2000);
    } else {
      toast({
        title: 'Error al crear el viaje.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const [inputValueOrigen, setInputValueOrigen] = useState('');
  const [inputValueParada, setInputValueParada] = useState('');
  const [inputValueDestino, setInputValueDestino] = useState('');
  const [ciudadesOrigen, setCiudadesOrigen] = useState([]);
  const [ciudadesParada, setCiudadesParada] = useState([]);
  const [ciudadesDestino, setCiudadesDestino] = useState([]);
  const containerRef = useRef(null);
  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([]);
  const [ciudadesFiltradasDestino, setCiudadesFiltradasDestino] = useState([]);
  const [listOrigen, showListOrigen] = useState(false);
  const [listParada, showListParada] = useState(false);
  const [listDestino, showListDestino] = useState(false);
  const [provinciaOrigen, setProvinciaOrigen] = useState('');
  const [provinciaDestino, setProvinciaDestino] = useState('');
  const [provinciaParada, setProvinciaParada] = useState('');

  const provinciasArgentina = [
    'Buenos Aires',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Córdoba',
    'Corrientes',
    'Entre Ríos',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquén',
    'Río Negro',
    'Salta',
    'San Juan',
    'San Luis',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
    'Tucumán',
  ];

  useEffect(() => {
    const fetchDataOrigen = async () => {
      try {
        const response = await fetch(`https://apis.datos.gob.ar/georef/api/localidades?provincia=${provinciaOrigen}&campos=nombre&max=999`);

        if (response.ok) {
          const data = await response.json();
          const localidadesModificadas = data.localidades.map(localidad => {
            if (localidad.nombre === "Ciudad de Buenos Aires") {
              return { ...localidad, nombre: "Ciudad Autónoma de Buenos Aires" };
            }
            return localidad;
          });
          setCiudadesOrigen(localidadesModificadas);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (provinciaOrigen !== '') {
      fetchDataOrigen();
      showListOrigen(false);
      setInputValueOrigen('');
    }
  }, [provinciaOrigen]);

  useEffect(() => {
    const fetchDataOrigen = async () => {
      try {
        const response = await fetch(`https://apis.datos.gob.ar/georef/api/localidades?provincia=${provinciaDestino}&campos=nombre&max=999`);

        if (response.ok) {
          const data = await response.json();
          const localidadesModificadas = data.localidades.map(localidad => {
            if (localidad.nombre === "Ciudad de Buenos Aires") {
              return { ...localidad, nombre: "Ciudad Autónoma de Buenos Aires" };
            }
            return localidad;
          });
          setCiudadesDestino(localidadesModificadas);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (provinciaDestino !== '') {
      fetchDataOrigen();
      showListDestino(false);
      setInputValueDestino('');
    }
  }, [provinciaDestino]);

  useEffect(() => {
    const fetchDataParada = async () => {
      try {
        const response = await fetch(`https://apis.datos.gob.ar/georef/api/localidades?provincia=${provinciaParada}&campos=nombre&max=999`);

        if (response.ok) {
          const data = await response.json();
          const localidadesModificadas = data.localidades.map(localidad => {
            if (localidad.nombre === "Ciudad de Buenos Aires") {
              return { ...localidad, nombre: "Ciudad Autónoma de Buenos Aires" };
            }
            return localidad;
          });
          setCiudadesParada(localidadesModificadas);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (provinciaParada !== '') {
      fetchDataParada();
      showListParada(false);
      setInputValueParada('');
    }
  }, [provinciaParada]);


  /* parada */
  useEffect(() => {
    const ciudadesFiltradas = ciudadesParada.filter((ciudad) =>
      typeof ciudad === 'object' && ciudad.nombre.toLowerCase().includes(inputValueParada.toLowerCase())
    );
    setCiudadesFiltradas(ciudadesFiltradas);
  }, [inputValueParada]);


  /* origen */
  useEffect(() => {
    const ciudadesFiltradas = ciudadesOrigen.filter((ciudad) =>
      typeof ciudad === 'object' && ciudad.nombre.toLowerCase().includes(inputValueOrigen.toLowerCase())
    );
    setCiudadesFiltradas(ciudadesFiltradas);
  }, [inputValueOrigen]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValueOrigen(value);
  };

  /* destino */
  useEffect(() => {
    const ciudadesFiltradas = ciudadesDestino.filter((ciudad) =>
      typeof ciudad === 'object' && ciudad.nombre.toLowerCase().includes(inputValueDestino.toLowerCase())
    );
    setCiudadesFiltradasDestino(ciudadesFiltradas);
  }, [inputValueDestino]);

  const handleInputChangeDestino = (event) => {
    const value = event.target.value;
    setInputValueDestino(value);
  };

  function capitalizeWords(str) {
    const accentMap = {
      'á': 'a',
      'é': 'e',
      'í': 'i',
      'ó': 'o',
      'ú': 'u',
      // Agrega más caracteres acentuados y sus equivalentes sin acentos según sea necesario
    };

    // Reemplaza los caracteres acentuados por sus equivalentes sin acentos
    const stringWithoutAccents = str.replace(/[áéíóú]/g, match => accentMap[match] || match);

    // Capitaliza las palabras
    return stringWithoutAccents
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const handleInputChangeParada = (event) => {
    const value = event.target.value;
    setInputValueParada(value);
  };

  const { isOpen: isOpenParada, onOpen: onOpenParada, onClose: onCloseParada } = useDisclosure();

  const eliminarParada = (index) => {
    setFormDataParada((prevData) => ({
      ...prevData,
      paradas: prevData.paradas.filter((parada, i) => i !== index)
    }));
  };

  const handleChangePrecio = (e) => {
    const precio = e.target.value;
    setSeleccionCompletoParadaPrecio(precio);
  };


  return (
    <>
      <FormControl w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
        <Box position="relative" zIndex="1" ref={containerRef} w="100%">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<TbLocationFilled color='gray.300' />}
            />
            <Input onClick={onOriginModalOpen} readOnly value={seleccionadoCompleto.origen != '' ? seleccionadoCompleto.origen : inputValueOrigen} placeholder="Origen" onChange={handleInputChange} />
          </InputGroup>
        </Box>
        <Box w="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap="10px">
          <Text cursor="pointer" onClick={onOpenParada} textDecoration="underline" color="primary.100">Agregar parada</Text>
        </Box>
        {formDataParada.paradas && formDataParada.paradas.map((parada, index) => {
          return (
            <>
              <Box w="100%" display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
                <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
                  <Text color="#adacac" fontSize="13px">PARADA {index + 1}</Text>
                  <Text>{parada.ciudad}</Text>
                  <Text>${parada.precio}</Text>
                </Box>
                <Box h="100%" display="flex" alignItems="center" justifyContent="center">
                  <FaTrash cursor="pointer" onClick={() => eliminarParada(index)} />
                </Box>
              </Box>
              <Divider mb="0px" mt="-3px" />
            </>

          );
        })}
        <Modal isOpen={isOpenParada} onClose={onCloseParada}>
          <ModalOverlay />
          <ModalContent w="95%" mt={["40%", "20%", "10%"]}>
            <ModalHeader>Agregar parada</ModalHeader>
            <ModalCloseButton />
            <ModalBody w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
              <Box w="100%">
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<TbLocationFilled color='gray.300' />}
                  />
                  <Input onClick={onParadaModalOpen} readOnly value={seleccionadoCompletoParada.parada} placeholder="Destino" onChange={handleInputChangeParada} />
                </InputGroup>
              </Box>
              <Box w="100%">
                <InputGroup>
                  <InputLeftElement pointerEvents='none'>
                    <IoLogoUsd color='gray.300' />
                  </InputLeftElement>
                  <Input w="100%" h="40px" variant='outline' type='number' placeholder={seleccionadoCompletoParadaPrecio != '' ? seleccionadoCompletoParadaPrecio : 'Precio'} name="precio" onChange={handleChangePrecio} />
                </InputGroup>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button variant="outline" color="#1e1e1e" mr={3} onClick={onCloseParada}>
                Cancelar
              </Button>
              <Button bg='primary.100' color="#fff" onClick={agregarParada} variant='solid'>Crear parada</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Box w="100%">
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <MdOutlineLocationOn color='gray.300' />
            </InputLeftElement>
            <Input onClick={onDestinationModalOpen} value={seleccionadoCompleto.destino != '' ? seleccionadoCompleto.destino : inputValueDestino} readOnly w="100%" h="40px" variant='outline' placeholder='Destino' />
          </InputGroup>
        </Box>
        <Flex w="100%">
          <Box w="48.5%">
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <BsFillCalendarEventFill color='gray.300' />
              </InputLeftElement>
              <Input w="100%" h="40px" variant='outline' name="fecSalida" placeholder={fecha && fecha} onChange={handleChange} value={fecha instanceof Date ? fecha.toISOString().slice(0, 10) : ''} onClick={openModal} readOnly />
            </InputGroup>
            <Calendario isOpen={isOpen} onClose={closeModal} setFecha={setFecha} fecha={fecha} />
          </Box>
          <Spacer />
          <Box w="48.5%">
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <IoLogoUsd color='gray.300' />
              </InputLeftElement>
              <Input w="100%" h="40px" variant='outline' type='number' placeholder='Precio' name="precio" onChange={handleChange} />
            </InputGroup>
          </Box>
        </Flex>
        <Box w="100%" display="flex" flexDirection="row" gap="5px">
          <InputGroup w="80%">
            <InputLeftElement pointerEvents='none'>
              <BsPeopleFill color='gray.300' />
            </InputLeftElement>
            <Input w="100%" h="40px" variant='outline' value={`Pasajeros ida: ${countIda}`} readOnly name="pasajeros_ida" />
          </InputGroup>
          <Button onClick={handleDecrementIda} color={countIda == 0 ? '#fff' : 'primary.100'} disabled={countVuelta <= 0}> - </Button>
          <Button onClick={handleIncrementIda} color='primary.100'> + </Button>
        </Box>
        <Box w="100%" display="flex" flexDirection="row" gap="5px">
          <InputGroup w="80%">
            <InputLeftElement pointerEvents='none'>
              <BsPeopleFill color='gray.300' />
            </InputLeftElement>
            <Input w="100%" h="40px" variant='outline' value={`Pasajeros vuelta: ${countVuelta}`} readOnly name="pasajeros_vuelta" />
          </InputGroup>
          <Button onClick={handleDecrementVuelta} color={countVuelta == 0 ? '#fff' : 'primary.100'} disabled={countVuelta <= 0}> - </Button>
          <Button onClick={handleIncrementVuelta} color='primary.100'> + </Button>
        </Box>
        <Flex w="100%">
          <Box w="48.5%">
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <BiTimeFive color='gray.300' />
              </InputLeftElement>
              <Input w="100%" h="40px" variant='outline' type='text' placeholder='Hora de Salida' name="horaIda" onChange={handleChange} />
            </InputGroup>
          </Box>
          <Spacer />
          <Box w="48.5%">
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <BiTimeFive color='gray.300' />
              </InputLeftElement>
              <Input w="100%" h="40px" variant='outline' type='text' placeholder='Hora de Vuelta' name="horaVuelta" onChange={handleChange} />
            </InputGroup>
          </Box>
        </Flex>
        <Box w="100%">
          <Select value={formData.chofer} onChange={handleChoferChange} placeholder='Seleccione el chofer' w="100%" h="40px" variant='outline'>
            {choferes && choferes.map(chofer => (
              <option key={chofer.id} value={chofer.id}>{chofer.nombre}</option>
            ))}
          </Select>
        </Box>

        <Box w="100%" mb="60px">
          <Button w="100%" h="40px" isLoading={cargando} onClick={handleClick} leftIcon={<GiSteeringWheel />} bg='primary.100' color="#fff" variant='solid'>Crear viaje</Button>
        </Box>
      </FormControl>
      <Box>
        <Modal isOpen={isOriginModalOpen} onClose={onOriginModalClose}>
          <ModalOverlay />
          <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
            <ModalHeader>Complete el origen</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection="column" gap="10px">
              <Select placeholder="Provincia" value={provinciaOrigen} onChange={handleProvinciaOrigen}>
                {provinciasArgentina.map((provincia, index) => (
                  <option key={index} value={provincia}>
                    {provincia}
                  </option>
                ))}
              </Select>
              <Input onClick={() => showListOrigen(true)} value={inputValueOrigen} w="100%" h="40px" variant='outline' placeholder='Ciudad' name="origen" onChange={handleInputChange} />
              {listOrigen &&
                <Box >
                  <List mt="-10px" maxH="200px" overflowY="auto" border="1px solid #e2e8f0" borderRadius="5px">
                    {ciudadesFiltradas.map((ciudad, index) => (
                      <Box onClick={() => { origenSeleccionado(ciudad) }} key={index} display="flex" gap="10px" fontSize="18px" flexDirection="row" justifyContent="flex-start" alignItems="center" h="35px">
                        <MdLocationPin />
                        <Text>{capitalizeWords(ciudad.nombre)}</Text>
                      </Box>
                    ))}
                  </List>
                </Box>}
            </ModalBody>
            <ModalFooter>
              <Button color="#fff" bg="primary.100" w="150px" onClick={onOriginModalClose}> Seleccionar </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isParadaModalOpen} onClose={onParadaModalClose}>
          <ModalOverlay />
          <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
            <ModalHeader>Complete el origen</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection="column" gap="10px">
              <Select placeholder="Provincia" value={provinciaParada} onChange={handleProvinciaParada}>
                {provinciasArgentina.map((provincia, index) => (
                  <option key={index} value={provincia}>
                    {provincia}
                  </option>
                ))}
              </Select>
              <Input onClick={() => showListParada(true)} value={inputValueParada} w="100%" h="40px" variant='outline' placeholder='Ciudad' name="parada" onChange={handleInputChangeParada} />
              {listParada &&
                <Box >
                  <List mt="-10px" maxH="200px" overflowY="auto" border="1px solid #e2e8f0" borderRadius="5px">
                    {ciudadesFiltradas.map((ciudad, index) => (
                      <Box onClick={() => { paradaSeleccionado(ciudad) }} key={index} display="flex" gap="10px" fontSize="18px" flexDirection="row" justifyContent="flex-start" alignItems="center" h="35px">
                        <MdLocationPin />
                        <Text>{capitalizeWords(ciudad.nombre)}</Text>
                      </Box>
                    ))}
                  </List>
                </Box>}
            </ModalBody>
            <ModalFooter>
              <Button color="#fff" bg="primary.100" w="150px" onClick={onParadaModalClose}> Seleccionar </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isDestinationModalOpen} onClose={onDestinationModalClose}>
          <ModalOverlay />
          <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
            <ModalHeader>Modal de Destino</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection="column" gap="10px">
              <Select placeholder="Provincia" value={provinciaDestino} onChange={handleProvinciaDestino}>
                {provinciasArgentina.map((provincia, index) => (
                  <option key={index} value={provincia}>
                    {provincia}
                  </option>
                ))}
              </Select>
              <Input onClick={() => showListDestino(true)} value={inputValueDestino} w="100%" h="40px" variant='outline' placeholder='Ciudad' name="destino" onChange={handleInputChangeDestino} />
              {listDestino &&
                <Box >
                  <List mt="-10px" maxH="200px" overflowY="auto" border="1px solid #e2e8f0" borderRadius="5px">
                    {ciudadesFiltradasDestino.map((ciudad, index) => (
                      <Box onClick={() => { destinoSeleccionado(ciudad) }} key={index} display="flex" gap="10px" fontSize="18px" flexDirection="row" justifyContent="flex-start" alignItems="center" h="35px">
                        <MdLocationPin />
                        <Text>{capitalizeWords(ciudad.nombre)}</Text>
                      </Box>
                    ))}
                  </List>
                </Box>}
            </ModalBody>
            <ModalFooter>
              <Button color="#fff" bg="primary.100" w="150px" colorScheme="blue" onClick={onDestinationModalClose}> Seleccionar </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  )
}

export default FormCrearViaje