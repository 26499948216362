import { Box, Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useDisclosure, useMediaQuery, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { getTarifas } from '../../database';
import EditarTarifa from './EditarTarifa';

const TablaTarifas = ({ sinLimite, setActualizado, actualizado }) => {
    const [tarifas, setTarifas] = useState();
    const [idTarifas, setIdTarifas] = useState();

    const navigate = useNavigate();

    const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
    const { isOpen, onOpen, onClose } = useDisclosure();

    const obtenerTarifas = async () => {
        const resultadoTarifas = await getTarifas(sinLimite == false ? 7 : 100);

        if (resultadoTarifas.tarifas.length > 0) {
            setTarifas(resultadoTarifas.tarifas);
        }
    }

    const obtenerTarifaEditar = (id) => {
        setActualizado(false);
        setIdTarifas(id);
        onOpen();
    }

    useEffect(() => {
        obtenerTarifas();
    }, [actualizado]);


    return (
        <>
            <TableContainer w="100%" h="100%" mb="70px">
                <Table size='sm' colorScheme='primary'>
                    {sinLimite == false &&
                        <TableCaption bg="red.100" w="100%" h="30px" p="0" mt="0px">
                            <Text cursor="pointer" mt="4px" textDecoration="underline" onClick={() => { navigate('/tarifas') }}>Ver todas</Text>
                        </TableCaption>}
                    <Thead>
                        <Tr h="40px">
                            <Th w="50%" p="0">Localidad</Th>
                            <Th w="50%" p="0">Precio</Th>
                            {sinLimite != false &&
                                <Th w="50%" p="0"></Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {tarifas && tarifas.map((data, index) => (


                            <Tr key={index} h="40px" cursor="pointer">
                                <Td textAlign="start" p="0" maxW="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{data.localidad}</Td>
                                <Td textAlign="start" p="0" >${data.precio}</Td>
                                {sinLimite != false &&
                                    <Td p="0" display="flex" flexDirection="row" h="40px" justifyContent="flex-end" alignItems="center" gap="5px">
                                        <Box display="flex" justifyContent="center" alignItems="center" borderRadius="10px" w="25px" h="25px">
                                            <BiEdit cursor="pointer" fontSize="23px" color='gray.300' onClick={() => { obtenerTarifaEditar(data.id) }} />
                                        </Box>
                                    </Td>}
                            </Tr>
                        ))}

                    </Tbody>
                </Table>
            </TableContainer>
            {!isLargerThan800 &&
                <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} autoFocus={false}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerHeader mt="20px" mb="10px" p="0" w="100%" h="150px" display="flex" justifyContent="center">
                            <Text>Datos de la tarifa</Text>
                        </DrawerHeader>
                        <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
                            <EditarTarifa idTarifa={idTarifas} onClose={onClose} setActualizado={setActualizado} />
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>}
            {isLargerThan800 &&
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent w={["380px", "100%", "100%"]}>
                        <ModalHeader>Datos de la tarifa</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <EditarTarifa idTarifa={idTarifas} onClose={onClose} setActualizado={setActualizado} />
                        </ModalBody>
                    </ModalContent>
                </Modal>}
        </>
    )
}

export default TablaTarifas