import { Box, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// import { obtenerViajes } from '../../database';

const TablaViajesHist = () => {
  const [viajes, setViajes] = useState();
  const navigate = useNavigate();

  const obtenerViajesFunc = async () => {

    // const resultadoViajes = await obtenerViajes('0', '0', '100', '1');

    // if (resultadoViajes.success) {
    //   setViajes(resultadoViajes.viajes)
    // }
  }

  const formatearFecha = (fechaISO) => {
    const fecha = new Date(fechaISO);
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear().toString().substring(2);
    return `${dia}/${mes}/${anio}`;
  }
  const obtenerElementoEnPosicion1 = (texto) => {
    const arreglo = texto.split(',');
    if (arreglo.length >= 2) {
      return arreglo[1].trim();
    } else {
      return null;
    }
  };

  useEffect(() => {
    obtenerViajesFunc();
  }, [])

  return (
    <TableContainer w="100%">
      <Table size='sm' colorScheme='primary'>
        <TableCaption bg="red.100" w="100%" h="20px" p="0" mt="0px">
          <Text onClick={() => { navigate('/viajes-creados') }}>Ver todos</Text>
        </TableCaption>
        <Thead>
          <Tr h="40px">
            <Th w="40%" p="0">Salida</Th>
            <Th w="40%" p="0">Destino</Th>
            <Th w="20%" textAlign="center" p="0">Fecha</Th>
            <Th w="20%" textAlign="center" p="0">Hora</Th>
          </Tr>
        </Thead>
        <Tbody>
          {viajes && viajes.map((data, index) => (
            <Tr key={index} h="40px" onClick={() => { navigate(`/viaje/${data.id}`) }} cursor="pointer">
              <Td p="0" maxW="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {obtenerElementoEnPosicion1(data.origen)}
              </Td>
              <Td p="0" maxW="40px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {obtenerElementoEnPosicion1(data.destino)}
              </Td>
              <Td textAlign="center" p="0">{formatearFecha(data.fecha)}</Td>
              <Td textAlign="center" p="0">{data.hora}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default TablaViajesHist