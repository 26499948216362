import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Image, Input, Link, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { HiMenuAlt3 } from 'react-icons/hi';
import { AiFillCar, AiFillHome, AiFillMessage, AiFillWarning, AiOutlineHome, AiOutlineWifi } from 'react-icons/ai';
import logo from '../../img/logo.png';
import logoA from '../../img/logoA.png';
import { TbLocationFilled } from 'react-icons/tb';
import { BiSolidError } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const MenuPresentacion = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const navigate = useNavigate();
  return (
    <Box zIndex="1000" w="100%" display="flex" justifyContent="space-between" alignItems="center" bg="#1e1e1e" borderRadius="0 0 20px 20px" position="fixed" top="0">
      <Box ml="20px">
        <Image w="60px" src={logoA} />
      </Box>
      {/* <Box mr="20px" h="35px" cursor="pointer" w="35px" display="flex" alignItems="center" backgroundColor="primary.100"justifyContent="center" borderRadius="5px" boxShadow="2px 2px 5px 0px rgba(30,30,30,0.25)">
        <HiMenuAlt3 color="#fff" onClick={onOpen} ref={btnRef} fontSize="30px" />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image w="120px" src={logo} />
          </DrawerHeader>

          <DrawerBody>
            <Flex direction="column" gap="10px">
              <Link onClick={()=>{navigate('/presentacion')}} display="flex" flexDirection="row" alignItems="center" gap="5px">
                <AiFillMessage fontSize="20px"/>
                <Text>Sobre nosotros</Text>
              </Link>
              <Link onClick={()=>{navigate('/presentacion')}} display="flex" flexDirection="row" alignItems="center" gap="5px">
                <AiFillCar fontSize="20px"/>
                <Text>Nuestro servicio</Text>
              </Link>
              <Link onClick={()=>{navigate('/presentacion')}} display="flex" flexDirection="row" alignItems="center" gap="5px">
                <AiOutlineWifi fontSize="20px"/>
                <Text>Reservá online</Text>
              </Link>
              <Link display="flex" flexDirection="row" alignItems="center" gap="5px">
                <AiFillWarning fontSize="20px"/>
                <Text>Condiciones</Text>
              </Link>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer> */}
    </Box>
  )
}

export default MenuPresentacion