import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, Image, Input, Link, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React from 'react'
import { HiMenuAlt3 } from 'react-icons/hi';
import { AiFillHome, AiFillMessage, AiOutlineHome } from 'react-icons/ai';
import logo from '../../img/logo.png';
import logoA from '../../img/logoA.png';
import { TbLocationFilled } from 'react-icons/tb';
import { BiSolidError } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { cerrarSesion } from '../../database';
import { TfiMoney } from 'react-icons/tfi';
import { FaCarOn } from 'react-icons/fa6';
import { BsMegaphoneFill } from 'react-icons/bs';
import { RiDiscountPercentFill } from "react-icons/ri";
import { MdDiscount } from 'react-icons/md';

const Menu = ({ adminId = '' }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  const navigate = useNavigate();
  const toast = useToast();

  function eliminarCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  }

  const cerrarSesionUser = async () => {
    const resultadoCerrarSesion = await cerrarSesion();

    if (resultadoCerrarSesion.success) {
      eliminarCookie("adminId");
      navigate('/');
    } else {
      toast({
        title: 'Error al cerrar sesión',
        description: 'Por favor, intente nuevamente.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  }

  return (
    <Box w="100%" display="flex" justifyContent="space-between" alignItems="center" bg="white" >
      <Box>
        <Image w="120px" src={logo} />
      </Box>
      <Box h="40px" cursor="pointer" w="40px" display="flex" alignItems="center" backgroundColor="primary.100" justifyContent="center" borderRadius="5px" boxShadow="2px 2px 5px 0px rgba(30,30,30,0.25)">
        <HiMenuAlt3 color="#fff" onClick={onOpen} ref={btnRef} fontSize="30px" />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image w="50px" src={logoA} />
          </DrawerHeader>


          <DrawerBody>
            {!adminId &&
              <Flex direction="column" gap="10px">
                <Link onClick={() => { navigate(`/home`) }} display="flex" flexDirection="row" alignItems="center" gap="5px">
                  <AiFillHome fontSize="20px" />
                  <Text>Inicio</Text>
                </Link>
                <Link onClick={() => { navigate('/misviajes') }} display="flex" flexDirection="row" alignItems="center" gap="5px">
                  <TbLocationFilled fontSize="20px" />
                  <Text>Mis viajes</Text>
                </Link>
                <Link onClick={() => { navigate('/reportarproblema') }} display="flex" flexDirection="row" alignItems="center" gap="5px">
                  <BiSolidError fontSize="20px" />
                  <Text>Reportar un problema</Text>
                </Link>
                <Link display="flex" flexDirection="row" alignItems="center" gap="5px">
                  <AiFillMessage fontSize="20px" />
                  <Text>Contactar</Text>
                </Link>
              </Flex>
            }
            {adminId &&
              <Flex direction="column" gap="10px">
                <Link onClick={() => { navigate(`/tarifas`) }} display="flex" flexDirection="row" alignItems="center" gap="5px">
                  <TfiMoney fontSize="20px" />
                  <Text>Tarifas</Text>
                </Link>
                <Link onClick={() => { navigate(`/viajes-particulares`) }} display="flex" flexDirection="row" alignItems="center" gap="5px">
                  <FaCarOn fontSize="20px" />
                  <Text>Viajes Particulares</Text>
                </Link>
                <Link onClick={() => { navigate(`/publicidades`) }} display="flex" flexDirection="row" alignItems="center" gap="5px">
                  <BsMegaphoneFill fontSize="20px" />
                  <Text>Publicidades</Text>
                </Link>
                <Link onClick={() => { navigate(`/cupones`) }} display="flex" flexDirection="row" alignItems="center" gap="5px">
                  <MdDiscount fontSize="20px" />
                  <Text>Cupones</Text>
                </Link>
              </Flex>}
          </DrawerBody>

          <DrawerFooter>
            <Button onClick={() => { cerrarSesionUser() }} color="#fff" bg='red.500'>Cerrar sesión</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default Menu