import { Box, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import VolverTitulo from '../../components/usuario/VolverTitulo'
import FormViajeParticular from '../../components/usuario/FormViajeParticular'
import FormLogin from '../../components/usuario/FormLogin'

const ViajePartiuclar = ({ user, setUser }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showLogin, setShowLogin] = useState(false);
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  useEffect(() => {
    if (user == '') {
      setShowLogin(true);
      onOpen();
    }
  }, [user]);

  return (
    <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
      <VolverTitulo texto="Viaje particular" />
      <Box mt="60px" w="85%" maxW="400px" display="flex" flexDirection="column" alignItems="center" h="100vh">
        <FormViajeParticular onOpen={onOpen} user={user} />
        {showLogin && <>
          {!isLargerThan800 &&
            <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} autoFocus={false}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerHeader mt="20px" mb="10px" p="0" w="100%" h="150px" display="flex" justifyContent="center">
                  <Text>Iniciar Sesión</Text>
                </DrawerHeader>
                <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
                  <Box w="100%">
                    <FormLogin id='particular' setUser={setUser} onClose={onClose} />
                  </Box>
                </DrawerBody>
              </DrawerContent>
            </Drawer>}
          {isLargerThan800 &&
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Iniciar Sesión</ModalHeader>
                <ModalCloseButton />
                <ModalBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
                  <Box w="100%">
                    <FormLogin id='particular' setUser={setUser} onClose={onClose} />
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>}
        </>}
      </Box>
    </Box>
  );
}

export default ViajePartiuclar