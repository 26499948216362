import { Box, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import { FaBeer } from 'react-icons/fa';
import React from 'react'
import { BsCalendarEvent } from 'react-icons/bs';
import { BiTimeFive } from 'react-icons/bi';
import { GiCarSeat } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { MdLocationPin, MdOutlineLocationOn } from 'react-icons/md';
import { TbLocationFilled } from 'react-icons/tb';

const CardViajesParticulares = ({ viaje }) => {
    const { origen, destino, asientos_disponibles, fecha, hora, id, id_chofer, precio } = viaje;
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    //const fechaFormateada = new Date(fecha).toLocaleDateString(undefined, options);

    const navigate = useNavigate();

    const irViaje = () => {
        navigate('/viaje-particular/' + id);
    }

    const obtenerElementoEnPosicion1 = (texto) => {
        const arreglo = texto.split(',');
        if (arreglo.length >= 2) {
            return arreglo[1].trim();
        } else {
            return null;
        }
    };


    return (
        <Box backgroundColor="#f0f0f0" p="5px" boxShadow="2px 2px 5px 0px rgba(30,30,30,0.25)" borderRadius="5px" display="flex" justifyContent="center" cursor="pointer" onClick={irViaje}>
            <Box w="95%">
                <Flex direction="column">
                    <Flex>
                        <Flex alignItems="center" gap="5px">
                            <TbLocationFilled />
                            <Text fontWeight="bold">{obtenerElementoEnPosicion1(origen)}</Text>
                        </Flex>
                    </Flex>
                    <Spacer />
                    <Flex alignItems="center" gap="5px">
                        <MdOutlineLocationOn />
                        <Text fontWeight="bold">{obtenerElementoEnPosicion1(destino)}</Text>
                    </Flex>
                </Flex>
                <Flex mt="5px">
                    <Box display="flex" justifyContent="center" alignItems="center" gap="5px">
                        <BsCalendarEvent />
                        <Text>{fecha}</Text>
                    </Box>
                    <Spacer />
                    <Text>${precio}</Text>
                </Flex>
            </Box>
        </Box>
    )
}

export default CardViajesParticulares

//GiCarSeat