import { Box, Button, FormControl, Input, InputGroup, InputLeftElement, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiLogIn } from 'react-icons/bi';
import { MdAlternateEmail, MdPassword } from 'react-icons/md'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { iniciarSesion } from '../../database';

const FormLogin = ({ setUser, id = '', onClose = '' }) => {

    const navigate = useNavigate();
    const toast = useToast();
    const [cargando, setCargando] = useState(false);

    const [formData, setFormData] = useState({
        correo: '',
        contra: ''
    });

    function setCookie(name, value, daysToExpire) {
        const date = new Date();
        date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value}; ${expires}; path=/`;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleClick = async () => {
        setCargando(true);

        const email = formData.correo.trim();

        if (!email || !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            toast({
                title: 'Datos erróneos',
                description: 'Por favor, ingrese un correo electrónico válido.',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
            setCargando(false);
            return;
        }

        setFormData(

        );

        if (!(formData.contra.length >= 8 && formData.contra.length <= 20)) {
            toast({
                title: 'Datos erróneos',
                description: 'La contraseña debe tener entre 8 y 20 caracteres.',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
            setCargando(false);
            return;
        }

        console.log(formData)

        const resultadoInicioSesion = await iniciarSesion(formData);

        if (resultadoInicioSesion.success) {
            setCookie('userId', resultadoInicioSesion.usuario.idInicio, 365);
            setCargando(false);
            setUser(resultadoInicioSesion.usuario);
            if (id != '') {
                setTimeout(() => {
                    onClose();
                }, 1000);
            } else {
                navigate(`/home`);
            }
        } else {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: 'El correo o la contraseña son incorrectos.',
                status: 'error',
                isClosable: true,
            });
        }

        // const res = await fetch("http://localhost:4000/login-usuario",{
        //     method: 'POST',
        //     body: JSON.stringify(formData),
        //     headers: {"Content-Type": "application/json",}
        // });

        // setCargando(false);
        // const data = await res.json();
        // if (res.ok){
        //     console.log(data)
        //     setUserIdCookie(data.pasajero.id);
        //     setUser(data.pasajero);
        //     if (id != ''){
        //         setTimeout(()=>{
        //             onClose();
        //         }, 1000);
        //     } else {
        //         navigate(`/home`);
        //     }

        // } 
        // else if (data.message = 'Correo o contraseña incorrectos'){
        //     toast({
        //         title: 'Datos erróneos',
        //         description: 'Correo o contraseña incorrectos.',
        //         status: 'error',
        //         duration: 4000,
        //         isClosable: true,
        //       });
        // }  
    };

    return (
        <FormControl w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
            <Box w="100%">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <MdAlternateEmail color='gray.300' />
                    </InputLeftElement>
                    <Input w="100%" h="40px" variant='outline' placeholder="correo electrónico" name="correo" onChange={handleChange} />
                </InputGroup>
            </Box>
            <Box w="100%">
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <MdPassword color='gray.300' />
                    </InputLeftElement>
                    <Input type='password' w="100%" h="40px" variant='outline' placeholder="Contraseña" name="contra" onChange={handleChange} />
                </InputGroup>
            </Box>
            <Box w="100%" mb={id == '' ? '20px' : '0px'}>
                <Button isLoading={cargando} onClick={handleClick} w="100%" h="40px" rightIcon={<BiLogIn />} bg='primary.100' color="#fff" variant='solid'>Iniciar sesión</Button>
            </Box>
            {id != '' && <Box w="100%" mb="20px">
                <Button onClick={() => { navigate('/presentacion') }} variant='outline' w="100%" h="40px" rightIcon={<BiLogIn />} borderColor='primary.100' color="primary.100">Registrarse</Button>
            </Box>}
        </FormControl>
    )
}

export default FormLogin