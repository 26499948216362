import { Button } from '@chakra-ui/react'
import React from 'react';
import { BiShare } from 'react-icons/bi';
import { RWebShare } from "react-web-share";

const ButtonCompartir = ({ fecha, hora, origen, destino, precio, id }) => {

  let dato = `Viaje desde ${origen} hacia ${destino}. Sale el día ${fecha} a las ${hora}. Precio $ ${precio}`;

  return (
    <RWebShare
      data={{
        text: dato,
        url: `https://annytraslados.com/viaje/${id}`,
        title: 'Viaje AnnyTraslados',
      }}
    >
      <Button w="100%" h="40px" leftIcon={<BiShare />} color="gray.600" colorScheme='gray.600' variant='outline'> Compartir </Button>
    </RWebShare>
  )
}

export default ButtonCompartir