// customTheme.js
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    colors: {
      primary: {
        100: "#8C3333",
        // ...
        900: "#8C3333",
      },
    },
  })

export default theme;
