import { Box, Button, Image, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import logo from '../../img/logo.png';
import { AiFillCar } from 'react-icons/ai';
import TablaViajes from '../../components/admin/TablaViajes';
import TabMenu from '../../components/admin/TabMenu';
import { GiSteeringWheel } from 'react-icons/gi';
import { GoHistory } from 'react-icons/go';
import { BsFillBagCheckFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import TablaViajesHist from '../../components/admin/TablaViajesHist';
import Menu from '../../components/usuario/Menu';
import { IoMdNotificationsOutline } from 'react-icons/io';
import TablaSolicitudes from '../../components/admin/TablaSolicitudes';
import { TfiMoney } from "react-icons/tfi";
import TablaTarifas from '../../components/admin/TablaTarifas';

const TabContent = ({ selected, text }) => {
  let icon;

  if (text === 'Viajes activos') {
    icon = <GiSteeringWheel fontSize="20px" color="#1e1e1e" />;
  } else if (text === 'Historial') {
    icon = <GoHistory fontSize="20px" color="#1e1e1e" />;
  } else if (text === 'Solicitudes') {
    icon = <IoMdNotificationsOutline fontSize="20px" color="#1e1e1e" />;
  } else if (text === 'Tarifas') {
    icon = <TfiMoney fontSize="20px" color="#1e1e1e" />;
  }

  return (
    <Box>
      {selected ? (
        text // Mostrar el texto si la pestaña está seleccionada
      ) : (
        icon && icon // Mostrar el ícono si la pestaña no está seleccionada y hay un ícono definido
      )}
    </Box>
  );
};

const HomeAdmin = ({ admin }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();

  function getCookie(name) {
    const cookieName = `${name}=`;
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  }

  const adminId = getCookie('adminId');

  useEffect(() => {
    if (adminId == '') {
      navigate('/login');
    } else {

    }
  }, [adminId]);

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };


  return (
    <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
      <Box w="85%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" h="100vh" gap="10px">
        <Menu adminId={adminId} />
        <Box w="100%" mt="-10px" display="flex" flexDirection="row" alignItems="center" gap={["0", "10px"]} justifyContent={["space-between", "flex-start"]}>
          <Button onClick={() => { navigate('/crearviaje') }} leftIcon={<GiSteeringWheel />} w="150px" h="35px" colorScheme='blackAlpha' color="#8C3333" variant='outline'> Crear viaje </Button>
          <Button onClick={() => { navigate('/choferes') }} leftIcon={<AiFillCar />} w="150px" h="35px" colorScheme='blackAlpha' color="#8C3333" variant='outline'> Choferes </Button>
        </Box>
        <Tabs w="100%" variant='line' onChange={handleTabChange}>
          <TabList>
            <Tab> <TabContent selected={selectedTab === 0} text="Viajes activos" /> </Tab>
            <Tab> <TabContent selected={selectedTab === 1} text="Solicitudes" /> </Tab>
            {/* <Tab> <TabContent selected={selectedTab === 2} text="Historial" /> </Tab> */}
            <Tab> <TabContent selected={selectedTab === 3} text="Tarifas" /> </Tab>
          </TabList>
          <TabPanels p="0">
            <TabPanel p="0">
              <TablaViajes />
            </TabPanel>

            <TabPanel p="0">
              <TablaSolicitudes />
            </TabPanel>

            {/* <TabPanel p="0">
              <TablaViajesHist />
            </TabPanel> */}

            <TabPanel p="0">
              <TablaTarifas sinLimite={false} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <TabMenu />
    </Box>
  )
}

export default HomeAdmin