import { Box, Button, Flex, Image, Link, Spacer, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import error from '../../img/error.png';
import correcto from '../../img/correcto.png';
import sin_viaje from '../../img/sin_viaje.png';
import reservado from '../../img/reservado.png';
import { useNavigate } from 'react-router-dom';
import VolverTitulo from './VolverTitulo';

const Mensaje = ({tipo}) => {
    const [imgSelected, setImgSelected] = useState();
    const [texto, setTexto] = useState({
        mensaje: '',
        titulo: ''
    });
    const [link, setLink] = useState({
        url: '',
        text: ''
    });

    const navigate = useNavigate();

    const ImagenTipo = (tipo) => {
        if (tipo === "reserva_error") {
            setImgSelected(error);
            setTexto({mensaje: 'Ha ocurrido un error al intentar reservar el viaje.', titulo: 'Error'});
            setLink({url: '/reportarproblema', text: 'Reportar un problema'});
        } else if (tipo === "reservar_correcta") {
            setImgSelected(reservado);
            setTexto({mensaje: 'El viaje ha sido reservado correctamente. Nos comunicaremos a la brevedad.', titulo: 'Reservado'});
            setLink({url: '/', text: 'Inicio'});
        } else if (tipo === "reporte_correcto") {
            setImgSelected(correcto);
            setTexto({mensaje: 'El reporte se ha enviado correctamente.', titulo: 'Reporte'});
            setLink({url: '/', text: 'Inicio'});
        } else if (tipo === "sin_viaje") {
            setImgSelected(sin_viaje);
            setTexto({mensaje: 'No se han encontrado viajes que coincidan con tus preferencias.', titulo: 'Reporte'});
            setLink({url: '/viajeparticular', text: 'Solicitá un viaje particular'});
          }
    };

    useEffect(()=>{
        ImagenTipo(tipo);
    }, []);

  return (
        <Box w="100%" maxW="400px" display="flex" flexDirection="column" alignItems="center" mt="100px">
            <Flex direction="column" alignItems="center" justifyContent="center" w="100%" h="50%">
                <Image src={imgSelected} w="70px" />
                <Text textAlign="center">{texto.mensaje}</Text>
            </Flex>
            <Flex direction="column" alignItems="center" justifyContent="center" w="100%" mt="50px">
                <Button onClick={()=>{navigate(link.url)}} w="100%" h="40px" colorScheme='blackAlpha' color="#8C3333" variant='outline'>{link.text}</Button>
            </Flex>
        </Box>
  )
}

export default Mensaje