import { Box, Button, FormControl, Input, InputGroup, InputLeftElement, Select, useToast } from '@chakra-ui/react'
import React from 'react'
import VolverTitulo from '../../components/usuario/VolverTitulo'
import { TbLocationFilled } from 'react-icons/tb'
import { MdOutlineLocationOn } from 'react-icons/md'
import { FaSearchLocation } from 'react-icons/fa'
import { BiSolidError, BiSolidMessageAltError } from 'react-icons/bi'

const ReportarProblema = () => {
    const toast = useToast()

    const reportar = () =>{
        toast({
            description: "Su reporte ha sido enviado.",
            status: 'success',
            duration: 4000,
            isClosable: true,
        })
    }

  return (
    <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
        <VolverTitulo texto="Reportar un problema"/>
        <Box mt="60px" w="85%" maxW="400px" display="flex" flexDirection="column" alignItems="center" h="100vh">
            <FormControl w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px" mt="20px">
                <Box w="100%">
                    <Select placeholder='Seleccione el tipo de problema' w="100%" h="40px" variant='outline'>
                        <option value="error-login">Error al iniciar sesión en mi cuenta</option>
                        <option value="error-reserva">No puedo realizar una reserva de viaje</option>
                        <option value="error-busqueda">Problemas al buscar rutas o destinos específicos</option>
                        <option value="precios-incorrectos">El sistema muestra precios incorrectos</option>
                        <option value="no-asistencia">El conductor no se presentó al encuentro acordado</option>
                        <option value="cancelar-modificar">No puedo cancelar una reserva</option>
                        <option value="sin-confirmacion">No recibí confirmación de mi reserva por correo electrónico</option>
                        <option value="problemas-comunicacion">No puedo comunicarme con el conductor o el pasajero</option>
                        <option value="otro-problema">Otro problema técnico o error no mencionado anteriormente</option>

                    </Select>
                </Box>
                <Box w="100%">
                <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                            <BiSolidMessageAltError color='gray.300' />
                        </InputLeftElement>
                        <Input w="100%" h="40px" variant='outline' placeholder='Describí el problema' name="destino" />
                    </InputGroup>
                </Box>
                <Box w="100%">
                    <Button w="100%" h="40px" onClick={reportar} leftIcon={<BiSolidError />} bg='#8C3333' color="#fff" variant='solid'> Reportar </Button>
                </Box>
            </FormControl>
        </Box>
    </Box>
  )
}

export default ReportarProblema