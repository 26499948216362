import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import VolverTitulo from '../../components/usuario/VolverTitulo'
import TabMenu from '../../components/admin/TabMenu'
import { useNavigate } from 'react-router-dom'
import { IoIosAddCircleOutline } from 'react-icons/io'
import TablaPublicidad from '../../components/admin/TablaPublicidad'
import FormAgregarPublicidad from '../../components/admin/FormAgregarPublicidad'
import { obtenerPublicidades } from '../../database'

const Publicidades = () => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [actualizado, setActualizado] = useState(false);

    function getCookie(name) {
        const cookieName = `${name}=`;
        const cookiesArray = document.cookie.split(';');
        for (let i = 0; i < cookiesArray.length; i++) {
            let cookie = cookiesArray[i].trim();
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    }

    const adminId = getCookie('adminId');

    useEffect(() => {
        if (adminId == '') {
            navigate('/login');
        } else {

        }
    }, [adminId]);

    return (
        <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
            <VolverTitulo texto="Publicidades" />
            <Box mt="60px" w="85%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" h="100vh" gap="10px">
                <Box w="100%" display="flex" justifyContent="flex-start">
                    <Button onClick={onOpen} leftIcon={<IoIosAddCircleOutline />} w="200px" h="35px" colorScheme='blackAlpha' color="#8C3333" variant='outline'>Agregar publicidad</Button>
                </Box>
                <TablaPublicidad setActualizado={setActualizado} actualizado={actualizado} />
            </Box>
            <TabMenu />
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent w="85%">
                    <ModalHeader>Nueva publicidad</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormAgregarPublicidad setActualizado={setActualizado} onClose={onClose} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default Publicidades