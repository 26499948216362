import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Carrousel = ({ images }) => {
  return (
    <Carousel
      stopOnLastSlide={false}
      showStatus={false}
      showThumbs={false}
      showArrows={false}
      autoPlay={true}
      infiniteLoop={true}
    >
      {images.map((image, index) => (
        <Box key={index} height={['150px', '250px', '350px']} textAlign="center">
          <Image height={['150px', '250px', '350px']} src={image.url} alt={`Slide ${index}`} />
        </Box>
      ))}
    </Carousel>
  );
};

export default Carrousel;
