import { Box, Flex, Link, Text } from '@chakra-ui/react'
import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const VolverTitulo = ({ texto, url = '' }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (url != '') {
            navigate(`${url}`);
        } else {
            window.history.go(-1);
        }
    };

    return (
        <Box w="100%" position="fixed" bg="#fff" zIndex="10" display="flex" flexDirection="column" alignItems="center" h="50px" boxShadow={["2px 2px 5px 0px rgba(30,30,30,0.25)", "2px 2px 5px 0px rgba(30,30,30,0.25)", "none"]}>
            <Flex w={["340px", "600px", "800px"]} alignItems="center" justifyContent="center" h="50px" >
                <Box onClick={handleClick} cursor="pointer">
                    <IoIosArrowBack fontSize="17px" />
                </Box>
                <Text w="100%" fontSize="17px" fontWeight="600" textAlign="center">{texto}</Text>
            </Flex>
        </Box>
    )
}

export default VolverTitulo