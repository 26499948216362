import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Checkbox, Divider, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Select, Spacer, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useDisclosure, useMediaQuery, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import VolverTitulo from '../../components/usuario/VolverTitulo'
import { BsCheckLg, BsFillStoplightsFill, BsPeopleFill, BsWhatsapp } from 'react-icons/bs'
import { BiCalendar, BiEdit, BiShare } from 'react-icons/bi'
import { MdDiscount, MdOutlineCancel, MdOutlineLocationOn } from 'react-icons/md'
import { FaSuitcaseRolling } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { TbLocationFilled } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import FormLogin from '../../components/usuario/FormLogin'
import ButtonCompartir from '../../components/usuario/ButtonCompartir'
import { AiOutlineCheckCircle } from 'react-icons/ai';
import emailjs from "@emailjs/browser";
import { getViajePorId, getChoferPorViaje, reservarViaje, getPasajerosPorViaje, cancelarReservaViaje, marcarViajeTerminado, eliminarViaje, getTarifas, obtenerCuponPorCodigo } from '../../database';
import { app } from "../../firebase";
import { getAuth } from "firebase/auth";
import EditarViaje from '../../components/admin/EditarViaje';
import { FaRegCircleStop } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import FormRegistro from '../../components/usuario/FormRegistro'

const Viaje = ({ user, setUser, admin }) => {
    const { id } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isCancelOpen, onOpen: onCancelOpen, onClose: onCancelClose } = useDisclosure();
    const { isOpen: isPasajeroOpen, onOpen: onPasajeroOpen, onClose: onPasajeroClose } = useDisclosure();
    const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
    const [viajeTerminado, setViajeTerminado] = useState(false);
    const [viajeTerminadoStatus, setViajeTerminadoStatus] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const [viaje, setViaje] = useState();
    const [paradas, setParadas] = useState();
    const [pasajeros, setPasajeros] = useState([]);
    const [chofer, setChofer] = useState([]);
    const [tarifas, setTarifas] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const initialRef = useRef(null)
    const finalRef = useRef(null)
    const [cargando, setCargando] = useState(false);
    const [cuponUsado, setCuponUsado] = useState(false);
    const [precio, setPrecio] = useState(null);
    const [formData, setFormData] = useState({
        comentario_adicional: '',
        origen_busqueda: '',
        destino_busqueda: '',
        cant_pasajeros: 0,
        equipaje: false,
        id_viaje: '',
        id_pasajero: '',
        localidad: '',
        precio_total: 0
    });

    const obtenerTarifas = async () => {
        const resultadoTarifas = await getTarifas();

        if (resultadoTarifas.tarifas.length > 0) {
            setTarifas(resultadoTarifas.tarifas);
        }
    }

    const handleTarifaChange = (event) => {
        const localidadSeleccionada = event.target.value;

        console.log(precio)
        console.log(localidadSeleccionada)

        let total = parseFloat(precio * 1000) + parseFloat(localidadSeleccionada * 1000);
        setFormData({
            ...formData,
            localidad: localidadSeleccionada,
            precio_total: total
        });
    };

    function getCookie(name) {
        const cookieName = `${name}=`;
        const cookiesArray = document.cookie.split(';');
        for (let i = 0; i < cookiesArray.length; i++) {
            let cookie = cookiesArray[i].trim();
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    }

    const adminId = getCookie('adminId');

    const [maximo, setMaximo] = useState(0);
    const [cupon, setCupon] = useState('');
    const [dataCupon, setDataCupon] = useState('');
    const [fechaFormateada, setFechaFormateada] = useState('');
    const [haReservado, setHaReservado] = useState(false);
    const [reservadoPrecio, setReservadoPrecio] = useState('');
    const form = useRef();

    const [count, setCount] = useState(0);

    const auth = getAuth(app);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // El usuario está autenticado, user contiene información sobre el usuario.
                setUser(user);
            } else {
                // El usuario no está autenticado, user es null.
                setUser(null);
            }
        });

        return () => {
            unsubscribe(); // Limpia el listener cuando el componente se desmonta.
        };
    }, []);

    const handleIncrement = () => {
        if (count < maximo) {
            setCount((prevCount) => {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    cant_pasajeros: prevCount + 1
                }));
                return prevCount + 1;
            });
        }
    };

    const handleDecrement = () => {
        if (count > 0) {
            setCount((prevCount) => {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    cant_pasajeros: prevCount - 1
                }));
                return prevCount - 1;
            });
        }
    };


    const [isChecked, setIsChecked] = useState(false);

    const handleTextClick = () => {
        setIsChecked(!isChecked);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChangeCupon = (e) => {
        const { name, value } = e.target;
        setCupon((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleClick = async () => {
        setCargando(true);

        if (origen == null) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "Debes seleccionar el origen.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (destino == null) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "Debes seleccionar el destino.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (formData.origen_busqueda.length == '') {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "La dirección de búsqueda es requerida.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (formData.destino_busqueda == '') {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "La dirección de llegada es  requerida.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (formData.cant_pasajeros == 0) {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "La cantidad de pasajeros debe ser mayor a 0 .",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        if (formData.localidad == '') {
            setCargando(false);
            toast({
                title: 'Datos erróneos',
                description: "La localidad es requerida.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
            return;
        }

        const resultadoReserva = await reservarViaje(formData, origen.nombre, destino.nombre, dataCupon);
        setCargando(false);

        if (resultadoReserva.success) {
            //enviarMailReserva();
            toast({
                title: 'Viaje reservado',
                description: "La reserva se realizó correctamente.",
                status: 'success',
                duration: 4000,
                isClosable: true,
            })
            onClose();
            obtenerViajes();
        } else {
            toast({
                title: 'Error',
                description: "Ocurrió un error al intentar reservar.",
                status: 'error',
                duration: 4000,
                isClosable: true,
            })
        }

    };

    let templateParamsReserva = {
        titulo: 'El viaje ha sido reservado correctamente',
        para: 'huckalexis0@gmail.com',
        mensaje: ''
    }

    let templateParamsCancelar = {
        titulo: 'El viaje ha sido cancelado correctamente',
        para: 'huckalexis0@gmail.com',
        mensaje: ''
    }

    // const enviarMailReserva = ()=>{

    //     templateParamsReserva.mensaje = `Has reservado ${formData.cant_pasajeros} lugar/es para el viaje desde ${viaje.origen} hasta ${viaje.destino} el ${fechaFormateada} a las ${viaje.hora} por un precio de $${viaje.precio}. \n Te buscamos en la dirección ${formData.destino_busqueda} y te llevamos a ${formData.destino_busqueda}. \n Te recordamos que, en caso de cancelar tu reserva, podés hacerlo hasta 24 horas antes del horario de salida, de lo contrario se te cobrará un 50% del precio establecido`
    //     templateParamsReserva.para = user.correoelectronico;

    //     emailjs.send('service_2ydpz2d', 'template_jwihuul', templateParamsReserva, 'nYd7ti16wSjWCucB9')
    //     .then((result) => {
    //             console.log(result.text);
    //         }, (error) => {
    //             console.log(error.text);
    //         });
    // }

    const obtenerViajes = async () => {
        const resultadoViaje = await getViajePorId(id);

        if (resultadoViaje.success) {
            setParadas(resultadoViaje.paradas);
            setViaje(resultadoViaje.viaje);
            setMaximo(resultadoViaje.viaje.asientos_disponibles);
        }

        const resultadoChofer = await getChoferPorViaje(id);
        if (resultadoChofer) {
            setChofer([resultadoChofer]);
        }

        const resultadoPasajeros = await getPasajerosPorViaje(id);
        if (resultadoPasajeros.success) {
            if (user && user.uid) {
                const haReservado = resultadoPasajeros.pasajeros.some((pasajero) => {
                    if (pasajero.idInicio === user.uid) {
                        setReservadoPrecio(pasajero.viajePasajero.precio_total * pasajero.viajePasajero.cant_pasajeros)
                        return true;
                    }
                    return false;
                });
                setHaReservado(haReservado);
            } else {
                setHaReservado(false);
            }

            setPasajeros(resultadoPasajeros.pasajeros);
        } else {
            setHaReservado(false);
            setPasajeros([]);
        }
    }


    useEffect(() => {
        obtenerViajes();
    }, [user, id, viajeTerminado, viajeTerminadoStatus])

    const verificarUser = () => {
        if (viaje.asientos_disponibles > 0) {
            if (!(user && (user.uid || user.idInicio))) {
                setShowLogin(true);
                onOpen();
            } else {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    id_viaje: id,
                    id_pasajero: user.uid && user.uid ? user.uid : user.idInicio
                }));
                setShowLogin(false);
                onOpen();
            }
        } else {
            toast({
                title: 'No quedan asientos',
                description: "No quedan lugares disponibles.",
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
        }

    }

    let templateParamsCancelPasajero = {
        titulo: 'El viaje ha sido reservado correctamente',
        para: 'huckalexis0@gmail.com', //correo analia
        mensaje: ''
    }

    const enviarMailCancelPasajero = () => {

        templateParamsCancelPasajero.mensaje = `Has cancelado el viaje desde ${viaje.origen} hasta ${viaje.destino} el ${fechaFormateada} a las ${viaje.hora}.`

        emailjs.send('service_2ydpz2d', 'template_jwihuul', templateParamsCancelPasajero, 'nYd7ti16wSjWCucB9')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    }

    let templateParamsCancelViaje = {
        titulo: 'El viaje ha sido reservado correctamente',
        para: 'huckalexis0@gmail.com', //correo analia
        mensaje: ''
    }

    const enviarMailCancelViaje = () => {

        templateParamsCancelViaje.mensaje = `El viaje desde ${viaje.origen} hasta ${viaje.destino} el ${fechaFormateada} a las ${viaje.hora}, ha sido cancelado por Anny Traslados. Si crees que se trata de un error, comunícanoslo por Whatsapp.`

        pasajeros.map((pasajero) => {
            templateParamsCancelViaje.para = pasajero.correoelectronico;

            emailjs.send('service_2ydpz2d', 'template_jwihuul', templateParamsCancelViaje, 'nYd7ti16wSjWCucB9')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
        })
    }

    const cancelarReserva = async () => {
        const data = {
            id_viaje: id,
            id_pasajero: user.uid
        };

        const resultadoCancelarReserva = await cancelarReservaViaje(data);

        if (resultadoCancelarReserva.success) {
            // enviarMailCancelPasajero();
            //obtenerViajes();
            setViajeTerminadoStatus(true);
            onCancelClose();
            toast({
                title: 'Reserva cancelada',
                description: "La reserva se canceló correctamente.",
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

        }
    }

    const terminarViaje = async () => {
        try {
            const resultadoTerminar = await marcarViajeTerminado(id);

            if (resultadoTerminar.success) {
                setViajeTerminado(true);
                toast({
                    title: 'Viaje terminado.',
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Error al terminar el viaje.',
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
            }
        } catch (e) {
            toast({
                title: 'Error al terminar el viaje.',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    }

    const { isOpen: isCancelarOpen, onOpen: onCancelarOpen, onClose: onCancelarClose } = useDisclosure();

    const [idCancelar, setIdCancelar] = useState('');
    const [idViaje, setIdViaje] = useState('');

    const { isOpen: isEditarOpen, onOpen: onEditarOpen, onClose: onEditarClose } = useDisclosure();

    const obtenerViajeEditar = () => {
        setIdViaje(id);
        onEditarOpen();
    }

    const popupCancelarViaje = () => {
        onCancelarOpen();
        setIdCancelar(id);
    }

    const cancelarViaje = async () => {
        if (idCancelar != '') {
            try {
                const resultadoeliminar = await eliminarViaje(idCancelar);

                if (resultadoeliminar.success) {
                    enviarMailCancelViaje();
                    navigate('/admin');
                    toast({
                        title: 'El viaje se ha eliminado correctamente.',
                        status: 'success',
                        duration: 4000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Ocurrió un error al intentar eliminar el viaje.',
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                    })
                }
                onCancelarClose();
            } catch (error) {
                console.error('Error en la solicitud de eliminación:', error);
            }
        } else {

        }
    }

    function convertirFormato(numero) {
        const numeroLimpio = numero.replace(/[-\s]/g, '');

        const numeroConPrefijo = "+54" + numeroLimpio;

        return numeroConPrefijo;
    }

    function generateWhatsAppLink(fixedNumber, recipientNumber, text) {
        const urlEncodedText = encodeURIComponent(text);
        return `https://api.whatsapp.com/send?phone=+541136575905&text=${urlEncodedText}`;
    }

    function redirectToWhatsApp(fixedNumber, recipientNumber, text) {
        const whatsappLink = generateWhatsAppLink(fixedNumber, recipientNumber, text);
        window.location.href = whatsappLink;
    }

    const enviarDatosChofer = () => {
        let nroChofer = convertirFormato('1136575905');
        let msj = `Datos sobre el viaje: \n`;
        msj += `Desde: ${viaje.origen}. \n`;
        msj += `Hacia: ${viaje.destino}. \n`;
        msj += `El día ${fechaFormateada} a las ${viaje.hora}. \n`;
        msj += `Pasajero/s: \n`;
        pasajeros.map((pasajero, index) => {
            console.log(pasajero);
            msj += ` - ${pasajero.nombre}. Origen: ${pasajero.viajePasajero.origen} y destino: ${pasajero.viajePasajero.destino}.  Dirección de búsqueda: ${pasajero.viajePasajero.origen_busqueda}. Direccion de destino: ${pasajero.viajePasajero.destino_busqueda}. Comentario del pasajero: ${pasajero.viajePasajero.comentario_adicional} \n`;
        })
        msj += `Precio por pasajero: $${viaje.precio} \n`;

        redirectToWhatsApp('1136575905', nroChofer, msj);
    }

    const [origen, setOrigen] = useState(null);
    const [destino, setDestino] = useState(null);


    const handleSeleccion = (ciudad, peso) => {
        if (origen === null) {
            setOrigen({ nombre: ciudad, peso });
        } else if (destino === null) {
            if (peso < origen.peso) {
                setDestino(origen);
                setOrigen({ nombre: ciudad, peso });
            }
            else {
                setDestino({ nombre: ciudad, peso });
                // Obtener el precio de la parada seleccionada como destino
                const paradaSeleccionada = paradas.find(parada => parada.ciudad === ciudad);
                console.log(paradaSeleccionada)
                if (paradaSeleccionada) {
                    console.log(parseFloat(paradaSeleccionada.precio))
                    setPrecio(parseFloat(paradaSeleccionada.precio));
                } else {
                    setPrecio(parseFloat(viaje.precio));
                }
            }
        }
        else {
            setOrigen({ nombre: ciudad, peso });
            setDestino(null);
            setPrecio(null); // Limpiar el precio si se cambia el origen
        }
    };


    useEffect(() => {
        obtenerTarifas();
    }, []);

    const [pasajeroSeleccionado, setPasajeroSeleccionado] = useState('');

    const obtenerPasajeroPorId = (id_pasajero) => {
        console.log(id_pasajero);
        const pasajero = pasajeros.find(p => p.idInicio === id_pasajero);
        setPasajeroSeleccionado(pasajero)
        onPasajeroOpen(true);
    }

    const validarCupon = async () => {
        const resultadoCupon = await obtenerCuponPorCodigo(cupon.codigo_descuento);

        if (cuponUsado) {
            toast({
                title: 'Ya has aplicado un código de descuento.',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
            return;
        }

        if (resultadoCupon.success) {

            if (resultadoCupon.cupon.tipo == 'porcentaje') {
                if (precio != null) {
                    let total = parseFloat(precio) - (parseFloat(precio) * parseFloat(resultadoCupon.cupon.porcentaje) / 100);
                    total = total * 1000;

                    setFormData({
                        ...formData,
                        precio_total: total
                    });
                    setViaje({
                        ...formData,
                        precio: total
                    });
                } else {
                    if (formData.precio_total != '') {
                        let total = parseFloat(formData.precio_total) - (parseFloat(formData.precio_total) * parseFloat(resultadoCupon.cupon.porcentaje) / 100);
                        setFormData({
                            ...formData,
                            precio_total: total
                        });
                    } else {
                        let total = parseFloat(viaje.precio * 1000) - (parseFloat(viaje.precio * 1000) * parseFloat(resultadoCupon.cupon.porcentaje) / 100);
                        setPrecio(total);
                        setViaje({
                            ...formData,
                            precio: total
                        });
                    }
                }
            } else {
                if (precio != null) {
                    let total = parseFloat(formData.precio_total) - parseFloat(resultadoCupon.cupon.monto * 1000);
                    setFormData({
                        ...formData,
                        precio_total: total
                    });
                    setViaje({
                        ...formData,
                        precio: total
                    });
                } else {
                    if (formData.precio_total != '') {
                        let total = parseFloat(formData.precio_total) - parseFloat(resultadoCupon.cupon.monto * 1000);
                        setFormData({
                            ...formData,
                            precio_total: total
                        });
                    } else {
                        let total = parseFloat(viaje.precio * 1000) - parseFloat(resultadoCupon.cupon.monto * 1000);
                        setPrecio(total);
                        setViaje({
                            ...formData,
                            precio: total
                        });
                    }
                }
            }

            setCuponUsado(true);
            setDataCupon(resultadoCupon.cupon);

            toast({
                title: 'Código aplicado.',
                status: 'success',
                duration: 4000,
                isClosable: true,
            });
        } else {

            toast({
                title: 'Código no encontrado.',
                status: 'error',
                duration: 4000,
                isClosable: true,
            });
        }
    }

    const convertirFecha = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            const date = new Date(timestamp.seconds * 1000); // Convertir segundos a milisegundos
            return date.toLocaleDateString(); // O usa toLocaleString() si necesitas la hora también
        }
        return '';
    };

    return (
        <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
            <VolverTitulo texto="Viaje" volver="" />
            {viaje &&
                <Box w="85%" maxW={["400px", "600px", "800px"]} display="flex" flexDirection="column" alignItems="center" h="100vh" mt="60px">
                    <Box fontWeight="600" w="100%" gap="20px" display="flex" flexDirection={["column", "column", "row"]}>
                        <Box w="100%">
                            <Flex w="100%" flexDirection="column">
                                <Box w="100%">
                                    <Text color="#adacac" fontSize="13px">ORIGEN</Text>
                                    <Text maxW="100%" isTruncated mt="-5px">{viaje.origen}</Text>
                                </Box>
                                <Spacer />
                                {paradas && paradas.map((parada, index) => {
                                    return (
                                        <Box w="100%" display="flex" flexDirection="row" alignItems="center" gap="10px">
                                            <Box>
                                                <FaRegCircleStop color="#adacac" />
                                            </Box>
                                            <Box>
                                                <Text color="#adacac" fontSize="13px">PARADA {index + 1}</Text>
                                                <Text maxW="100%" isTruncated mt="-5px">{parada.ciudad}</Text>
                                            </Box>

                                        </Box>
                                    );
                                })}
                                <Box w="100%">
                                    <Text color="#adacac" fontSize="13px">DESTINO</Text>
                                    <Text maxW="100%" isTruncated mt="-5px">{viaje.destino}</Text>
                                </Box>
                            </Flex>
                            <Divider mb="10px" mt="-3px" />
                            <Flex w="100%">
                                <Box>
                                    <Text color="#adacac" fontSize="13px">FECHA</Text>
                                    <Text textAlign="left" mt="-5px">{convertirFecha(viaje.fecha)}</Text>
                                </Box>
                                <Spacer />
                                <Box>
                                    <Text color="#adacac" fontSize="13px">SALIDA</Text>
                                    <Text textAlign="center" mt="-5px">{viaje.hora}</Text>
                                </Box>
                                <Spacer />
                                <Box>
                                    <Text color="#adacac" fontSize="13px">DISPONIBLES</Text>
                                    <Text textAlign="right" mt="-5px">{viaje.asientos_disponibles}</Text>
                                </Box>
                            </Flex>
                            <Divider mb="10px" mt="-3px" />
                            <Flex w="100%" justifyContent="flex-end">
                                <Box>
                                    <Text textAlign="right" color="#adacac" fontSize="13px">PRECIO</Text>
                                    {haReservado ? <Text textAlign="right" mt="-5px">${reservadoPrecio}</Text>
                                        : <Text textAlign="right" mt="-5px">${viaje.precio}</Text>}
                                </Box>
                            </Flex>
                            <Divider mb="10px" mt="-3px" />
                            {viaje.realizado == "false" &&
                                <>
                                    <Flex w="100%" >
                                        <Box w="100%">
                                            {!adminId && <Button w="100%" h="40px" color="#fff" variant='solid'
                                                leftIcon={haReservado ? <MdOutlineCancel /> : <BiCalendar />}
                                                onClick={haReservado ? onCancelOpen : verificarUser}
                                                bg={haReservado ? 'red.500' : '#8C3333'}
                                            > {haReservado ? 'Cancelar Reserva' : 'Reservar'} </Button>}
                                            {adminId &&
                                                <Button w="100%" h="40px" onClick={() => { popupCancelarViaje(viaje.id) }} color="#fff" variant='solid' leftIcon={<MdOutlineCancel />} bg='red.500' > Eliminar viaje </Button>}
                                        </Box>
                                    </Flex>

                                    <Box w="100%" mt="10px">
                                        {adminId && <Button onClick={() => { obtenerViajeEditar() }} w="100%" h="40px" color="primary.100" leftIcon={<BiEdit />} borderColor='primary.100' variant='outline'> Editar </Button>}
                                    </Box>

                                    <Flex w="100%" mt="10px">
                                        {!adminId && <>
                                            <Box w="48.5%">
                                                <Button w="100%" h="40px" leftIcon={<BsWhatsapp />} colorScheme='teal' variant='outline'> Contactar </Button>
                                            </Box>
                                            <Spacer />
                                            <Box w="48.5%">
                                                <ButtonCompartir origen={viaje.origen} fecha={viaje.fecha} hora={viaje.hora} destino={viaje.destino} precio={viaje.precio} id={id} />
                                            </Box>
                                        </>}
                                        {adminId &&
                                            <Box w="100%">
                                                <Button onClick={() => { enviarDatosChofer(); }} w="100%" h="40px" leftIcon={<BsWhatsapp />} colorScheme='teal' variant='outline'> Enviar datos al chofer </Button>
                                            </Box>}
                                    </Flex>
                                    {adminId && <Flex w="100%" mt="10px">
                                        <Button onClick={() => { terminarViaje() }} w="100%" h="40px" color="#fff" variant='solid' leftIcon={<AiOutlineCheckCircle />} bg='primary.100' > Finalizar viaje </Button>
                                    </Flex>}
                                </>}
                            {viaje.realizado == "true" &&
                                <Flex w="100%" >
                                    <Box w="100%" bg="primary.100" h="40px" display="flex" justifyContent="center" alignItems="center" borderRadius="5px">
                                        <Text color="#fff">Viaje terminado.</Text>
                                    </Box>
                                </Flex>}

                        </Box>
                        <Box w="100%" >
                            <Flex w="100%" mt={["-10px", "15px", "0"]} direction="column">
                                <Box w="100%">
                                    <Text color="#adacac" fontSize="13px">CHOFER</Text>
                                </Box>
                                {chofer && chofer.map((chof) => {
                                    return (
                                        <Flex mt="5px" index={chof.id}>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <Image w="50px" h="50px" objectFit='cover' borderRadius="25px"
                                                    src="https://cdn-icons-png.flaticon.com/128/5651/5651503.png" />
                                            </Box>
                                            <Flex direction="column">
                                                <Text ml="10px" mb="-5px" fontWeight="bold">{chof.marca_vehiculo} - {chof.modelo_vehiculo}</Text>
                                                <Text ml="10px" mb="-5px" fontWeight="bold">{chof.patente_vehiculo} - {chof.color_vehiculo}</Text>
                                                <Text ml="10px" color="#adacac" fontSize="16px">{chof.nombre}</Text>
                                            </Flex>
                                        </Flex>
                                    );

                                })}
                                {chofer.length == 0 && (
                                    <Flex direction="column" h="100%" justifyContent="center">
                                        <Text>No hay chofer asignado.</Text>
                                    </Flex>
                                )}
                            </Flex>
                            <Flex w="100%" mt="15px" direction="column" mb="20px">
                                <Box w="100%">
                                    <Text color="#adacac" fontSize="13px">PASAJEROS</Text>
                                </Box>
                                {pasajeros && pasajeros.map((pasajero) => (
                                    <Flex cursor={adminId && "pointer"} mt="5px" key={pasajero.id} onClick={() => { obtenerPasajeroPorId(pasajero.idInicio) }}>
                                        <Box>
                                            <Image w="40px" h="40px" objectFit='cover' borderRadius="20px" src="https://cdn-icons-png.flaticon.com/128/149/149071.png" />
                                        </Box>
                                        <Flex direction="column" h="100%" justifyContent="center">
                                            <Text ml="10px">{pasajero.nombre}</Text>
                                        </Flex>
                                    </Flex>
                                ))}

                                {pasajeros.length == 0 && (
                                    <Flex direction="column" h="100%" justifyContent="center">
                                        <Text>No hay pasajeros.</Text>
                                    </Flex>
                                )}
                            </Flex>
                            {adminId && <Modal isOpen={isPasajeroOpen} onClose={onPasajeroClose}>
                                <ModalOverlay />
                                <ModalContent maxH="600px" w="90%" mt={["10%", "1.5%"]} mb="0">
                                    <ModalHeader>DATOS DEL PASAJERO/A</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody mb="5px" overflowY="scroll">
                                        {pasajeroSeleccionado &&
                                            <Box w="100%" display="flex" flexDirection="column" justifyContent="flex-start" gap="5px">
                                                <Box display="flex" flexDirection="column">
                                                    <Text color="#adacac" fontSize="13px">NOMBRE</Text>
                                                    <Text fontWeight="600" mt="-5px">{pasajeroSeleccionado.nombre}</Text>
                                                </Box>
                                                <Box display="flex" flexDirection="column">
                                                    <Text color="#adacac" fontSize="13px">TELEFONO</Text>
                                                    <Text fontWeight="600" mt="-5px">{pasajeroSeleccionado.telefono}</Text>
                                                </Box>
                                                <Divider mb="10px" mt="-3px" h="1px" bg="primary.100" />
                                                <Box display="flex" flexDirection="column">
                                                    <Text color="#adacac" fontSize="13px">ORIGEN</Text>
                                                    <Text fontWeight="600" mt="-5px">{pasajeroSeleccionado.viajePasajero.origen}</Text>
                                                </Box>
                                                <Box display="flex" flexDirection="column">
                                                    <Text color="#adacac" fontSize="13px">DIRECCIÓN DE BÚSQUEDA</Text>
                                                    <Text fontWeight="600" mt="-5px">{pasajeroSeleccionado.viajePasajero.origen_busqueda}</Text>
                                                </Box>
                                                <Divider mb="10px" mt="-3px" h="1px" bg="primary.100" />
                                                <Box display="flex" flexDirection="column">
                                                    <Text color="#adacac" fontSize="13px">DESTINO</Text>
                                                    <Text fontWeight="600" mt="-5px">{pasajeroSeleccionado.viajePasajero.destino}</Text>
                                                </Box>
                                                <Box display="flex" flexDirection="column">
                                                    <Text color="#adacac" fontSize="13px">DIRECCIÓN A LLEVAR</Text>
                                                    <Text fontWeight="600" mt="-5px">{pasajeroSeleccionado.viajePasajero.destino_busqueda}</Text>
                                                </Box>
                                                <Divider mb="10px" mt="-3px" h="1px" bg="primary.100" />
                                                <Box display="flex" flexDirection="column">
                                                    <Text color="#adacac" fontSize="13px">EQUIPAJE</Text>
                                                    <Text fontWeight="600" mt="-5px">{pasajeroSeleccionado.viajePasajero.equipaje == true ? 'Lleva equipaje' : 'No lleva equipaje'}</Text>
                                                </Box>
                                                <Divider mb="10px" mt="-3px" h="1px" bg="primary.100" />
                                                <Box display="flex" flexDirection="column">
                                                    <Text color="#adacac" fontSize="13px">CANTIDAD DE PASAJEROS</Text>
                                                    <Text fontWeight="600" mt="-5px">{pasajeroSeleccionado.viajePasajero.cant_pasajeros}</Text>
                                                </Box>
                                                <Box display="flex" flexDirection="column">
                                                    <Text color="#adacac" fontSize="13px">PRECIO TOTAL</Text>
                                                    <Text fontWeight="600" mt="-5px">${pasajeroSeleccionado.viajePasajero.precio_total * pasajeroSeleccionado.viajePasajero.cant_pasajeros}  </Text>
                                                </Box>
                                                {pasajeroSeleccionado.viajePasajero.comentario_adicional &&
                                                    <> <Divider mb="10px" mt="-3px" h="1px" bg="primary.100" />
                                                        <Box display="flex" flexDirection="column">
                                                            <Text color="#adacac" fontSize="13px">COMENTARIO ADICIONAL</Text>
                                                            <Text fontWeight="600" mt="-5px">{pasajeroSeleccionado.viajePasajero.comentario_adicional}</Text>
                                                        </Box> </>}
                                            </Box>}
                                    </ModalBody>
                                </ModalContent>
                            </Modal>}
                        </Box>
                    </Box>
                    <Box>
                        <AlertDialog m="0px" isOpen={isCancelOpen} onClose={onCancelClose}>
                            <AlertDialogOverlay display="flex" justifyContent="center" alignItems="center">
                                <AlertDialogContent mt={["50%", "25%", "15%"]} w={["380px", "100%", "100%"]} >
                                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                        Cancelar Reserva
                                    </AlertDialogHeader>

                                    <AlertDialogBody>
                                        ¿Estás seguro de que quieres cancelar esta reserva?
                                    </AlertDialogBody>

                                    <AlertDialogFooter>
                                        <Button onClick={onCancelClose}>No</Button>
                                        <Button colorScheme="red" onClick={cancelarReserva} ml={3}>
                                            Sí, Cancelar
                                        </Button>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialogOverlay>
                        </AlertDialog>
                    </Box>
                    {!isLargerThan800 &&
                        <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} autoFocus={false}>
                            <DrawerOverlay />
                            <DrawerContent >
                                <DrawerHeader borderBottom="1px solid #ccc" mt="20px" mb="10px" p="0" pb="0px" w="100%" h="150px" display="flex" justifyContent="center">
                                    <Text>{showLogin ? 'No has iniciado sesión' : 'Datos de la reserva'}</Text>
                                </DrawerHeader>
                                <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="0px" gap="20px">
                                    {!showLogin && <>
                                        <Box mt="470px">
                                            <Text fontWeight="500">Seleccione el origen y el destino</Text>
                                            <Box display="flex" flexDirection="column" gap="5px">
                                                <Box display="flex" alignItems="center" mt="5px" w="100%" h="50px" borderRadius="5px" border={(origen && origen.peso === -1) ? '1px solid #fff' : '1px solid #ccc'} backgroundColor={(origen && origen.peso === -1) ? 'primary.100' : ''}>
                                                    <Text
                                                        onClick={() => handleSeleccion(viaje.origen, -1)}
                                                        fontWeight={(origen && origen.peso === -1) ? 'bold' : 'normal'}
                                                        color={(origen && origen.peso === -1) ? '#fff' : '#1e1e1e'}
                                                        marginLeft={(origen && origen.peso === -1) ? '10px' : '5px'}
                                                    >
                                                        {viaje.origen}
                                                    </Text>
                                                </Box>

                                                {paradas && paradas.map((parada, index) => (
                                                    <Box display="flex" alignItems="center" key={index} w="100%" h="50px" borderRadius="5px" border={((origen && origen.peso === index) || (destino && destino.peso === index)) ? '1px solid #fff' : '1px solid #ccc'} backgroundColor={((origen && origen.peso === index) || (destino && destino.peso === index)) ? 'primary.100' : ''}>
                                                        <Text
                                                            onClick={() => handleSeleccion(parada.ciudad, index)}
                                                            fontWeight={((origen && origen.peso === index) || (destino && destino.peso === index)) ? 'bold' : 'normal'}
                                                            color={((origen && origen.peso === index) || (destino && destino.peso === index)) ? '#fff' : '#1e1e1e'}
                                                            marginLeft={((origen && origen.peso === index) || (destino && destino.peso === index)) ? '10px' : '5px'}
                                                        >
                                                            {parada.ciudad}
                                                        </Text>
                                                    </Box>
                                                ))}

                                                <Box display="flex" alignItems="center" w="100%" h="50px" borderRadius="5px" border={(destino && destino.peso === 20) ? '1px solid #fff' : '1px solid #ccc'} backgroundColor={(destino && destino.peso === 20) ? 'primary.100' : ''}>
                                                    <Text
                                                        onClick={() => handleSeleccion(viaje.destino, 20)}
                                                        fontWeight={(destino && destino.peso === 20) ? 'bold' : 'normal'}
                                                        color={(destino && destino.peso === 20) ? '#fff' : '#1e1e1e'}
                                                        marginLeft={(destino && destino.peso === 20) ? '10px' : '5px'}
                                                    >
                                                        {viaje.destino}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box w="100%" display="flex" flexDirection="column" gap="5px">
                                            <Text mt="5px" fontWeight="500">Localidad</Text>
                                            <Text mt="-10px" color="#adacac" fontSize="13px">Según la localidad se aplica un recargo.</Text>
                                            <Box w="100%">
                                                <Select value={formData.precio} onChange={handleTarifaChange} placeholder='Seleccione la localidad' w="100%" h="40px" variant='outline'>
                                                    {tarifas && tarifas.map(tarifa => (
                                                        <option key={tarifa.id} value={tarifa.precio}>{tarifa.localidad} - ${tarifa.precio}</option>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Box>

                                        <Box w="100%" display="flex" flexDirection="column" gap="5px">
                                            <Text mt="5px" fontWeight="500">Direcicones</Text>
                                            <Box w="100%">
                                                <InputGroup>
                                                    <InputLeftElement pointerEvents='none'>
                                                        <TbLocationFilled color='gray.300' />
                                                    </InputLeftElement>
                                                    <Input w="100%" h="40px" variant='outline' placeholder='¿Dónde te buscamos? (Dirección)' name="origen_busqueda" onChange={handleChange} />
                                                </InputGroup>
                                            </Box>
                                            <Box w="100%">
                                                <InputGroup>
                                                    <InputLeftElement pointerEvents='none'>
                                                        <MdOutlineLocationOn color='gray.300' />
                                                    </InputLeftElement>
                                                    <Input w="100%" h="40px" variant='outline' placeholder='¿A Dónde te llevamos? (Dirección)' name="destino_busqueda" onChange={handleChange} />
                                                </InputGroup>
                                            </Box>
                                        </Box>

                                        <Box w="100%" display="flex" flexDirection="row" gap="15px" alignItems="center">
                                            <Box ml="10px" display="flex" flexDirection="row" gap="5px" alignItems="center">
                                                <BsPeopleFill color='gray.300' />
                                                <Text>Pasajero(s)</Text>
                                            </Box>
                                            <Button onClick={handleDecrement} color={count == 0 ? '#fff' : 'primary.100'} disabled={count <= 0}> - </Button>
                                            <Text>{count}</Text>
                                            <Button onClick={handleIncrement} color={count == maximo ? '#fff' : 'primary.100'} disabled={count >= maximo}> + </Button>
                                        </Box>
                                        <Box w="100%">
                                            <Box ml="10px" display="flex" flexDirection="row" gap="5px" alignItems="center">
                                                <FaSuitcaseRolling fontSize="17px" color='gray.300' />
                                                <Text>Equipaje</Text>
                                                <Checkbox checked={formData.equipaje} onChange={() => setFormData(prevData => ({
                                                    ...prevData,
                                                    equipaje: !prevData.equipaje // Cambia el valor de equipaje a su opuesto (true a false o viceversa)
                                                }))} />
                                            </Box>
                                            <Text ml="10px" color="#adacac" fontSize="13px">Una valija y un bolso de mano sin cargo.</Text>
                                        </Box>

                                        <Box>
                                            <Text fontWeight="500">Comentario adicional</Text>
                                            <Textarea name="comentario_adicional" onChange={handleChange} placeholder='Ingrese si cree necesario un comentario adicional' />
                                        </Box>

                                        <Box w="100%">
                                            <Text fontWeight="500">Código de descuento</Text>
                                            <Box w="100%" display="flex" flexDirection="row" gap="2.5%">
                                                <Box w="67.5%">
                                                    <InputGroup>
                                                        <InputLeftElement pointerEvents='none'>
                                                            <MdDiscount color='gray.300' />
                                                        </InputLeftElement>
                                                        <Input w="100%" h="40px" variant='outline' placeholder='Código' name="codigo_descuento" onChange={handleChangeCupon} />
                                                    </InputGroup>
                                                </Box>
                                                <Box w="30%">
                                                    <Button type="button" onClick={validarCupon} w="100%" leftIcon={<MdDiscount />} border='1px solid #8C3333' color="#8C3333">Validar</Button>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box>
                                            <Text fontWeight="500">Precio del viaje</Text>
                                            <Text>Precio por persona ${formData.precio_total != 0 ? formData.precio_total : precio != null ? precio * 1000 : viaje.precio}</Text>
                                            <Text fontWeight="500" textDecoration="underline">PRECIO TOTAL: ${(formData.precio_total != 0 ? formData.precio_total : precio != null ? precio * 1000 : viaje.precio) * count}</Text>
                                            {dataCupon.porcentaje != '' && dataCupon.porcentaje != null ? <Text>Descuento aplicado: {dataCupon.porcentaje}%</Text> : ''}
                                            {dataCupon.monto != '' && dataCupon.monto != null ? <Text>Descuento aplicado: ${dataCupon.monto}</Text> : ''}
                                        </Box>

                                        <Box w="100%" mb="10px" display="flex" flexDirection="column" gap="10px">
                                            <Button w="100%" h="40px" onClick={handleClick} isLoading={cargando} leftIcon={<BsCheckLg />} bg='#8C3333' color={cargando == true ? "#8C3333" : "#fff"} variant='solid'> Confirmar </Button>
                                            <Button w="100%" h="40px" onClick={onClose} leftIcon={<RxCross2 />} border='1px solid #8C3333' color="#8C3333"> Cancelar </Button>
                                        </Box>
                                    </>}
                                    {showLogin && <>
                                        <Box w="100%">
                                            <Tabs variant='soft-rounded' colorScheme='red'>
                                                <TabList w="100%">
                                                    <Box w="100%" display="flex" flexDirection="row" justifyContent="center" gap="20px">
                                                        <Tab>Iniciar sesión</Tab>
                                                        <Tab>Registrarme</Tab>
                                                    </Box>
                                                </TabList>
                                                <TabPanels>
                                                    <TabPanel>
                                                        <FormLogin setUser={setUser} onClose={onClose} />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <FormRegistro setUser={setUser} onClose={onClose} />
                                                    </TabPanel>
                                                </TabPanels>
                                            </Tabs>

                                        </Box>
                                    </>}

                                </DrawerBody>
                            </DrawerContent>
                        </Drawer>}
                    {isLargerThan800 &&
                        <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>{showLogin ? 'Iniciar Sesión' : 'Datos de la reserva'}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
                                    {!showLogin && <>
                                        <Box>
                                            <Text fontWeight="500">Seleccione el origen y el destino</Text>
                                            <Box display="flex" flexDirection="column" gap="5px">
                                                <Box display="flex" alignItems="center" mt="5px" w="100%" h="40px" borderRadius="5px" border={(origen && origen.peso === -1) ? '1px solid #fff' : '1px solid #ccc'} backgroundColor={(origen && origen.peso === -1) ? 'primary.100' : ''}>
                                                    <Text
                                                        cursor="pointer"
                                                        onClick={() => handleSeleccion(viaje.origen, -1)}
                                                        fontWeight={(origen && origen.peso === -1) ? 'bold' : 'normal'}
                                                        color={(origen && origen.peso === -1) ? '#fff' : '#1e1e1e'}
                                                        marginLeft={(origen && origen.peso === -1) ? '10px' : '5px'}
                                                    >
                                                        {viaje.origen}
                                                    </Text>
                                                </Box>

                                                {paradas && paradas.map((parada, index) => (
                                                    <Box display="flex" alignItems="center" key={index} w="100%" h="40px" borderRadius="5px" border={((origen && origen.peso === index) || (destino && destino.peso === index)) ? '1px solid #fff' : '1px solid #ccc'} backgroundColor={((origen && origen.peso === index) || (destino && destino.peso === index)) ? 'primary.100' : ''}>
                                                        <Text
                                                            cursor="pointer"
                                                            onClick={() => handleSeleccion(parada.ciudad, index)}
                                                            fontWeight={((origen && origen.peso === index) || (destino && destino.peso === index)) ? 'bold' : 'normal'}
                                                            color={((origen && origen.peso === index) || (destino && destino.peso === index)) ? '#fff' : '#1e1e1e'}
                                                            marginLeft={((origen && origen.peso === index) || (destino && destino.peso === index)) ? '10px' : '5px'}
                                                        >
                                                            {parada.ciudad}
                                                        </Text>
                                                    </Box>
                                                ))}

                                                <Box display="flex" alignItems="center" w="100%" h="40px" borderRadius="5px" border={(destino && destino.peso === 20) ? '1px solid #fff' : '1px solid #ccc'} backgroundColor={(destino && destino.peso === 20) ? 'primary.100' : ''}>
                                                    <Text
                                                        cursor="pointer"
                                                        onClick={() => handleSeleccion(viaje.destino, 20)}
                                                        fontWeight={(destino && destino.peso === 20) ? 'bold' : 'normal'}
                                                        color={(destino && destino.peso === 20) ? '#fff' : '#1e1e1e'}
                                                        marginLeft={(destino && destino.peso === 20) ? '10px' : '5px'}
                                                    >
                                                        {viaje.destino}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box w="100%" display="flex" flexDirection="column" gap="5px">
                                            <Text mt="5px" fontWeight="500">Localidad</Text>
                                            <Text mt="-10px" color="#adacac" fontSize="13px">Según la localidad se aplica un recargo.</Text>
                                            <Box w="100%">
                                                <Select value={formData.precio} onChange={handleTarifaChange} placeholder='Seleccione la localidad' w="100%" h="40px" variant='outline'>
                                                    {tarifas && tarifas.map(tarifa => (
                                                        <option key={tarifa.id} value={tarifa.precio}>{tarifa.localidad} - ${tarifa.precio}</option>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Box>

                                        <Box w="100%" display="flex" flexDirection="column" gap="5px">
                                            <Text mt="5px" fontWeight="500">Direcicones</Text>
                                            <Box w="100%">
                                                <InputGroup>
                                                    <InputLeftElement pointerEvents='none'>
                                                        <TbLocationFilled color='gray.300' />
                                                    </InputLeftElement>
                                                    <Input w="100%" h="40px" variant='outline' placeholder='¿Dónde te buscamos? (Dirección)' name="origen_busqueda" onChange={handleChange} />
                                                </InputGroup>
                                            </Box>
                                            <Box w="100%">
                                                <InputGroup>
                                                    <InputLeftElement pointerEvents='none'>
                                                        <MdOutlineLocationOn color='gray.300' />
                                                    </InputLeftElement>
                                                    <Input w="100%" h="40px" variant='outline' placeholder='¿A Dónde te llevamos? (Dirección)' name="destino_busqueda" onChange={handleChange} />
                                                </InputGroup>
                                            </Box>
                                        </Box>

                                        <Box w="100%" display="flex" flexDirection="row" gap="15px" alignItems="center">
                                            <Box ml="10px" display="flex" flexDirection="row" gap="5px" alignItems="center">
                                                <BsPeopleFill color='gray.300' />
                                                <Text>Pasajero(s)</Text>
                                            </Box>
                                            <Button onClick={handleDecrement} color={count == 0 ? '#fff' : 'primary.100'} disabled={count <= 0}> - </Button>
                                            <Text>{count}</Text>
                                            <Button onClick={handleIncrement} color={count == maximo ? '#fff' : 'primary.100'} disabled={count >= maximo}> + </Button>
                                        </Box>
                                        <Box w="100%">
                                            <Box ml="10px" display="flex" flexDirection="row" gap="5px" alignItems="center">
                                                <FaSuitcaseRolling fontSize="17px" color='gray.300' />
                                                <Text>Equipaje</Text>
                                                <Checkbox checked={formData.equipaje} onChange={() => setFormData(prevData => ({
                                                    ...prevData,
                                                    equipaje: !prevData.equipaje // Cambia el valor de equipaje a su opuesto (true a false o viceversa)
                                                }))} />
                                            </Box>
                                            <Text ml="10px" color="#adacac" fontSize="13px">Una valija y un bolso de mano sin cargo.</Text>
                                        </Box>

                                        <Box>
                                            <Text fontWeight="500">Comentario adicional</Text>
                                            <Textarea name="comentario_adicional" onChange={handleChange} placeholder='Ingrese si cree necesario un comentario adicional' />
                                        </Box>

                                        <Box w="100%">
                                            <Text fontWeight="500">Código de descuento</Text>
                                            <Box w="100%" display="flex" flexDirection="row" gap="2.5%">
                                                <Box w="67.5%">
                                                    <InputGroup>
                                                        <InputLeftElement pointerEvents='none'>
                                                            <MdDiscount color='gray.300' />
                                                        </InputLeftElement>
                                                        <Input w="100%" h="40px" variant='outline' placeholder='Código' name="codigo_descuento" onChange={handleChangeCupon} />
                                                    </InputGroup>
                                                </Box>
                                                <Box w="30%">
                                                    <Button type="button" onClick={validarCupon} w="100%" leftIcon={<MdDiscount />} border='1px solid #8C3333' color="#8C3333">Validar</Button>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box>
                                            <Text fontWeight="500" textDecoration="underline">PRECIO TOTAL: ${formData.precio_total != 0 ? formData.precio_total : viaje.precio}</Text>
                                            {dataCupon.porcentaje != '' && dataCupon.porcentaje != null ? <Text>Descuento aplicado: {dataCupon.porcentaje}%</Text> : ''}
                                            {dataCupon.monto != '' && dataCupon.monto != null ? <Text>Descuento aplicado: ${dataCupon.monto}</Text> : ''}
                                        </Box>

                                        <Box w="100%" mb="10px" display="flex" flexDirection="column" gap="10px">
                                            <Button w="100%" h="40px" onClick={handleClick} isLoading={cargando} leftIcon={<BsCheckLg />} bg='#8C3333' color={cargando == true ? "#8C3333" : "#fff"} variant='solid'> Confirmar </Button>
                                            <Button w="100%" h="40px" onClick={onClose} leftIcon={<RxCross2 />} border='1px solid #8C3333' color="#8C3333"> Cancelar </Button>
                                        </Box>
                                    </>}
                                    {showLogin && <>
                                        <Box w="100%">
                                            <Tabs variant='soft-rounded' colorScheme='red'>
                                                <TabList w="100%">
                                                    <Box w="100%" display="flex" flexDirection="row" justifyContent="center" gap="20px">
                                                        <Tab>Iniciar sesión</Tab>
                                                        <Tab>Registrarme</Tab>
                                                    </Box>
                                                </TabList>
                                                <TabPanels>
                                                    <TabPanel>
                                                        <FormLogin setUser={setUser} onClose={onClose} />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <FormRegistro setUser={setUser} onClose={onClose} />
                                                    </TabPanel>
                                                </TabPanels>
                                            </Tabs>

                                        </Box>
                                    </>}
                                </ModalBody>
                            </ModalContent>
                        </Modal>}
                </Box>
            }
            {
                !viaje &&
                <Text>Cargando...</Text>
            }
            {
                isCancelarOpen &&
                <Modal isOpen={isCancelarOpen} onClose={onCancelarClose}>
                    <ModalOverlay />
                    <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
                        <ModalHeader>Cancelar viaje</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            < Text>¿Desea cancelar el viaje?</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button bg='primary.100' color="#fff" mr={3} onClick={() => { cancelarViaje() }}>
                                Si, cancelar
                            </Button>
                            <Button onClick={onCancelarClose} bg="#">No</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }

            {
                !isLargerThan800 &&
                <Drawer placement="bottom" onClose={onEditarClose} isOpen={isEditarOpen} autoFocus={false}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerHeader mt="20px" mb="10px" p="0" w="100%" h="150px" display="flex" justifyContent="center">
                            <Text>Datos del viaje</Text>
                        </DrawerHeader>
                        <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
                            <EditarViaje id={idViaje} cerrarPopup={onEditarClose} />
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            }
            {
                isLargerThan800 &&
                <Modal isOpen={isEditarOpen} onClose={onEditarClose}>
                    <ModalOverlay />
                    <ModalContent w={["380px", "100%", "100%"]}>
                        <ModalHeader>Datos del viaje</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <EditarViaje id={idViaje} cerrarPopup={onEditarClose} />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }
        </Box >
    )
}

export default Viaje