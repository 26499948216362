import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Box, Button, Text } from '@chakra-ui/react';
import VolverTitulo from '../../components/usuario/VolverTitulo';
import FormBuscarViaje from '../../components/usuario/FormBuscarViaje';
import ViajesDisponibles from '../../components/usuario/ViajesDisponibles';
import Mensaje from '../../components/usuario/Mensaje';
import { getViajesPorCiudad, obtenerViajesOrigenDestino } from '../../database';

const Resultados = () => {
  const { origen, destino } = useParams();
  const [hayViajes, setHayViajes] = useState(false);
  const [viajes, setViajes] = useState([]);
  const [numViajes, setNumViajes] = useState(10);

  const addViajesSinDuplicados = (newViajes) => {
    setViajes(prevViajes => {
      const viajesMap = new Map(prevViajes.map(viaje => [viaje.id, viaje]));
      newViajes.forEach(viaje => {
        if (!viajesMap.has(viaje.id)) {
          viajesMap.set(viaje.id, viaje);
        }
      });
      return Array.from(viajesMap.values());
    });
  };

  const obtenerViajesFunc = async () => {
    let origen_busqueda = origen !== 'undefined' ? origen : '0';
    let destino_busqueda = destino !== 'undefined' ? destino : '0';

    const resultadoViajes = await obtenerViajesOrigenDestino(origen_busqueda, destino_busqueda, numViajes);

    if (resultadoViajes.success) {
      setViajes(resultadoViajes.viajes);
      setHayViajes(true);
    }

    if (origen_busqueda != '0') {
      const resultadoViajesParadasOrigen = await getViajesPorCiudad(origen_busqueda, numViajes);

      if (resultadoViajesParadasOrigen.success) {
        addViajesSinDuplicados(resultadoViajesParadasOrigen.viajes);
      }
    }

    if (destino_busqueda != '0') {
      const resultadoViajesParadasDestino = await getViajesPorCiudad(destino_busqueda, numViajes);
      if (resultadoViajesParadasDestino.success) {
        addViajesSinDuplicados(resultadoViajesParadasDestino.viajes);
      }
    }
  };

  useEffect(() => {
    obtenerViajesFunc();
  }, [origen, destino, numViajes]);

  const cargarMasViajes = () => {
    setNumViajes(prevNum => prevNum + 10);
  };

  return (
    <Box w="100%" display="flex" flexDirection="column" alignItems="center" h="100vh">
      <VolverTitulo texto="Resultados" url="/home" />
      <Box mt="60px" w="85%" maxW="400px" display="flex" flexDirection="column" alignItems="center" h="100vh">
        {hayViajes &&
          <>
            <FormBuscarViaje setHayViajes={setHayViajes} hayViajes={hayViajes} origen={origen} destino={destino} />
            <Box w="100%" maxW="400px" display="flex" mt="20px">
              <Text fontWeight="600" fontSize="20px">Viajes disponibles</Text>
            </Box>
            <ViajesDisponibles resultado={true} viajes={viajes} />
            {viajes.length >= 10 && (
              <Button mt="20px" onClick={cargarMasViajes}>Obtener más</Button>
            )}
          </>
        }
        {!hayViajes && <Mensaje tipo="sin_viaje" />}
      </Box>
    </Box>
  )
}

export default Resultados;
