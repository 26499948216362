import { Box, Button, Divider, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, FormControl, Input, InputGroup, InputLeftElement, List, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Text, useDisclosure, useMediaQuery, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { BiEdit, BiTimeFive } from 'react-icons/bi'
import { BsFillCalendarEventFill, BsPeopleFill, BsPersonFill } from 'react-icons/bs'
import { GiSteeringWheel } from 'react-icons/gi'
import { IoLogoUsd } from 'react-icons/io'
import { MdLocationPin, MdOutlineLocationOn } from 'react-icons/md'
import { TbLocationFilled } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import Calendario from '../Calendario';
import { actualizarViaje, getChoferes, getViajePorId } from '../../database'
import { FaTrash } from 'react-icons/fa'
import EditarParada from './EditarParada'

const EditarViaje = ({ id, cerrarPopup }) => {

  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const provinciasArgentina = [
    'Buenos Aires',
    'Catamarca',
    'Chaco',
    'Chubut',
    'Córdoba',
    'Corrientes',
    'Entre Ríos',
    'Formosa',
    'Jujuy',
    'La Pampa',
    'La Rioja',
    'Mendoza',
    'Misiones',
    'Neuquén',
    'Río Negro',
    'Salta',
    'San Juan',
    'San Luis',
    'Santa Cruz',
    'Santa Fe',
    'Santiago del Estero',
    'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
    'Tucumán',
  ];
  const [fecha, setFecha] = useState();
  const [count, setCount] = useState(0);
  const [inputValueOrigen, setInputValueOrigen] = useState('');
  const [inputValueDestino, setInputValueDestino] = useState('');
  const [ciudadesOrigen, setCiudadesOrigen] = useState([]);
  const [ciudadesDestino, setCiudadesDestino] = useState([]);
  const containerRef = useRef(null);
  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([]);
  const [ciudadesFiltradasDestino, setCiudadesFiltradasDestino] = useState([]);
  const [listOrigen, showListOrigen] = useState(false);
  const [listDestino, showListDestino] = useState(false);
  const [provinciaOrigen, setProvinciaOrigen] = useState('');
  const [provinciaDestino, setProvinciaDestino] = useState('');
  const navigate = useNavigate();
  const toast = useToast();
  const [cargando, setCargando] = useState(false);
  const [choferes, setChoferes] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [chofer, setChofer] = useState([]);
  const [fechaFormateada, setFechaFormateada] = useState('');
  const [formData, setFormData] = useState({
    origen: '',
    destino: '',
    hora: '',
    asientos_disponibles: 0,
    fecha: '',
    precio: '',
    id_chofer: ''
  });

  const [paradas, setParadas] = useState();
  const [parada, setParada] = useState();
  const [actualizado, setActualizado] = useState();

  const handleProvinciaDestino = (event) => {
    setProvinciaDestino(event.target.value);
  };

  const handleProvinciaOrigen = (event) => {
    setProvinciaOrigen(event.target.value);
  };
  const { isOpen: isOriginModalOpen, onOpen: onOriginModalOpen, onClose: onOriginModalClose } = useDisclosure();
  const { isOpen: isDestinationModalOpen, onOpen: onDestinationModalOpen, onClose: onDestinationModalClose } = useDisclosure();
  const { isOpen: isEditarTarifaModalOpen, onOpen: onEditarTarifaOpen, onClose: onEditarTarifaClose } = useDisclosure();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const handleChoferChange = (event) => {
    const choferSeleccionado = event.target.value;

    setFormData({
      ...formData,
      id_chofer: choferSeleccionado
    });
  };

  const obtenerChoferes = async () => {
    const resultadoAgregarChofer = await getChoferes('0', '0');

    if (resultadoAgregarChofer.length > 0) {
      setChoferes(resultadoAgregarChofer);
    }
  }

  const obtenerViaje = async (id) => {
    const resultadoViaje = await getViajePorId(id);

    if (resultadoViaje.success) {
      const data = resultadoViaje.viaje;
      console.log(resultadoViaje)
      setParadas(resultadoViaje.paradas);

      setFormData({
        origen: data.origen,
        destino: data.destino,
        hora: data.hora,
        asientos_disponibles: data.asientos_disponibles,
        fecha: data.fecha,
        precio: data.precio,
        id_chofer: data.id_chofer
      });
      setCount(data.asientos_disponibles);
    }
  }

  useEffect(() => {
    obtenerViaje(id);
    obtenerChoferes();
  }, [actualizado]);

  useEffect(() => {
    const fetchDataOrigen = async () => {
      try {
        const response = await fetch(`https://apis.datos.gob.ar/georef/api/localidades?provincia=${provinciaOrigen}&campos=nombre&max=999`);

        if (response.ok) {
          const data = await response.json();
          const localidadesModificadas = data.localidades.map(localidad => {
            if (localidad.nombre === "Ciudad de Buenos Aires") {
              return { ...localidad, nombre: "Ciudad Autónoma de Buenos Aires" };
            }
            return localidad;
          });
          setCiudadesOrigen(localidadesModificadas);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (provinciaOrigen !== '') {
      fetchDataOrigen();
      showListOrigen(false);
      setInputValueOrigen('');
    }
  }, [provinciaOrigen]);

  useEffect(() => {
    const fetchDataOrigen = async () => {
      try {
        const response = await fetch(`https://apis.datos.gob.ar/georef/api/localidades?provincia=${provinciaDestino}&campos=nombre&max=999`);

        if (response.ok) {
          const data = await response.json();
          const localidadesModificadas = data.localidades.map(localidad => {
            if (localidad.nombre === "Ciudad de Buenos Aires") {
              return { ...localidad, nombre: "Ciudad Autónoma de Buenos Aires" };
            }
            return localidad;
          });
          setCiudadesDestino(localidadesModificadas);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (provinciaDestino !== '') {
      fetchDataOrigen();
      showListDestino(false);
      setInputValueDestino('');
    }
  }, [provinciaDestino]);

  /* origen */
  useEffect(() => {
    const ciudadesFiltradas = ciudadesOrigen.filter((ciudad) =>
      typeof ciudad === 'object' && ciudad.nombre.toLowerCase().includes(inputValueOrigen.toLowerCase())
    );
    setCiudadesFiltradas(ciudadesFiltradas);
  }, [inputValueOrigen]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValueOrigen(value);
  };

  /* destino */
  useEffect(() => {
    const ciudadesFiltradas = ciudadesDestino.filter((ciudad) =>
      typeof ciudad === 'object' && ciudad.nombre.toLowerCase().includes(inputValueDestino.toLowerCase())
    );
    setCiudadesFiltradasDestino(ciudadesFiltradas);
  }, [inputValueDestino]);

  const handleInputChangeDestino = (event) => {
    const value = event.target.value;
    setInputValueDestino(value);
  };

  function capitalizeWords(str) {
    const accentMap = {
      'á': 'a',
      'é': 'e',
      'í': 'i',
      'ó': 'o',
      'ú': 'u',
    };

    const stringWithoutAccents = str.replace(/[áéíóú]/g, match => accentMap[match] || match);

    return stringWithoutAccents
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const [seleccionadoCompleto, setSeleccionCompleto] = useState('');

  const origenSeleccionado = (cuidad) => {
    showListOrigen(false);
    setInputValueOrigen(capitalizeWords(cuidad.nombre));

    setSeleccionCompleto({
      origen: provinciaOrigen + ', ' + capitalizeWords(cuidad.nombre)
    });
    setSeleccionCompleto((prevSeleccion) => ({
      ...prevSeleccion,
      origen: capitalizeWords(provinciaOrigen) + ', ' + capitalizeWords(cuidad.nombre)
    }));
  }

  const destinoSeleccionado = (cuidad) => {
    showListDestino(false);
    setInputValueDestino(capitalizeWords(cuidad.nombre));

    setSeleccionCompleto((prevSeleccion) => ({
      ...prevSeleccion,
      destino: capitalizeWords(provinciaDestino) + ', ' + capitalizeWords(cuidad.nombre),
    }));
  }

  useEffect(() => {
    setFormData((prevSeleccion) => ({
      ...prevSeleccion,
      fecha: fecha
    }));
  }, [fecha]);

  useEffect(() => {
    setFormData((prevSeleccion) => ({
      ...prevSeleccion,
      asientos_disponibles: count
    }));
  }, [count]);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      origen: seleccionadoCompleto.origen
    }));
  }, [seleccionadoCompleto.origen]);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      destino: seleccionadoCompleto.destino
    }));
  }, [seleccionadoCompleto.destino]);

  const handleClick = async () => {
    try {
      setCargando(false);

      const resultadoActualizaar = await actualizarViaje(id, formData);

      console.log(resultadoActualizaar)

      if (resultadoActualizaar.success) {
        cerrarPopup();
        toast({
          title: 'Viaje actualizado.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      } else {
        cerrarPopup();
        toast({
          title: 'Error al actualizar el viaje.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (e) {

    }
  }

  const convertirFecha = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000); // Convertir segundos a milisegundos
      return date.toLocaleDateString(); // O usa toLocaleString() si necesitas la hora también
    }
    return '';
  };


  const obtenerParadaEditar = (parada) => {
    setActualizado(false);
    setParada(parada);
    onEditarTarifaOpen();
  }

  return (
    <>
      {formData && <FormControl w="100%" display="flex" flexDirection="column" alignItems="center" gap="10px">
        <Box position="relative" zIndex="1" w="100%">
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<TbLocationFilled color='gray.300' />}
            />
            <Input onClick={onOriginModalOpen} readOnly value={seleccionadoCompleto.origen != '' ? seleccionadoCompleto.origen : inputValueOrigen} placeholder={formData && formData.origen} onChange={handleInputChange} />
          </InputGroup>
        </Box>
        {paradas && paradas.map((parada, index) => {
          return (
            <>
              <Box key={index} w="100%" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
                  <Text>{parada.ciudad}</Text>
                  <Text>${parada.precio}</Text>
                </Box>
                <Box h="100%" display="flex" alignItems="center" justifyContent="center">
                  <BiEdit cursor="pointer" onClick={() => { obtenerParadaEditar(parada) }} />
                </Box>
              </Box>
            </>

          );
        })}
        <Box w="100%">
          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <MdOutlineLocationOn color='gray.300' />
            </InputLeftElement>
            <Input onClick={onDestinationModalOpen} value={seleccionadoCompleto.destino != '' ? seleccionadoCompleto.destino : inputValueDestino} readOnly w="100%" h="40px" variant='outline' placeholder={formData && formData.destino} />
          </InputGroup>
        </Box>
        <Flex w="100%">
          <Box w="48.5%">
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <BsFillCalendarEventFill color='gray.300' />
              </InputLeftElement>
              <Input w="100%" h="40px" variant='outline' name="fecha" placeholder={formData && formData.fecha ? convertirFecha(formData.fecha) : ''} onChange={handleChange} value={fecha && fecha instanceof Date ? fecha.toISOString().slice(0, 10) : ''} onClick={openModal} readOnly />
            </InputGroup>
            <Calendario isOpen={isOpen} onClose={closeModal} setFecha={setFecha} fecha={fecha && fecha} />
          </Box>
          <Spacer />
          <Box w="48.5%">
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <IoLogoUsd color='gray.300' />
              </InputLeftElement>
              <Input w="100%" h="40px" variant='outline' type='number' placeholder={formData && formData.precio} name="precio" onChange={handleChange} />
            </InputGroup>
          </Box>
        </Flex>
        <Box w="100%" display="flex" flexDirection="row" gap="5px">
          <InputGroup w="48.5%">
            <InputLeftElement pointerEvents='none'>
              <BsPeopleFill color='gray.300' />
            </InputLeftElement>
            <Input w="100%" h="40px" variant='outline' value={`Pasajeros: ${formData && formData.asientos_disponibles}`} readOnly name="pasajeros" />
          </InputGroup>
          <Button onClick={handleDecrement} color={count == 0 ? '#fff' : 'primary.100'} disabled={count <= 0}> - </Button>
          <Button onClick={handleIncrement} color='primary.100'> + </Button>
        </Box>
        <Flex w="100%">
          <Box w="48.5%">
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <BiTimeFive color='gray.300' />
              </InputLeftElement>
              <Input w="100%" h="40px" variant='outline' type='text' placeholder={formData && formData.hora} name="hora" onChange={handleChange} />
            </InputGroup>
          </Box>

        </Flex>
        <Box w="100%">
          <Select value={formData.id_chofer} onChange={handleChoferChange} placeholder='Seleccione el chofer' w="100%" h="40px" variant='outline'>
            {choferes && choferes.map(chofer => (
              <option key={chofer.id} value={chofer.id}>{chofer.nombre}</option>
            ))}
          </Select>
        </Box>

        <Box w="100%">
          <Button w="100%" h="40px" isLoading={cargando} onClick={handleClick} leftIcon={<GiSteeringWheel />} bg='primary.100' color="#fff" variant='solid'>Guardar viaje</Button>
        </Box>
      </FormControl>}
      <Box>
        <Modal isOpen={isOriginModalOpen} onClose={onOriginModalClose}>
          <ModalOverlay />
          <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
            <ModalHeader>Complete el origen</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection="column" gap="10px">
              <Select placeholder="Provincia" value={provinciaOrigen} onChange={handleProvinciaOrigen}>
                {provinciasArgentina.map((provincia, index) => (
                  <option key={index} value={provincia}>
                    {provincia}
                  </option>
                ))}
              </Select>
              <Input onClick={() => showListOrigen(true)} value={inputValueOrigen} w="100%" h="40px" variant='outline' placeholder='Ciudad' name="origen" onChange={handleInputChange} />
              {listOrigen &&
                <Box >
                  <List mt="-10px" maxH="200px" overflowY="auto" border="1px solid #e2e8f0" borderRadius="5px">
                    {ciudadesFiltradas.map((ciudad, index) => (
                      <Box onClick={() => { origenSeleccionado(ciudad) }} key={index} display="flex" gap="10px" fontSize="18px" flexDirection="row" justifyContent="flex-start" alignItems="center" h="35px">
                        <MdLocationPin />
                        <Text>{capitalizeWords(ciudad.nombre)}</Text>
                      </Box>
                    ))}
                  </List>
                </Box>}
            </ModalBody>
            <ModalFooter>
              <Button color="#fff" bg="primary.100" w="150px" onClick={onOriginModalClose}> Seleccionar </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isDestinationModalOpen} onClose={onDestinationModalClose}>
          <ModalOverlay />
          <ModalContent mt={["30%", "25%", "15%"]} w={["380px", "100%", "100%"]}>
            <ModalHeader>Modal de Destino</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" flexDirection="column" gap="10px">
              <Select placeholder="Provincia" value={provinciaDestino} onChange={handleProvinciaDestino}>
                {provinciasArgentina.map((provincia, index) => (
                  <option key={index} value={provincia}>
                    {provincia}
                  </option>
                ))}
              </Select>
              <Input onClick={() => showListDestino(true)} value={inputValueDestino} w="100%" h="40px" variant='outline' placeholder='Ciudad' name="destino" onChange={handleInputChangeDestino} />
              {listDestino &&
                <Box >
                  <List mt="-10px" maxH="200px" overflowY="auto" border="1px solid #e2e8f0" borderRadius="5px">
                    {ciudadesFiltradasDestino.map((ciudad, index) => (
                      <Box onClick={() => { destinoSeleccionado(ciudad) }} key={index} display="flex" gap="10px" fontSize="18px" flexDirection="row" justifyContent="flex-start" alignItems="center" h="35px">
                        <MdLocationPin />
                        <Text>{capitalizeWords(ciudad.nombre)}</Text>
                      </Box>
                    ))}
                  </List>
                </Box>}
            </ModalBody>
            <ModalFooter>
              <Button color="#fff" bg="primary.100" w="150px" colorScheme="blue" onClick={onDestinationModalClose}> Seleccionar </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
      {!isLargerThan800 &&
        <Drawer placement="bottom" onClose={onEditarTarifaClose} isOpen={isEditarTarifaModalOpen} autoFocus={false}>
          <DrawerOverlay />
          <DrawerContent bg="#ddd">
            <DrawerHeader mt="20px" mb="10px" p="0" w="100%" h="150px" display="flex" justifyContent="center">
              <Text>Datos de la parada</Text>
            </DrawerHeader>
            <DrawerBody w="100%" display="flex" flexDirection="column" justifyContent="center" mb="10px" gap="20px">
              <EditarParada parada={parada} onClose={onEditarTarifaClose} setActualizado={setActualizado} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>}
      {isLargerThan800 &&
        <Modal isOpen={isOpen} onClose={onEditarTarifaClose}>
          <ModalOverlay />
          <ModalContent w={["380px", "100%", "100%"]}>
            <ModalHeader>Datos de la parada</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <EditarParada parada={parada} onClose={onEditarTarifaClose} setActualizado={setActualizado} />
            </ModalBody>
          </ModalContent>
        </Modal>}
    </>
  )
}

export default EditarViaje