import React, { useState, useEffect } from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  VStack,
  Grid,
  Spacer,
  Button,
  ModalFooter,
} from '@chakra-ui/react';
import { IoChevronBackCircle, IoChevronForwardCircle } from 'react-icons/io5';

const Calendario = ({ isOpen, onClose, setFecha, fecha }) => {
  const [mes, setMes] = useState(new Date().getMonth());
  const [año, setAño] = useState(new Date().getFullYear());
  const [diasSeleccionados, setdiasSeleccionados] = useState({});

  const primerDiaDelMes = new Date(año, mes, 1);
  const diasEnMes = new Date(año, mes + 1, 0).getDate();
  const primerDiaDeLaSemana = primerDiaDelMes.getDay();

  const dias = Array.from({ length: diasEnMes }, (_, index) => index + 1);
  const diasAnteriores = Array.from(
    { length: primerDiaDeLaSemana },
    (_, index) => ''
  );

  const cambiarMes = (incremento) => {
    const nuevoMes = mes + incremento;
    if (nuevoMes < 0) {
      setMes(11);
      setAño(año - 1);
    } else if (nuevoMes > 11) {
      setMes(0);
      setAño(año + 1);
    } else {
      setMes(nuevoMes);
    }
    setdiasSeleccionados({});
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const toggleDiaseleccionado = (dia) => {
    if (diasSeleccionados[mes]?.includes(dia)) {
      setdiasSeleccionados((prevSeleccionados) => {
        return {
          ...prevSeleccionados,
          [mes]: prevSeleccionados[mes].filter((d) => d !== dia),
        };
      });
    } else {
      setdiasSeleccionados((prevSeleccionados) => {
        return {
          ...prevSeleccionados,
          [mes]: [...(prevSeleccionados[mes] || []), dia],
        };
      });
    }
  };

  const confirmarSeleccion = () => {
    const fechasFormateadas = diasSeleccionados[mes].map((diaDB) => {

      return `${diaDB}/${(parseInt([mes]) + 1)}/${año}`;
    });
    setFecha(fechasFormateadas);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent background="none" maxWidth="300px" h="200px">
        <ModalBody>
          <VStack>
            <Box p="5px" display="flex" color="#fff" h="40px" flexDirection="row" alignItems="center" w="300px" bg="primary.100" borderRadius="5px 5px 0 0">
              <IoChevronBackCircle
                size={20}
                color="#fff"
                onClick={() => cambiarMes(-1)}
                aria-label="Mes anterior"
                bg="primary.100"
                cursor="pointer"
              />
              <Spacer/>
              <Text fontSize="lg" fontWeight="bold">
                {capitalizeFirstLetter(
                  primerDiaDelMes.toLocaleDateString('default', {
                    month: 'long',
                    year: 'numeric',
                  })
                )}
              </Text>
              <Spacer/>
              <IoChevronForwardCircle
                size={20}
                color="#fff"
                onClick={() => cambiarMes(1)}
                aria-label="Próximo mes"
                bg="primary.100"
                cursor="pointer"
              />
            </Box>
            <Grid p="5px"
              templateColumns="repeat(7, 1fr)"
              gap={1}
              justifyContent="center"
              alignItems="center"
              w="300px" 
              boxShadow="2px 2px 5px 0px rgba(30,30,30,0.25)"
              mt="-8px"
              borderRadius="0 0 5px 5px"
              bg="#fff"
            >
              {['D', 'L', 'M', 'X', 'J', 'V', 'S'].map((dia) => (
                <Text
                  key={dia}
                  fontWeight="bold"
                  fontSize="sm"
                  textAlign="center"
                  color="gray.600"
                >
                  {dia}
                </Text>
              ))}
              {diasAnteriores.map((dia, index) => (
                <Text key={`empty-${index}`} />
              ))}
              {dias.map((dia, index) => (
                <Text
                  key={dia}
                  cursor="pointer"
                  textAlign="center"
                  borderRadius="full"
                  w="8"
                  h="8"
                  lineHeight="8"
                  backgroundColor={
                    diasSeleccionados[mes]?.includes(dia) ? 'blue.500' : 'transparent'
                  }
                  color={diasSeleccionados[mes]?.includes(dia) ? 'white' : 'inherit'}
                  fontWeight={diasSeleccionados[mes]?.includes(dia) ? 'bold' : 'normal'}
                  _hover={{
                    backgroundColor: 'blue.200',
                  }}
                  onClick={() => {
                    toggleDiaseleccionado(dia);
                    setFecha(new Date(año, mes, dia));
                  }}
                >
                  {dia}
                </Text>
              ))}
            </Grid>
          </VStack>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center" alignItems="center">
          <Button onClick={confirmarSeleccion}>Confirmar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Calendario;
